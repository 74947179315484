:root {
  --theme-color: #0163d2; }

.theme-color2 {
  --theme-color: #e22454; }

.theme-color3 {
  --theme-color: #7520dd; }

.theme-color4 {
  --theme-color: #e87316; }

.theme-color5 {
  --theme-color: #df4246; }

.theme-color6 {
  --theme-color: #51983c; }

.text-color {
  color: #6d6d6d; }

/**=====================
    common mixins css start
==========================**/
/*======= position css starts  ======= */
/*======= position css ends  ======= */
/*======= display flex css starts  ======= */
/*======= display flex css ends  ======= */
/*======= Font-color css starts  ======= */
.font-default {
  color: #232323 !important; }

.font-dark {
  color: rgba(35, 35, 35, 0.65) !important; }

.font-light {
  color: #7e7e7e !important; }

.font-dark-30 {
  color: #232323 !important; }

/*======= Font-color css end  ======= */
/*======= buttons css starts  ======= */
.btn-solid-dark {
  color: #fff;
  background-color: unset;
  border: 1px solid #232323; }
  .btn-solid-dark::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #232323;
    transition: all 0.5s; }
  .btn-solid-dark:hover {
    color: #232323; }
    .btn-solid-dark:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5); }

.btn-solid-light {
  color: #fff;
  background-color: unset;
  border: 1px solid #fefefe;
  color: #fff; }
  .btn-solid-light::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #fefefe;
    transition: all 0.5s; }
  .btn-solid-light:hover {
    color: #232323; }
    .btn-solid-light:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5); }
  .btn-solid-light:hover, .btn-solid-light:focus, .btn-solid-light:active, .btn-solid-light.active {
    color: #000; }

.btn-solid-default {
  color: #fff;
  background-color: unset;
  border: 1px solid var(--theme-color); }
  .btn-solid-default::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--theme-color);
    transition: all 0.5s; }
  .btn-solid-default:hover {
    color: #232323; }
    .btn-solid-default:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5); }

/*======= buttons css end  ======= */
/*======= align css starts  ======= */
/*======= align css ends  ======= */
/*======= breakpoint css ends  ======= */
/**=====================
    breakpoint mixins css start
==========================**/
/**=====================
     reset css start
==========================**/
section,
.section-t-space {
  padding-top: 80px; }
  @media (max-width: 1460px) {
    section,
    .section-t-space {
      padding-top: 70px; } }
  @media (max-width: 1199px) {
    section,
    .section-t-space {
      padding-top: 60px; } }
  @media (max-width: 991px) {
    section,
    .section-t-space {
      padding-top: 50px; } }
  @media (max-width: 767px) {
    section,
    .section-t-space {
      padding-top: 40px; } }
  @media (max-width: 575px) {
    section,
    .section-t-space {
      padding-top: 30px; } }

.section-b-space {
  padding-bottom: 80px; }
  @media (max-width: 1460px) {
    .section-b-space {
      padding-bottom: 70px; } }
  @media (max-width: 1199px) {
    .section-b-space {
      padding-bottom: 60px; } }
  @media (max-width: 991px) {
    .section-b-space {
      padding-bottom: 50px; } }
  @media (max-width: 767px) {
    .section-b-space {
      padding-bottom: 40px; } }
  @media (max-width: 575px) {
    .section-b-space {
      padding-bottom: 30px; } }

.media .media-body {
  flex: 1; }

.rating li i.orange {
  color: #facc21; }

.g-18 {
  margin: -18px; }
  .g-18 > div {
    padding: 18px; }

.custom-gy-5 {
  margin-top: calc(-16px + (-20 - -16) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: calc(-16px + (-20 - -16) * ((100vw - 320px) / (1920 - 320))); }
  .custom-gy-5 > div {
    margin-top: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))); }

.light-gray-bg {
  background-color: #eff2f7; }

@media (min-width: 1200px) {
  .custom-col {
    width: calc(33.33333% + 4.16%); } }

/**=====================
     typography css start
==========================**/
body {
  font-family: Rubik, sans-serif;
  position: relative;
  font-size: 14px;
  color: #232323;
  margin: 0;
  background-color: #fff; }
  body .bg-overlay {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 3;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s; }
    body .bg-overlay.show {
      visibility: visible;
      opacity: 1; }

ul {
  padding-left: 0;
  margin-bottom: 0; }

li {
  display: inline-block;
  font-size: 14px; }

p {
  font-size: 14px;
  line-height: 18px; }

a {
  color: var(--theme-color);
  transition: 0.5s ease;
  text-decoration: none; }
  a:hover {
    text-decoration: none;
    transition: 0.5s ease; }
  a:focus {
    outline: none; }

button:focus {
  outline: none; }

.btn-close:focus {
  box-shadow: none; }

:focus {
  outline: none; }

.form-control {
  background-color: #fff; }
  .form-control:focus {
    box-shadow: none; }

h1 {
  font-size: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  line-height: 1.1;
  text-transform: capitalize;
  margin: 0; }

h2 {
  font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin: 0; }

h3 {
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 500;
  line-height: 1.2;
  margin: 0; }

h4 {
  font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.2;
  margin: 0;
  font-weight: 400; }

h5 {
  font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.2;
  margin: 0;
  font-weight: 400; }

h6 {
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.2;
  margin: 0;
  font-weight: 400; }

span {
  display: inline-block; }

.theme-color {
  color: var(--theme-color) !important; }

.theme-bg-color {
  background-color: var(--theme-color); }

/**=====================
     buttons css start
==========================**/
.btn {
  position: relative;
  padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  z-index: 1;
  transition: all 0.5s;
  border-radius: 0;
  font-weight: bold;
  font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize;
  white-space: nowrap; }
  .btn-submit {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    color: #fff; }
    .btn-submit:hover {
      background-color: var(--theme-color);
      color: #fff; }
  .btn-size {
    font-size: 12px; }
  .btn:focus {
    box-shadow: none; }
  .btn-spacing {
    padding: calc(5px + (14 - 5) * ((100vw - 320px) / (1920 - 320))) calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320))); }

.btn-sm {
  padding: 8px 16px;
  font-weight: 400; }

.default-white {
  background-color: #fff;
  border-radius: 0; }

.default-light {
  color: #f8f8f8;
  position: relative;
  border: 1px solid transparent;
  padding: calc(6px + (14 - 6) * ((100vw - 320px) / (1920 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 800; }
  .default-light::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.15; }

.default-light1 {
  color: var(--theme-color);
  position: relative;
  background-color: unset;
  border: 1px solid transparent; }
  .default-light1::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: var(--theme-color);
    opacity: 0.11;
    transition: all 0.5s; }
  .default-light1::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border: 1px solid var(--theme-color);
    opacity: 0;
    transition: all 0.5s; }
  .default-light1:hover {
    color: var(--theme-color); }
    .default-light1:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5); }
    .default-light1:hover::after {
      opacity: 0.11; }

.default-light-theme {
  color: var(--theme-color);
  padding: calc(6px + (14 - 6) * ((100vw - 320px) / (1920 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .default-light-theme:hover {
    color: var(--theme-color); }

.default-theme {
  background-color: var(--theme-color);
  color: #fff; }
  .default-theme:hover {
    color: #fff; }

.default-theme-2 {
  padding: 12px 25px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400; }

.default-theme-1 {
  background-color: rgba(240, 247, 240, 0.6);
  transform: scale(100%);
  border: 1px solid var(--theme-color); }
  .default-theme-1:hover {
    background-color: var(--theme-color);
    color: #fff;
    transform: scale(100%); }

.default::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--theme-color);
  opacity: 0.11;
  transition: all 0.5s; }

.default::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  border: 1px solid var(--theme-color);
  opacity: 0;
  transition: all 0.5s; }

.default:hover {
  color: var(--theme-color); }
  .default:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5); }
  .default:hover::after {
    opacity: 0.11; }

.btn-light-white {
  padding: 6px 20px;
  color: #fff;
  background-color: unset;
  border: 1px solid rgba(255, 255, 255, 0.1); }
  .btn-light-white i {
    font-size: 12px; }
  .btn-light-white::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s; }
  .btn-light-white:hover {
    color: #fff; }
    .btn-light-white:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5); }

.btn-white {
  padding: 6px 20px;
  color: #232323;
  background-color: unset;
  border: 1px solid #fff; }
  .btn-white i {
    font-size: 12px; }
  .btn-white::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #fff;
    transition: all 0.5s; }
  .btn-white:hover {
    color: #fff; }
    .btn-white:hover::before {
      opacity: 0;
      transform: scale(0.5, 0.5); }

.btn-full {
  width: 100%;
  border-radius: 5px; }

/**=====================
     alert css start
==========================**/
.alert-info .btn-close {
  font-size: 10px; }

.progress-bar-info {
  background-color: #0c5460; }

[data-notify="progressbar"] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px; }

/**=====================
    label css start
==========================**/
.label {
  font-size: 12px;
  padding: 6px 10px;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  font-weight: 500; }
  @media (max-width: 575px) {
    .label {
      padding: 4px 6px;
      font-size: 10px; } }
  .label-block {
    position: absolute;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    top: 0;
    width: 100%;
    z-index: 1; }
  .label-dark {
    background-color: #000; }
  .label-black {
    background-color: #000; }
    [dir="rtl"] .label-black {
      float: right; }
  .label-theme {
    background-color: var(--theme-color); }

/**=====================
     tab css start
==========================**/
.tab-wrap .nav-tabs {
  justify-content: center;
  border-bottom: 2px solid #f0f3f8; }
  @media (max-width: 991px) {
    .tab-wrap .nav-tabs {
      display: -webkit-box;
      justify-content: center;
      flex-wrap: inherit;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap; } }
  .tab-wrap .nav-tabs .nav-link {
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    color: #7e7e7e;
    font-weight: 500;
    padding: 0 20px 20px;
    background: transparent;
    border: none;
    border-bottom: 4px solid transparent; }
    @media (max-width: 991px) {
      .tab-wrap .nav-tabs .nav-link {
        padding: 0 10px 15px; } }
    .tab-wrap .nav-tabs .nav-link:hover, .tab-wrap .nav-tabs .nav-link:active, .tab-wrap .nav-tabs .nav-link:focus {
      border: none; }
    .tab-wrap .nav-tabs .nav-link.active, .tab-wrap .nav-tabs .nav-link:active {
      border-bottom: 4px solid var(--theme-color);
      color: #212529; }

.tab-wrap .tab-content {
  margin-top: 30px; }

/**=====================
     dropdown css start
==========================**/
.onhover-div {
  top: 70px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  transition: all linear 0.3s;
  min-width: 280px;
  box-shadow: 3.346px 3.716px 22.5px rgba(0, 0, 0, 0.07); }
  .onhover-div li {
    font-size: 14px; }
    .onhover-div li a svg {
      color: #232323; }
  .onhover-div::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    top: -7px;
    right: 10px;
    z-index: 2; }
    [dir="rtl"] .onhover-div::before {
      right: unset;
      left: 10px; }

.onhover-dropdown {
  position: relative;
  cursor: pointer; }
  .onhover-dropdown:hover .onhover-div {
    top: 50px;
    opacity: 1;
    transform: translateY(0px);
    visibility: visible; }
    @media (max-width: 767px) {
      .onhover-dropdown:hover .onhover-div {
        width: 320px; } }
  .onhover-dropdown.cart-dropdown button {
    display: flex;
    align-items: center;
    justify-content: center; }
    .onhover-dropdown.cart-dropdown button .feather {
      width: 23px !important;
      margin-right: 10px;
      height: 22px; }
      [dir="rtl"] .onhover-dropdown.cart-dropdown button .feather {
        margin-right: unset;
        margin-left: 10px; }
      @media (max-width: 360px) {
        .onhover-dropdown.cart-dropdown button .feather {
          margin-right: 0; }
          [dir="rtl"] .onhover-dropdown.cart-dropdown button .feather {
            margin-right: unset;
            margin-left: 0; } }
    .onhover-dropdown.cart-dropdown button span {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))); }

/**=====================
     timer css start
==========================**/
.timer ul {
  padding: 20px;
  background-color: rgba(35, 35, 35, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  [dir="rtl"] .timer ul {
    padding: 20px; }
  @media (max-width: 1366px) {
    .timer ul {
      padding: 15px; } }
  @media (max-width: 480px) {
    .timer ul {
      padding: 10px; } }
  .timer ul li {
    position: relative; }
    .timer ul li span {
      display: block;
      margin-bottom: 3px; }
    @media (max-width: 480px) {
      .timer ul li {
        font-size: 12px; } }
    @media (max-width: 480px) {
      .timer ul li h5 {
        font-size: 13px; } }
    .timer ul li + li {
      margin-left: 30px; }
      .timer ul li + li::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -17px;
        border-left: 1px solid rgba(126, 126, 126, 0.4);
        height: 50%; }
        [dir="rtl"] .timer ul li + li::before {
          right: -17px;
          left: unset !important;
          border-right: 1px solid rgba(126, 126, 126, 0.4);
          border-left: unset; }
        @media (max-width: 1366px) {
          .timer ul li + li::before {
            left: -12px; }
            [dir="rtl"] .timer ul li + li::before {
              right: -12px; } }
        @media (max-width: 480px) {
          .timer ul li + li::before {
            left: -5px; }
            [dir="rtl"] .timer ul li + li::before {
              right: -5px; } }
      [dir="rtl"] .timer ul li + li {
        margin-right: 30px;
        margin-left: unset !important; }
      @media (max-width: 1366px) {
        .timer ul li + li {
          margin-left: 20px; }
          [dir="rtl"] .timer ul li + li {
            margin-right: 20px; } }
      @media (max-width: 480px) {
        .timer ul li + li {
          margin-left: 6px; }
          [dir="rtl"] .timer ul li + li {
            margin-right: 6px; } }

.timer-style-2 ul {
  margin: calc(-5px + (-15 - -5) * ((100vw - 320px) / (1920 - 320)));
  display: flex;
  align-items: center; }
  .timer-style-2 ul li {
    margin: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 0;
    color: #212529; }
    .timer-style-2 ul li h2 {
      font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320))); }
    .timer-style-2 ul li span {
      display: block;
      margin-bottom: 3px; }
    @media (max-width: 480px) {
      .timer-style-2 ul li {
        font-size: 12px; } }
    @media (max-width: 480px) {
      .timer-style-2 ul li h5 {
        font-size: 13px; } }

.timer-style-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(232, 115, 22, 0.8);
  backdrop-filter: blur(2px);
  width: 80%; }
  .timer-style-3 ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    color: #fff;
    width: 100%; }
    .timer-style-3 ul li {
      margin: calc(5px + (5 - 5) * ((100vw - 320px) / (1920 - 320))) calc(5px + (5 - 5) * ((100vw - 320px) / (1920 - 320))) calc(5px + (5 - 5) * ((100vw - 320px) / (1920 - 320))) 0;
      padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
      width: 25%; }
      .timer-style-3 ul li + li {
        position: relative; }
        .timer-style-3 ul li + li::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          left: 0%; }
      .timer-style-3 ul li:nth-child(4) {
        margin-right: 0; }
      .timer-style-3 ul li h2 {
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320))); }
      .timer-style-3 ul li span {
        display: block;
        margin-bottom: 3px; }
      @media (max-width: 480px) {
        .timer-style-3 ul li {
          font-size: 12px; } }
      @media (max-width: 480px) {
        .timer-style-3 ul li h5 {
          font-size: 13px; } }

/**=====================
     title css start
==========================**/
.title {
  margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .title.title1 h2 {
    font-size: calc(22px + (35 - 22) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    display: inline-block;
    padding-bottom: 16px; }
    .title.title1 h2:before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1.5px;
      width: 100%;
      height: 1px;
      background-color: #ddd; }
    .title.title1 h2:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1px;
      width: 30%;
      height: 4px;
      background-color: var(--theme-color);
      border-radius: 50px; }
  .title.title1 p {
    margin: 20px auto 0;
    color: #212529;
    letter-spacing: 1px;
    width: 50%;
    line-height: 1.5; }
  .title.title-effect h2::after {
    left: 0;
    transform: translateX(0); }
  .title.title-1 {
    margin-bottom: 0;
    padding: 0 15px; }
  .title h5 {
    margin-bottom: 10px;
    margin-top: -2px;
    color: #7e7e7e; }

.title-2 {
  color: #212529; }
  .title-2 h2 {
    margin-top: -2px; }
  .title-2 h3 {
    margin-top: -2px; }
  .title-2 h5 {
    margin-bottom: 0;
    margin-top: 10px;
    color: #7e7e7e; }

.title-3 {
  margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .title-3.title-border {
    border-bottom: 1px solid #f0f3f8; }
  .title-3 h2 {
    margin-top: -2px; }
  .title-3 h5 {
    margin-bottom: 0;
    margin-top: 10px; }

.gradient-title {
  background-image: linear-gradient(100deg, #3d3d3d, var(--theme-color));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  -webkit-background-clip: text;
  cursor: pointer; }
  .gradient-title-1 {
    background-image: linear-gradient(100deg, white, var(--theme-color)); }

/**=====================
     Tap to top css start
==========================**/
.tap-to-top {
  background-color: var(--theme-color);
  position: fixed;
  bottom: 15px;
  right: -60px;
  border-radius: 5px;
  overflow: hidden;
  width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
  height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
  box-shadow: 0 3px 8px rgba(33, 37, 41, 0.35);
  z-index: 3;
  transition: all 0.5s ease;
  cursor: pointer;
  opacity: 0; }
  [dir="rtl"] .tap-to-top {
    right: unset;
    left: -60px; }
  .tap-to-top::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    background: #212529;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: 0.5s; }
  .tap-to-top:hover::before {
    height: 100%; }
  @media (max-width: 575px) {
    .tap-to-top {
      bottom: 75px; } }
  .tap-to-top.show {
    right: 15px;
    transition: all 0.5s ease;
    opacity: 1; }
    [dir="rtl"] .tap-to-top.show {
      right: unset;
      left: 15px; }
  .tap-to-top a {
    color: #fff;
    width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    z-index: 3; }
    @media (max-width: 575px) {
      .tap-to-top a {
        bottom: 75px; } }

/**=====================
    Modal css Start
==========================**/
.newletter-modal .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
  background-color: #fff; }
  .newletter-modal .modal-dialog .modal-content .modal-header {
    border: none;
    padding: 0;
    background-color: #fff; }
    .newletter-modal .modal-dialog .modal-content .modal-header .btn-close {
      width: 54px;
      height: 54px;
      top: -50px;
      right: -50px;
      line-height: 54px;
      position: absolute;
      text-decoration: none;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: none;
      cursor: pointer;
      transition: all 0.2s linear;
      opacity: 1; }
      @media (max-width: 575px) {
        .newletter-modal .modal-dialog .modal-content .modal-header .btn-close {
          right: -14px; }
          [dir="rtl"] .newletter-modal .modal-dialog .modal-content .modal-header .btn-close {
            right: unset;
            left: -14px; } }
      [dir="rtl"] .newletter-modal .modal-dialog .modal-content .modal-header .btn-close {
        right: unset;
        left: -50px; }
      .newletter-modal .modal-dialog .modal-content .modal-header .btn-close:before {
        content: "\f00d";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 22px; }
  .newletter-modal .modal-dialog .modal-content .modal-body {
    padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    overflow: hidden;
    z-index: 1; }
    .newletter-modal .modal-dialog .modal-content .modal-body img {
      position: absolute;
      top: -15px;
      right: -25px;
      width: 140px;
      transform: rotate(20deg);
      filter: grayscale(1);
      opacity: 0.5;
      box-shadow: 0 7px 11px rgba(33, 37, 41, 0.5);
      z-index: -1; }
      [dir="rtl"] .newletter-modal .modal-dialog .modal-content .modal-body img {
        right: unset;
        left: -25px;
        transform: rotate(-20deg); }
    .newletter-modal .modal-dialog .modal-content .modal-body .modal-title h2 {
      color: #212529;
      font-weight: 500;
      margin-bottom: 16px; }
    .newletter-modal .modal-dialog .modal-content .modal-body .modal-title ul {
      list-style-type: disc;
      padding-inline-start: 15px; }
      .newletter-modal .modal-dialog .modal-content .modal-body .modal-title ul li {
        display: list-item;
        color: #7e7e7e;
        line-height: 1.7; }
        .newletter-modal .modal-dialog .modal-content .modal-body .modal-title ul li::marker {
          color: #212529; }

.video-play-modal .modal-dialog .modal-content {
  background-color: transparent;
  border: none; }
  .video-play-modal .modal-dialog .modal-content .modal-body {
    padding: 0; }
    .video-play-modal .modal-dialog .modal-content .modal-body iframe {
      width: 100%;
      height: 550px; }

.comparemodal-body .table {
  margin-bottom: 0;
  border: 1px solid #ced4da; }
  .comparemodal-body .table.table-striped-1 tbody tr:nth-of-type(odd) {
    background-color: transparent; }
  .comparemodal-body .table.table-striped-1 tbody tr:nth-of-type(even) {
    background-color: #eff2f7; }
  .comparemodal-body .table a {
    color: #212529; }
  .comparemodal-body .table tr.table-product-details {
    border-bottom: 1px solid transparent; }
  .comparemodal-body .table tr.table-cart-button td {
    border-top: 1px solid transparent; }
  .comparemodal-body .table tr.table-cart-button a.btn {
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    border-radius: 5px; }
    .comparemodal-body .table tr.table-cart-button a.btn:hover {
      color: #fff; }
  .comparemodal-body .table tr td {
    border-left: 1px solid #ced4da;
    padding: 14px;
    min-width: 230px;
    width: 0;
    color: #212529; }
    .comparemodal-body .table tr td:first-child {
      min-width: 270px;
      font-weight: 500;
      text-transform: uppercase; }
  .comparemodal-body .table ul.list-detail {
    padding-left: 22px; }
    .comparemodal-body .table ul.list-detail li {
      font-size: 14px;
      color: #777777;
      display: list-item;
      list-style-type: square; }

.comparemodal-body .product-box .btn-close {
  right: 0;
  top: 0;
  z-index: 1; }

.comparemodal-body .product-box .product-image {
  text-align: center; }
  .comparemodal-body .product-box .product-image img {
    width: 100%;
    height: 100%; }

.modal-header {
  border: none;
  padding: 0; }
  .modal-header .btn-close {
    width: 54px;
    height: 54px;
    top: -50px;
    right: -50px;
    line-height: 54px;
    position: absolute;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background: 0 0;
    border: none;
    cursor: pointer;
    transition: all 0.2s linear;
    opacity: 1; }
    @media (max-width: 575px) {
      .modal-header .btn-close {
        right: -14px; }
        [dir="rtl"] .modal-header .btn-close {
          right: unset;
          left: -14px; } }
    [dir="rtl"] .modal-header .btn-close {
      right: unset;
      left: -50px; }
    .modal-header .btn-close:before {
      content: "\f00d";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 22px; }

.shipping-modal .modal-dialog .modal-content .modal-body {
  padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .shipping-modal .modal-dialog .modal-content .modal-body .modal-title h6 {
    color: #191919;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px; }
  .shipping-modal .modal-dialog .modal-content .modal-body .modal-title ul {
    list-style-type: disc;
    padding-inline-start: 15px; }
    .shipping-modal .modal-dialog .modal-content .modal-body .modal-title ul li {
      display: list-item;
      color: #7e7e7e;
      line-height: 1.7; }
      .shipping-modal .modal-dialog .modal-content .modal-body .modal-title ul li::marker {
        color: #212529; }

.cart-modal .modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0; }
  .cart-modal .modal-dialog .modal-content .modal-header .btn-close {
    width: 54px;
    height: 54px;
    top: -50px;
    right: -50px;
    line-height: 54px;
    position: absolute;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background: 0 0;
    border: none;
    cursor: pointer;
    transition: all 0.2s linear;
    opacity: 1; }
    @media (max-width: 575px) {
      .cart-modal .modal-dialog .modal-content .modal-header .btn-close {
        right: -14px; }
        [dir="rtl"] .cart-modal .modal-dialog .modal-content .modal-header .btn-close {
          right: unset;
          left: -14px; } }
    .cart-modal .modal-dialog .modal-content .modal-header .btn-close:before {
      content: "\f00d";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 22px; }

.cart-modal .modal-dialog .modal-content .modal-body {
  padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .cart-modal .modal-dialog .modal-content .modal-body .modal-messages {
    text-align: center;
    color: #008000;
    margin-bottom: 10px;
    background: #d1e7dd;
    padding: 12px;
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center; }
    @media (max-width: 575px) {
      .cart-modal .modal-dialog .modal-content .modal-body .modal-messages {
        display: block; } }
    .cart-modal .modal-dialog .modal-content .modal-body .modal-messages .fa-check {
      font-size: 14px;
      margin-right: 10px;
      width: 25px;
      height: 25px;
      background: #008000;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 575px) {
        .cart-modal .modal-dialog .modal-content .modal-body .modal-messages .fa-check {
          margin: 0 auto 12px; } }
  .cart-modal .modal-dialog .modal-content .modal-body .modal-product {
    display: flex;
    align-items: center; }
    @media (max-width: 575px) {
      .cart-modal .modal-dialog .modal-content .modal-body .modal-product {
        display: block;
        border-bottom: 1px solid #ddd;
        padding-bottom: 30px; } }
    @media (max-width: 575px) {
      .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-img {
        text-align: center;
        margin-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320))); } }
    .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-details {
      margin-left: 10px; }
      @media (max-width: 575px) {
        .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-details {
          text-align: center;
          margin-left: 0; } }
    .cart-modal .modal-dialog .modal-content .modal-body .modal-product h5 {
      margin: 5px 0; }
    .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-img img {
      width: 100%;
      max-width: 220px;
      height: auto;
      margin: 10px 0; }
      @media (max-width: 575px) {
        .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-img img {
          max-width: 60%; } }
      @media (max-width: 480px) {
        .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-img img {
          max-width: 75%; } }
      @media (max-width: 360px) {
        .cart-modal .modal-dialog .modal-content .modal-body .modal-product .modal-contain-img img {
          max-width: 100%; } }
  .cart-modal .modal-dialog .modal-content .modal-body .product-total h5 {
    font-weight: 400; }
    .cart-modal .modal-dialog .modal-content .modal-body .product-total h5 span {
      font-weight: 500; }
  .cart-modal .modal-dialog .modal-content .modal-body .shop-cart-button {
    margin: -8px; }
    @media (max-width: 360px) {
      .cart-modal .modal-dialog .modal-content .modal-body .shop-cart-button {
        display: flex;
        align-items: center;
        justify-content: center; } }
    .cart-modal .modal-dialog .modal-content .modal-body .shop-cart-button .conti-button {
      border: 1px solid var(--theme-color);
      background: #fff;
      color: var(--theme-color);
      margin: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320)));
      padding: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320))) calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))); }
      .cart-modal .modal-dialog .modal-content .modal-body .shop-cart-button .conti-button:hover {
        background-color: var(--theme-color);
        color: #fff; }

.modal-size .modal-dialog .modal-content .modal-header {
  padding: 0; }
  .modal-size .modal-dialog .modal-content .modal-header button {
    width: 54px;
    height: 54px;
    top: -50px;
    right: -50px;
    line-height: 54px;
    position: absolute;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background: 0 0;
    border: none;
    cursor: pointer;
    transition: all 0.2s linear;
    opacity: 1; }
    .modal-size .modal-dialog .modal-content .modal-header button .fa-times {
      font-size: 22px; }
    @media (max-width: 480px) {
      .modal-size .modal-dialog .modal-content .modal-header button {
        display: none; } }

.quick-view-modal .btn-close {
  width: 54px;
  height: 54px;
  top: -50px;
  right: -50px;
  line-height: 54px;
  position: absolute;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background: 0 0;
  border: none;
  cursor: pointer;
  transition: all 0.2s linear;
  opacity: 1; }
  @media (max-width: 575px) {
    .quick-view-modal .btn-close {
      right: -14px; }
      [dir="rtl"] .quick-view-modal .btn-close {
        right: unset;
        left: -14px; } }
  .quick-view-modal .btn-close:before {
    content: "\f00d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 22px; }

.quick-view-modal .modal-body {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }

.quick-view-modal .quick-view-image {
  position: relative; }
  .quick-view-modal .quick-view-image img {
    width: 100%; }
    @media (max-width: 991px) {
      .quick-view-modal .quick-view-image img {
        max-height: 400px;
        object-fit: cover; } }
  .quick-view-modal .quick-view-image .quick-nav {
    position: absolute;
    bottom: 20px;
    left: 20px; }
    .quick-view-modal .quick-view-image .quick-nav .slick-slide > div img {
      margin: 0;
      border: 1px solid #ced4da;
      width: 40px;
      height: 40px;
      object-fit: cover; }
    .quick-view-modal .quick-view-image .quick-nav .slick-slide.slick-current > div img {
      border: 1px solid var(--theme-color); }

.quick-view-modal .product-right.veg-product-right h2 {
  font-size: 26px; }

.quick-view-modal .product-right.veg-product-right .product-details {
  margin-top: 10px; }

.quick-view-modal .product-right.veg-product-right .product-btns a {
  font-size: 14px; }

.quick-view-modal .product-right.veg-product-right .price {
  display: block; }
  .quick-view-modal .product-right.veg-product-right .price li {
    width: 100%; }
    .quick-view-modal .product-right.veg-product-right .price li .custome-form-check {
      width: 100%;
      padding: 15px 8px 15px 30px;
      border-radius: 4px;
      border: 1px solid #d0d0d0;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 4px 10px rgba(33, 37, 41, 0.1);
      background: #f7f7f7; }

.quick-view-modal .product-right.veg-product-right .veg-details {
  margin-top: 30px;
  padding: 20px 0;
  border-top: 1px dashed #ced4da; }
  .quick-view-modal .product-right.veg-product-right .veg-details p {
    line-height: 1.7; }
  @media (max-width: 991px) {
    .quick-view-modal .product-right.veg-product-right .veg-details {
      margin-top: 20px; } }
  @media (max-width: 575px) {
    .quick-view-modal .product-right.veg-product-right .veg-details {
      margin-top: 15px;
      padding: 15px 0; } }

.quick-view-modal .product-right h2 {
  font-weight: 500; }

.quick-view-modal .product-right h6 {
  font-weight: 400; }

.quick-view-modal .product-right h4 {
  margin-bottom: 10px;
  text-transform: capitalize; }

.quick-view-modal .product-right .price {
  display: flex;
  align-items: center; }
  .quick-view-modal .product-right .price h3 {
    padding-right: 20px; }
    [dir="rtl"] .quick-view-modal .product-right .price h3 {
      padding-right: unset;
      padding-left: 20px; }

.quick-view-modal .product-right .color-types {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px dashed #ced4da; }
  .quick-view-modal .product-right .color-types.veg-details p {
    line-height: 1.7; }
  @media (max-width: 991px) {
    .quick-view-modal .product-right .color-types {
      margin-top: 20px; } }
  @media (max-width: 575px) {
    .quick-view-modal .product-right .color-types {
      margin-top: 15px;
      padding: 15px 0; } }

.quick-view-modal .product-right .size-detail {
  padding: 20px 0;
  border-top: 1px dashed #ced4da; }
  @media (max-width: 575px) {
    .quick-view-modal .product-right .size-detail {
      padding: 15px 0; } }
  .quick-view-modal .product-right .size-detail ul {
    display: flex; }
    .quick-view-modal .product-right .size-detail ul li {
      width: 30px;
      height: 30px;
      text-align: center;
      border: 1px solid #ced4da;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      .quick-view-modal .product-right .size-detail ul li.selected {
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2); }
      .quick-view-modal .product-right .size-detail ul li + li {
        margin-left: 10px; }

.quick-view-modal .product-right .product-details {
  padding: 20px 0;
  border-top: 1px dashed #ced4da;
  border-bottom: 1px dashed #ced4da; }
  @media (max-width: 575px) {
    .quick-view-modal .product-right .product-details {
      padding: 15px 0; } }
  .quick-view-modal .product-right .product-details ul li {
    display: block; }
    .quick-view-modal .product-right .product-details ul li + li {
      margin-top: 5px; }
    .quick-view-modal .product-right .product-details ul li span {
      min-width: 150px; }

.quick-view-modal .product-right .product-btns {
  margin-top: 20px; }
  @media (max-width: 575px) {
    .quick-view-modal .product-right .product-btns {
      margin-top: 15px; } }

.reset-email-modal .modal-content {
  border: none; }
  .reset-email-modal .modal-content .modal-header {
    border-bottom: none;
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: 0; }
    .reset-email-modal .modal-content .modal-header .btn-close {
      width: 54px;
      height: 54px;
      top: -50px;
      right: -50px;
      line-height: 54px;
      position: absolute;
      text-decoration: none;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: none;
      cursor: pointer;
      transition: all 0.2s linear;
      opacity: 1; }
      @media (max-width: 575px) {
        .reset-email-modal .modal-content .modal-header .btn-close {
          right: -14px; }
          [dir="rtl"] .reset-email-modal .modal-content .modal-header .btn-close {
            right: unset;
            left: -14px; } }
      .reset-email-modal .modal-content .modal-header .btn-close:before {
        content: "\f00d";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 22px; }
  .reset-email-modal .modal-content .modal-body {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .reset-email-modal .modal-content .modal-footer {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-top: none; }
    .reset-email-modal .modal-content .modal-footer button {
      margin: 0; }
      .reset-email-modal .modal-content .modal-footer button.modal-close-button {
        color: #fff;
        margin-right: 10px; }
        [dir="rtl"] .reset-email-modal .modal-content .modal-footer button.modal-close-button {
          margin-right: 0;
          margin-left: 10px; }

.delete-account-modal .modal-content {
  border: none; }
  .delete-account-modal .modal-content .modal-header {
    padding: 0;
    border: none; }
    .delete-account-modal .modal-content .modal-header .btn-close {
      width: 54px;
      height: 54px;
      top: -50px;
      right: -50px;
      line-height: 54px;
      position: absolute;
      text-decoration: none;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: none;
      cursor: pointer;
      transition: all 0.2s linear;
      opacity: 1; }
      @media (max-width: 575px) {
        .delete-account-modal .modal-content .modal-header .btn-close {
          right: -14px; }
          [dir="rtl"] .delete-account-modal .modal-content .modal-header .btn-close {
            right: unset;
            left: -14px; } }
      .delete-account-modal .modal-content .modal-header .btn-close:before {
        content: "\f00d";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 22px; }
  .delete-account-modal .modal-content .modal-body {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .delete-account-modal .modal-content .modal-body h4 {
      font-weight: 500; }
  .delete-account-modal .modal-content .modal-footer {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-top: none;
    padding-top: 0;
    justify-content: flex-start; }
    .delete-account-modal .modal-content .modal-footer button {
      margin: 0; }
      .delete-account-modal .modal-content .modal-footer button.modal-close-button {
        color: #fff;
        margin-right: 10px; }
        [dir="rtl"] .delete-account-modal .modal-content .modal-footer button.modal-close-button {
          margin-right: 0;
          margin-left: 10px; }

.payment-modal .modal-content {
  border: none; }
  .payment-modal .modal-content .modal-header {
    padding: 0;
    border: none; }
    .payment-modal .modal-content .modal-header .btn-close {
      width: 54px;
      height: 54px;
      top: -50px;
      right: -50px;
      line-height: 54px;
      position: absolute;
      text-decoration: none;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: none;
      cursor: pointer;
      transition: all 0.2s linear;
      opacity: 1; }
      @media (max-width: 575px) {
        .payment-modal .modal-content .modal-header .btn-close {
          right: -14px; }
          [dir="rtl"] .payment-modal .modal-content .modal-header .btn-close {
            right: unset;
            left: -14px; } }
      .payment-modal .modal-content .modal-header .btn-close:before {
        content: "\f00d";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 22px; }
  .payment-modal .modal-content .modal-body {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .payment-modal .modal-content .modal-body h4 {
      font-weight: 500; }
  .payment-modal .modal-content .modal-footer {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-top: none;
    padding-top: 0;
    justify-content: flex-start; }
    .payment-modal .modal-content .modal-footer button {
      margin: 0; }
      .payment-modal .modal-content .modal-footer button.modal-close-button {
        color: #fff;
        margin-right: 10px; }
        [dir="rtl"] .payment-modal .modal-content .modal-footer button.modal-close-button {
          margin-right: 0;
          margin-left: 10px; }

.add-address-modal .modal-content {
  border: none; }
  .add-address-modal .modal-content .modal-header {
    padding: 0;
    border: none; }
    .add-address-modal .modal-content .modal-header .btn-close {
      width: 54px;
      height: 54px;
      top: -50px;
      right: -50px;
      line-height: 54px;
      position: absolute;
      text-decoration: none;
      text-align: center;
      color: #fff;
      background: 0 0;
      border: none;
      cursor: pointer;
      transition: all 0.2s linear;
      opacity: 1; }
      @media (max-width: 575px) {
        .add-address-modal .modal-content .modal-header .btn-close {
          right: -14px; }
          [dir="rtl"] .add-address-modal .modal-content .modal-header .btn-close {
            right: unset;
            left: -14px; } }
      .add-address-modal .modal-content .modal-header .btn-close:before {
        content: "\f00d";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 22px; }
  .add-address-modal .modal-content .modal-body {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .add-address-modal .modal-content .modal-body h4 {
      font-weight: 500; }
  .add-address-modal .modal-content .modal-footer {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-top: none;
    padding-top: 0;
    justify-content: flex-start; }
    .add-address-modal .modal-content .modal-footer button {
      margin: 0; }
      .add-address-modal .modal-content .modal-footer button.modal-close-button {
        color: #fff;
        margin-right: 10px; }
        [dir="rtl"] .add-address-modal .modal-content .modal-footer button.modal-close-button {
          margin-right: 0;
          margin-left: 10px; }

/**=====================
     ratio css start
==========================**/
.ratio_30 .bg-size:before {
  padding-top: 30%;
  content: "";
  display: block; }

.ratio_35 .bg-size:before {
  padding-top: 35%;
  content: "";
  display: block; }

.ratio_40 .bg-size:before {
  padding-top: 40%;
  content: "";
  display: block; }

.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: "";
  display: block; }

.ratio2_1 .bg-size:before {
  padding-top: 50%;
  content: "";
  display: block; }

.ratio_55 .bg-size:before {
  padding-top: 55%;
  content: "";
  display: block; }

.ratio_59 .bg-size:before {
  padding-top: 59%;
  content: "";
  display: block; }

.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: "";
  display: block; }

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: "";
  display: block; }

.ratio_90 .bg-size:before {
  padding-top: 93%;
  content: "";
  display: block; }

.ratio_landscape .bg-size:before {
  padding-top: 75%;
  content: "";
  display: block; }

.ratio_veg .bg-size:before {
  padding-top: 113%;
  content: "";
  display: block; }

.ratio_square .bg-size:before {
  padding-top: 100%;
  content: "";
  display: block; }

.ratio_asos .bg-size:before {
  padding-top: 127.7777778%;
  content: "";
  display: block; }

.ratio_small .bg-size:before {
  padding-top: 118%;
  content: "";
  display: block; }

.ratio_medium .bg-size:before {
  padding-top: 138%;
  content: "";
  display: block; }

.ratio_portrait .bg-size:before {
  padding-top: 150%;
  content: "";
  display: block; }

.ratio2_2 .bg-size:before {
  padding-top: 154.777778%;
  content: "";
  display: block; }

.ratio_2 .bg-size:before {
  padding-top: 163%;
  content: "";
  display: block; }

.ratio1_2 .bg-size:before {
  padding-top: 200%;
  content: "";
  display: block; }

/**=====================
     form css start
  ==========================**/
.form-control {
  font-size: 14px;
  padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .form-control.subscribe-input {
    border: none;
    border-radius: 0; }
  [dir="rtl"] .form-control {
    text-align: right; }
  .form-control:focus {
    background-color: #eff2f7;
    border-color: #ced4da;
    box-shadow: none;
    color: #212529; }

.form-select {
  font-size: 14px; }
  .form-select:focus {
    background-color: #c7c7c5;
    border-color: #ced4da;
    box-shadow: none;
    color: #212529; }

.input-group .btn {
  z-index: 1; }

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin-right: 16px; }
  [dir="rtl"] .checkbox_animated {
    margin-right: 10px;
    margin-left: 16px; }
  .checkbox_animated:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 1;
    width: 12px;
    height: 6px;
    border: 2px solid var(--theme-color);
    border-top-style: none;
    border-right-style: none; }
  .checkbox_animated:after {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 21px;
    height: 21px;
    background: #fff;
    border: 2px solid #ddd;
    cursor: pointer; }
  .checkbox_animated:checked:before {
    transform: rotate(-45deg) scale(1, 1); }

/**=====================
     slider css start
==========================**/
.slick-initialized .slick-arrow {
  z-index: 1;
  transition: 0.5s; }
  .slick-initialized .slick-arrow:before {
    transition: 0.5s; }
  .slick-initialized .slick-arrow:hover {
    transition: 0.5s; }
    .slick-initialized .slick-arrow:hover:before {
      transition: 0.5s; }

.spacing-slider.slick-slider {
  margin: 0 -12px; }
  .spacing-slider.slick-slider .slick-list {
    margin: 0 -12px; }
    @media (max-width: 575px) {
      .spacing-slider.slick-slider .slick-list {
        margin: 0 -6px; } }
    .spacing-slider.slick-slider .slick-list .slick-slide > div {
      margin: 0 12px; }
      @media (max-width: 575px) {
        .spacing-slider.slick-slider .slick-list .slick-slide > div {
          margin: 0 6px; } }
  .spacing-slider.slick-slider.slick-dotted {
    margin-bottom: 0; }

.slick-slider .slick-list {
  margin: 0 -12px; }
  @media (max-width: 575px) {
    .slick-slider .slick-list {
      margin: 0 -6px; } }
  .slick-slider .slick-list .slick-slide > div {
    margin: 0 12px; }
    @media (max-width: 575px) {
      .slick-slider .slick-list .slick-slide > div {
        margin: 0 6px; } }

.slick-slider.slick-dotted {
  margin-bottom: 0; }

.slide-6 .slick-list {
  margin: 0; }

.classic-arrow .custom-arrow {
  position: absolute;
  bottom: 50px;
  z-index: 1;
  transition: 0.5s;
  cursor: pointer; }
  @media (max-width: 991px) {
    .classic-arrow .custom-arrow {
      bottom: 90px; } }
  @media (max-width: 575px) {
    .classic-arrow .custom-arrow {
      bottom: 10px; } }
  .classic-arrow .custom-arrow:hover {
    color: var(--theme-color);
    transition: 0.5s; }
  .classic-arrow .custom-arrow i {
    font-size: 12px;
    vertical-align: 1px; }
    @media (max-width: 575px) {
      .classic-arrow .custom-arrow i {
        margin: 0 !important;
        padding: 8px;
        background-color: #fff; }
        .classic-arrow .custom-arrow i:hover {
          background-color: var(--theme-color);
          color: #fff; } }
  .classic-arrow .custom-arrow.prev {
    right: 20%; }
    [dir="rtl"] .classic-arrow .custom-arrow.prev i {
      margin-right: 16px !important;
      margin-left: 0 !important; }
    [dir="rtl"] .classic-arrow .custom-arrow.prev {
      right: unset;
      left: 7%; }
    @media (max-width: 991px) {
      .classic-arrow .custom-arrow.prev {
        right: unset;
        left: 5%; }
        [dir="rtl"] .classic-arrow .custom-arrow.prev {
          left: unset;
          right: 5%; } }
    @media (max-width: 575px) {
      .classic-arrow .custom-arrow.prev {
        right: 53% !important;
        left: unset !important;
        bottom: 20px; }
        [dir="rtl"] .classic-arrow .custom-arrow.prev {
          left: 10%;
          right: unset; } }
    @media (max-width: 360px) {
      .classic-arrow .custom-arrow.prev {
        right: 12%;
        left: unset; }
        [dir="rtl"] .classic-arrow .custom-arrow.prev {
          left: 12%;
          right: unset; } }
  .classic-arrow .custom-arrow.next {
    right: 13%; }
    [dir="rtl"] .classic-arrow .custom-arrow.next i {
      margin-left: 16px !important;
      margin-right: 0 !important; }
    [dir="rtl"] .classic-arrow .custom-arrow.next {
      right: unset;
      left: 13%; }
    @media (max-width: 1199px) {
      .classic-arrow .custom-arrow.next {
        right: 11%; }
        [dir="rtl"] .classic-arrow .custom-arrow.next {
          right: unset;
          left: 15%; } }
    @media (max-width: 991px) {
      .classic-arrow .custom-arrow.next {
        right: unset;
        left: 16%; }
        [dir="rtl"] .classic-arrow .custom-arrow.next {
          left: unset;
          right: 16%; } }
    @media (max-width: 767px) {
      .classic-arrow .custom-arrow.next {
        left: 20%; }
        [dir="rtl"] .classic-arrow .custom-arrow.next {
          left: unset;
          right: 20%; } }
    @media (max-width: 575px) {
      .classic-arrow .custom-arrow.next {
        left: unset;
        right: 3%; }
        [dir="rtl"] .classic-arrow .custom-arrow.next {
          left: 3%;
          right: unset; } }
    .classic-arrow .custom-arrow.next::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: 2px solid rgba(0, 0, 0, 0.1);
      left: -28px;
      top: 0; }
      [dir="rtl"] .classic-arrow .custom-arrow.next::before {
        right: 18px;
        left: unset; }
      @media (max-width: 1660px) {
        .classic-arrow .custom-arrow.next::before {
          display: none; } }
  .classic-arrow .custom-arrow span {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px; }
    @media (max-width: 767px) {
      .classic-arrow .custom-arrow span {
        font-size: 14px; } }
    @media (max-width: 575px) {
      .classic-arrow .custom-arrow span {
        display: none; } }

.classic-arrow-1 .custom-arrow {
  position: absolute;
  bottom: 50px;
  z-index: 1;
  transition: 0.5s;
  cursor: pointer; }
  .classic-arrow-1 .custom-arrow:hover {
    color: var(--theme-color);
    transition: 0.5s; }
  .classic-arrow-1 .custom-arrow i {
    font-size: 12px;
    vertical-align: 1px; }
    @media (max-width: 575px) {
      .classic-arrow-1 .custom-arrow i {
        margin: 0 !important;
        padding: 8px;
        background-color: #fff; }
        .classic-arrow-1 .custom-arrow i:hover {
          background-color: var(--theme-color);
          color: #fff; } }
  .classic-arrow-1 .custom-arrow.prev {
    right: 20%; }
    [dir="rtl"] .classic-arrow-1 .custom-arrow.prev {
      right: unset !important;
      left: 6% !important; }
    @media (max-width: 575px) {
      [dir="rtl"] .classic-arrow-1 .custom-arrow.prev {
        left: unset !important;
        right: 51% !important; } }
    @media (max-width: 480px) {
      [dir="rtl"] .classic-arrow-1 .custom-arrow.prev {
        left: unset !important;
        right: 52% !important; } }
  .classic-arrow-1 .custom-arrow.next {
    right: 13%; }
    [dir="rtl"] .classic-arrow-1 .custom-arrow.next {
      right: unset !important;
      left: 12% !important; }
    .classic-arrow-1 .custom-arrow.next::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: 2px solid rgba(0, 0, 0, 0.1);
      left: -28px;
      top: 0; }
      [dir="rtl"] .classic-arrow-1 .custom-arrow.next::before {
        right: -28px;
        left: unset;
        width: 8%; }
      @media (max-width: 1660px) {
        .classic-arrow-1 .custom-arrow.next::before {
          display: none; } }
    @media (max-width: 991px) {
      [dir="rtl"] .classic-arrow-1 .custom-arrow.next {
        left: 16% !important; } }
    @media (max-width: 575px) {
      [dir="rtl"] .classic-arrow-1 .custom-arrow.next {
        left: unset !important;
        right: 13% !important; } }
    @media (max-width: 480px) {
      [dir="rtl"] .classic-arrow-1 .custom-arrow.next {
        left: unset !important;
        right: 44% !important; } }
  .classic-arrow-1 .custom-arrow span {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px; }
    @media (max-width: 767px) {
      .classic-arrow-1 .custom-arrow span {
        font-size: 14px; } }
    @media (max-width: 575px) {
      .classic-arrow-1 .custom-arrow span {
        display: none; } }

.light-arrow .slick-arrow,
.white-arrow .slick-arrow {
  border-radius: 100%;
  width: 40px;
  height: 40px; }
  @media (max-width: 991px) {
    .light-arrow .slick-arrow,
    .white-arrow .slick-arrow {
      width: 30px;
      height: 30px; } }
  .light-arrow .slick-arrow:before,
  .white-arrow .slick-arrow:before {
    font-size: 12px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900; }

.light-arrow .slick-prev,
.white-arrow .slick-prev {
  z-index: 1; }
  .light-arrow .slick-prev:before,
  .white-arrow .slick-prev:before {
    content: "\f053"; }

.light-arrow .slick-next:before,
.white-arrow .slick-next:before {
  content: "\f054"; }

.light-arrow .slick-arrow {
  background-color: #f0f3f8; }
  .light-arrow .slick-arrow:before {
    color: #232323; }
  .light-arrow .slick-arrow:hover {
    background-color: var(--theme-color); }
    .light-arrow .slick-arrow:hover:before {
      color: #fff; }

.bottom-space {
  margin-bottom: -4px; }

.white-arrow .slick-arrow {
  background-color: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 30px; }
  .white-arrow .slick-arrow:before {
    color: #fff; }
  .white-arrow .slick-arrow:hover, .white-arrow .slick-arrow:active, .white-arrow .slick-arrow:focus {
    background-color: #fff; }
    .white-arrow .slick-arrow:hover:before, .white-arrow .slick-arrow:active:before, .white-arrow .slick-arrow:focus:before {
      color: var(--theme-color); }

.category-slider .slick-prev {
  left: -120px; }

.category-slider .slick-next {
  right: unset;
  left: -80px; }

.brand-image img {
  margin: 0 auto; }

.dot-dark .slick-dots {
  bottom: unset;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: unset; }
  [dir="rtl"] .dot-dark .slick-dots {
    left: 0;
    right: unset; }
  .dot-dark .slick-dots li {
    width: auto;
    height: auto;
    display: block;
    margin: 5px; }
    .dot-dark .slick-dots li button {
      width: 10px;
      height: 10px;
      background: #232323;
      font-size: 10px;
      border-radius: 100%; }
      .dot-dark .slick-dots li button:before {
        display: none; }
    .dot-dark .slick-dots li.slick-active button {
      background-color: var(--theme-color); }

.image-show .slick-list {
  overflow: visible;
  height: auto !important; }

.image-show .slick-slide {
  float: none; }

[dir="rtl"] .poster-section-6 .image-show .slick-slide, [dir="rtl"]
.poster-section .image-show .slick-slide {
  float: none; }

.slick-lg-space .slick-list {
  margin: -5px -18px; }
  @media (max-width: 767px) {
    .slick-lg-space .slick-list {
      margin: -5px -10px; } }
  .slick-lg-space .slick-list .slick-slide > div {
    margin: 5px 18px; }
    @media (max-width: 767px) {
      .slick-lg-space .slick-list .slick-slide > div {
        margin: 5px 10px; } }

.black-slide .slick-list .slick-track .slick-slide > div {
  margin: 0 5px; }

.black-slide .slick-list .slick-track .slick-slide.slick-active .play-icon .fa-play {
  filter: grayscale(100%) blur(1px);
  opacity: 0.5; }

.black-slide .slick-list .slick-track .slick-slide.slick-active .play-icon:hover .fa-play {
  filter: grayscale(0);
  opacity: 1; }

.black-slide .slick-list .slick-track .slick-slide.slick-active img {
  transition: all 0.3s ease-in-out; }

.black-slide .slick-list .slick-track .slick-slide.slick-active:hover img {
  filter: grayscale(0);
  opacity: 1; }

.black-slide .slick-list .slick-track .slick-slide img {
  filter: grayscale(20%) blur(1px);
  opacity: 0.8; }

.black-slide .slick-list .slick-track .slick-slide.slick-center img {
  filter: grayscale(0);
  opacity: 1; }

.black-slide .slick-list .slick-track .slick-slide.slick-center .play-icon .fa-play {
  filter: grayscale(0);
  opacity: 1; }

/**=====================
    Touchspin  CSS Start
==========================**/
.bootstrap-touchspin input.touchspin {
  padding: 0 12px; }

.bootstrap-touchspin .input-group .btn {
  padding: 0.5rem 1.75rem; }

.bootstrap-touchspin .input-group {
  font-family: Rubik, sans-serif; }
  .bootstrap-touchspin .input-group .btn {
    border-radius: 0 !important; }

.touchspin-vertical-tab {
  max-width: 60px;
  width: unset; }
  .touchspin-vertical-tab .touchspin-vertical {
    padding: 6px 12px; }
  .touchspin-vertical-tab .input-group .input-group-btn-vertical {
    position: relative;
    display: grid; }
    .touchspin-vertical-tab .input-group .input-group-btn-vertical .bootstrap-touchspin-down {
      margin-top: -2px; }
    .touchspin-vertical-tab .input-group .input-group-btn-vertical i {
      position: absolute;
      top: 4px;
      left: 7px;
      font-size: 10px;
      font-weight: 900; }
    .touchspin-vertical-tab .input-group .input-group-btn-vertical > .btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      padding: 9px;
      margin-left: -1px;
      position: relative;
      background: #fff;
      border-color: #ced4da; }

/**=====================
    Lazy Load css Start
==========================**/
.blur-up {
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms; }
  .blur-up.lazyloaded {
    filter: blur(0); }

/**=====================
     Breadcrumb css start
==========================**/
.breadcrumb-section {
  background-color: #eff2f7;
  display: flex;
  text-align: center;
  position: relative;
  z-index: 0; }
  .breadcrumb-section h3 {
    font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .breadcrumb-section-1 {
    background-color: #e9e9e9; }
  .breadcrumb-section .breadcrumb-item.active {
    color: #7e7e7e; }
  .breadcrumb-section .breadcrumb {
    margin-bottom: 0;
    justify-content: center;
    margin-top: 10px; }
  .breadcrumb-section .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; }
    .breadcrumb-section .circles li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background-color: var(--theme-color);
      animation: animate 25s linear infinite;
      bottom: -150px;
      opacity: 0.2 !important; }
      .breadcrumb-section .circles li:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s; }
      .breadcrumb-section .circles li:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s; }
      .breadcrumb-section .circles li:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s; }
      .breadcrumb-section .circles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s; }
      .breadcrumb-section .circles li:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s; }
      .breadcrumb-section .circles li:nth-child(6) {
        left: 75%;
        width: 90px;
        height: 90px;
        animation-delay: 3s; }
      .breadcrumb-section .circles li:nth-child(7) {
        left: 19%;
        width: 110px;
        height: 110px;
        animation-delay: 7s; }
      .breadcrumb-section .circles li:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s; }
      .breadcrumb-section .circles li:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s; }
      .breadcrumb-section .circles li:nth-child(10) {
        left: 85%;
        width: 110px;
        height: 110px;
        animation-delay: 0s;
        animation-duration: 11s; }

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0; }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%; } }

/**=====================
     header css start
==========================**/
.container-fluid-lg {
  padding: 0 112px; }
  @media (max-width: 1460px) {
    .container-fluid-lg {
      padding: 0 60px; } }
  @media (max-width: 1366px) {
    .container-fluid-lg {
      padding: 0 30px; } }
  @media (max-width: 1199px) {
    .container-fluid-lg {
      padding: 0 12px; } }

header .search-header {
  border-bottom: 1px solid #e5e5e5; }

header.nav-down.nav-up .navbar-searchbar {
  top: 0; }

header.nav-down.nav-up .main-menu {
  padding: 10px 0; }

header.nav-down .navbar-searchbar {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  margin-top: 0;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(23, 32, 90, 0.15);
  transition: all 0.5s ease;
  z-index: 6; }

header .search-suggestion {
  height: 0px;
  max-height: 0;
  background-color: #fff;
  position: absolute;
  width: 100%;
  border-radius: 5px;
  top: 68px;
  overflow: auto;
  transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99);
  z-index: 1; }
  @media (max-width: 767px) {
    header .search-suggestion {
      top: 56px; } }
  header .search-suggestion > ul {
    margin: -10px; }
    header .search-suggestion > ul > li {
      padding: 10px; }
  header .search-suggestion .product-cart {
    display: flex;
    align-items: center; }
    header .search-suggestion .product-cart img {
      height: 100px; }
    header .search-suggestion .product-cart .media-body {
      padding-left: 15px; }
      header .search-suggestion .product-cart .media-body a {
        color: #232323; }
      header .search-suggestion .product-cart .media-body .rating li i {
        font-size: 12px;
        color: #c3c3c3; }

header .main-menu {
  position: relative;
  padding: 20px 0;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    header .main-menu {
      padding: 10px 0; } }

header .onhover-div {
  right: 0; }
  [dir="rtl"] header .onhover-div {
    right: unset;
    left: 0; }

@media (max-width: 575px) {
  header .wislist-dropdown {
    display: none; } }

@media (max-width: 767px) {
  header .cart-dropdown:hover .onhover-div {
    opacity: 0;
    transform: unset;
    visibility: hidden; } }

header .cart-dropdown .onhover-div::before {
  border-bottom: 7px solid #f0f3f8; }

@media (max-width: 767px) {
  header .cart-dropdown .onhover-div {
    position: fixed;
    top: 0 !important;
    height: 100vh;
    width: 290px;
    right: -290px;
    opacity: 1 !important;
    visibility: visible !important;
    transition: 0.5s;
    z-index: 4; } }

@media (max-width: 767px) {
  header .cart-dropdown.show .onhover-div {
    right: 0;
    transition: 0.5s; } }

header .cart-menu .cart-title {
  background-color: #f0f3f8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  header .cart-menu .cart-title h6 {
    position: relative; }
    header .cart-menu .cart-title h6 .label {
      position: absolute;
      top: -4px;
      right: -9px;
      padding: 2px 6px; }
  [dir="rtl"] header .cart-menu .cart-title span i {
    transform: rotate(180deg); }

header .cart-menu .media {
  display: flex;
  align-items: center;
  position: relative; }
  header .cart-menu .media .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    background: none; }
  header .cart-menu .media img {
    width: 60px; }
  header .cart-menu .media .media-body {
    margin-left: 15px; }
    [dir="rtl"] header .cart-menu .media .media-body {
      margin-left: unset;
      margin-right: 15px; }
    header .cart-menu .media .media-body h6 {
      font-weight: 600; }
    header .cart-menu .media .media-body input {
      max-width: 60px;
      width: unset;
      height: auto;
      padding: 3px 8px;
      border-radius: 0; }
    header .cart-menu .media .media-body .qty-with-price {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center; }

header .cart-menu ul {
  padding-top: 10px; }
  header .cart-menu ul li {
    padding: 10px 20px;
    display: block; }
    @media (max-width: 767px) {
      header .cart-menu ul li:last-child {
        padding-bottom: 0; } }
  @media (max-width: 767px) {
    header .cart-menu ul {
      height: calc(100vh - 180px);
      overflow: auto;
      border-bottom: 1px solid #ced4da; } }

header .cart-btn {
  padding: 20px; }
  @media (max-width: 767px) {
    header .cart-btn {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 1; } }
  header .cart-btn .cart-total {
    color: var(--theme-color);
    font-weight: 600;
    display: none; }
    @media (max-width: 767px) {
      header .cart-btn .cart-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px; } }
  header .cart-btn .btn {
    padding: 8px 12px;
    font-size: 14px;
    width: 100%; }

header .wislist-empty {
  padding: 20px;
  text-align: center; }
  header .wislist-empty i {
    font-size: 40px;
    color: var(--theme-color);
    margin-bottom: 15px; }

header .search-full {
  background-color: #fff;
  position: absolute;
  right: 0;
  z-index: 1;
  height: 0;
  width: 0;
  transform: scale(0);
  transition: all 0.3s ease;
  display: flex;
  align-items: center; }
  header .search-full .input-group {
    border: 1px solid #f0f3f8; }
    header .search-full .input-group .input-group-text {
      background-color: transparent;
      border: none; }
      header .search-full .input-group .input-group-text.close-search {
        cursor: pointer; }
      header .search-full .input-group .input-group-text svg {
        height: 18px; }
    header .search-full .input-group input {
      border: none; }
  header .search-full.open {
    width: 100%;
    height: 100%;
    animation: zoomIn 0.5s ease-in-out;
    transform: scale(1);
    transition: all 0.3s ease; }
  header .search-full.show .search-suggestion {
    padding: 10px;
    border: 1px solid #f0f3f8;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 500px;
    transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99); }

header .search-box1 {
  position: relative;
  width: 55%;
  right: 0;
  z-index: 1;
  height: 100%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center; }
  header .search-box1 .form-control {
    border-radius: 0;
    background-color: #fff; }
  header .search-box1 .twitter-typeahead {
    width: calc(100% - 50px); }
  header .search-box1 .tt-menu {
    display: block;
    width: 100%;
    background-color: #fff;
    border: unset !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 350px;
    overflow: auto;
    top: 55px !important;
    border-radius: 5px;
    padding: 8px 0; }
    header .search-box1 .tt-menu .tt-dataset-states {
      padding: 10px 20px; }
      header .search-box1 .tt-menu .tt-dataset-states .tt-selectable {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        header .search-box1 .tt-menu .tt-dataset-states .tt-selectable .image-section {
          width: 100%;
          max-width: 100px;
          margin-right: 20px; }
          header .search-box1 .tt-menu .tt-dataset-states .tt-selectable .image-section img {
            width: 100%; }
        header .search-box1 .tt-menu .tt-dataset-states .tt-selectable .description-section {
          width: calc(100% - 20px); }
          header .search-box1 .tt-menu .tt-dataset-states .tt-selectable .description-section h4 {
            margin-bottom: 5px; }
  @media (max-width: 1660px) {
    header .search-box1 {
      width: 48%; } }
  @media (max-width: 1366px) {
    header .search-box1 {
      width: 40%; } }
  @media (max-width: 1199px) {
    header .search-box1 {
      width: 30%; } }
  header .search-box1 .form-control:focus {
    border-color: #ced4da;
    background-color: #fff; }
  header .search-box1.open {
    width: 100%;
    height: 100%;
    animation: zoomIn 0.5s ease-in-out;
    transform: scale(1);
    transition: all 0.3s ease; }
  header .search-box1.show .search-suggestion {
    top: 100%;
    padding: 10px;
    border: 1px solid #f0f3f8;
    height: max-content;
    max-height: 500px;
    transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99); }
  header .search-box1 .input-group-text {
    color: #fff;
    border-radius: 0; }
    header .search-box1 .input-group-text .feather {
      color: #fff; }

header .profile-dropdown {
  min-width: 160px; }
  header .profile-dropdown ul {
    padding: 10px 0; }
    header .profile-dropdown ul li {
      display: block;
      padding: 5px 20px; }

.brand-logo .svg-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  transform-style: preserve-3d; }
  [dir="rtl"] .brand-logo .svg-icon {
    margin-right: 0;
    margin-left: 5px; }
  .brand-logo .svg-icon .fill-color {
    fill: var(--theme-color); }

@keyframes spinner {
  from {
    transform: rotateY(0deg); }
  to {
    transform: rotateY(-360deg); } }

.category-menu {
  position: relative; }
  .category-menu .category-dropdown {
    padding: 20px; }
    .category-menu .category-dropdown ul li {
      padding: 10px 0;
      display: block; }
      .category-menu .category-dropdown ul li a {
        color: #232323;
        text-transform: capitalize; }
        .category-menu .category-dropdown ul li a:hover, .category-menu .category-dropdown ul li a:focus, .category-menu .category-dropdown ul li a:active, .category-menu .category-dropdown ul li a.active {
          color: var(--theme-color); }

.close-btn {
  padding: 20px;
  font-size: 18px;
  color: #232323;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .close-btn i {
    font-size: 14px; }

.mobile-menu {
  position: fixed;
  bottom: 0;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 2px 7px 3px rgba(168, 168, 168, 0.2); }
  [dir="rtl"] .mobile-menu {
    left: 0; }
  .mobile-menu ul {
    display: flex; }
    [dir="rtl"] .mobile-menu ul {
      padding: 0; }
    .mobile-menu ul li {
      width: 100%;
      text-align: center; }
      .mobile-menu ul li a {
        display: block;
        color: rgba(35, 35, 35, 0.4); }
        @media (max-width: 360px) {
          .mobile-menu ul li a {
            font-size: 13px; } }
        .mobile-menu ul li a.active, .mobile-menu ul li a:hover {
          color: var(--theme-color); }
        .mobile-menu ul li a svg {
          display: block;
          margin: 0 auto;
          stroke-width: 1.5px; }
          @media (max-width: 360px) {
            .mobile-menu ul li a svg {
              height: 20px; } }
        .mobile-menu ul li a span {
          margin-top: 5px; }

@media (min-width: 1200px) {
  .category-menu {
    position: relative; }
    .category-menu .category-dropdown {
      top: 70px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: 8;
      background-color: #fff;
      transition: all linear 0.3s;
      min-width: 250px;
      box-shadow: 3.346px 3.716px 22.5px rgba(0, 0, 0, 0.07); }
      .category-menu .category-dropdown ul li.submenu > a {
        position: relative;
        display: block; }
        .category-menu .category-dropdown ul li.submenu > a::after {
          position: absolute;
          font-family: "Font Awesome 5 Free";
          content: "\f054";
          right: 0;
          font-weight: 600;
          top: 50%;
          transform: translateY(-50%);
          font-size: 11px; }
          [dir="rtl"] .category-menu .category-dropdown ul li.submenu > a::after {
            right: unset;
            left: 0;
            transform: translateY(-50%) rotate(180deg); }
      .category-menu .category-dropdown ul li.submenu .category-mega-menu {
        position: absolute;
        padding: 20px;
        opacity: 0;
        visibility: hidden;
        margin-left: 15px;
        background-color: #fff;
        transition: 0.5s;
        left: 95%;
        box-shadow: 3.346px 3.716px 22.5px rgba(0, 0, 0, 0.07);
        top: 0;
        height: 100%; }
        .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu .title-category {
          margin-bottom: 15px; }
        .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu ul li {
          padding: 5px 0; }
      .category-menu .category-dropdown ul li.submenu:hover .category-mega-menu {
        left: 100%;
        opacity: 1;
        visibility: visible;
        transition: 0.5s; }
        [dir="rtl"] .category-menu .category-dropdown ul li.submenu:hover .category-mega-menu {
          right: unset;
          left: 100%; }
    .category-menu:hover .category-dropdown {
      top: 50px;
      opacity: 1;
      transform: translateY(0px);
      visibility: visible; } }

@media (min-width: 1200px) {
  .category-menu .category-dropdown ul li.submenu .category-mega-menu {
    min-width: 720px; } }

@media (min-width: 1366px) {
  .category-menu .category-dropdown ul li.submenu .category-mega-menu {
    min-width: 850px; } }

.category-menu .category-dropdown .title-category h6 {
  font-size: 14px; }

@media (max-width: 1199px) {
  header .category-dropdown {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #ced4da;
    top: 0;
    height: 100vh;
    left: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
    padding: 0 !important; }
    header .category-dropdown.open {
      left: 0; }
  .category-menu .category-dropdown ul li {
    position: relative;
    letter-spacing: 0.8px;
    line-height: 1; }
    .category-menu .category-dropdown ul li.submenu a .according-menu {
      right: 0; }
      [dir="rtl"] .category-menu .category-dropdown ul li.submenu a .according-menu {
        right: unset;
        left: 0; }
    .category-menu .category-dropdown ul li.submenu .category-mega-menu {
      padding: 0 15px; }
      .category-menu .category-dropdown ul li.submenu .category-mega-menu > li {
        padding-bottom: 0; }
      .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu .title-category {
        position: relative;
        padding: 6px 0; }
        .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu .title-category h6 {
          font-weight: 400; }
        .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu .title-category .according-menu {
          top: 8px;
          right: 0; }
      .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu ul {
        padding: 0 15px; }
        .category-menu .category-dropdown ul li.submenu .category-mega-menu .category-childmenu ul li {
          padding: 6px 0; }
  .category-menu .category-dropdown > ul {
    padding: 20px 15px; }
    .category-menu .category-dropdown > ul > li > a {
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase; }
  .category-menu .category-banner {
    display: none; } }

header .header-style-3 .top-header {
  padding: 16px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  header .header-style-3 .top-header.top-comman-header {
    margin-right: 20px !important; }
    @media (max-width: 360px) {
      header .header-style-3 .top-header.top-comman-header {
        margin-left: 10px; } }
    [dir="rtl"] header .header-style-3 .top-header.top-comman-header {
      margin-right: 0 !important;
      margin-left: 20px !important; }
  @media (max-width: 991px) {
    header .header-style-3 .top-header {
      justify-content: flex-start; } }
  @media (max-width: 575px) {
    header .header-style-3 .top-header {
      padding: 10px 0; } }
  header .header-style-3 .top-header .row {
    justify-content: space-between;
    align-items: center;
    text-align: center; }
  header .header-style-3 .top-header .border-list {
    padding-right: 6px; }
    [dir="rtl"] header .header-style-3 .top-header .border-list {
      padding-right: unset;
      padding-left: 6px; }
    header .header-style-3 .top-header .border-list > li {
      padding: 0 20px;
      margin-right: -5px; }
      [dir="rtl"] header .header-style-3 .top-header .border-list > li {
        margin-right: unset;
        margin-left: -5px; }
      @media (max-width: 1366px) {
        header .header-style-3 .top-header .border-list > li {
          padding: 0 10px; } }
      header .header-style-3 .top-header .border-list > li a {
        color: #232323; }
        header .header-style-3 .top-header .border-list > li a span {
          font-weight: 600; }
        header .header-style-3 .top-header .border-list > li a i {
          vertical-align: middle;
          font-size: 11px;
          padding-left: 5px; }
          [dir="rtl"] header .header-style-3 .top-header .border-list > li a i {
            padding-left: unset;
            padding-right: 5px; }
          @media (max-width: 575px) {
            header .header-style-3 .top-header .border-list > li a i {
              font-size: 9px; } }
      header .header-style-3 .top-header .border-list > li:first-child {
        padding-left: 0; }
        @media (max-width: 575px) {
          header .header-style-3 .top-header .border-list > li:first-child {
            display: none; } }
      header .header-style-3 .top-header .border-list > li:last-child {
        padding-right: 0; }
      header .header-style-3 .top-header .border-list > li + li {
        border-left: 1px solid rgba(35, 35, 35, 0.15); }
        [dir="rtl"] header .header-style-3 .top-header .border-list > li + li {
          border-left: unset;
          border-right: 1px solid rgba(35, 35, 35, 0.15); }
      @media (max-width: 575px) {
        header .header-style-3 .top-header .border-list > li:nth-child(2) {
          border: none !important; } }
  @media (max-width: 575px) {
    header .header-style-3 .top-header .dropdown {
      font-size: 13px; } }
  header .header-style-3 .top-header .dropdown .dropdown-toggle::after {
    display: none; }

header .header-style-3 .main-menu {
  justify-content: space-between; }
  header .header-style-3 .main-menu.common-menu {
    justify-content: unset !important; }
    header .header-style-3 .main-menu.common-menu .navigationbar {
      margin: 0 auto; }
      @media (max-width: 1199px) {
        header .header-style-3 .main-menu.common-menu .navigationbar {
          margin-right: auto;
          margin-left: 0; } }
  header .header-style-3 .main-menu .menu-left {
    display: flex;
    align-items: center; }
  @media (max-width: 767px) {
    header .header-style-3 .main-menu .menu-right {
      padding-right: 8px; }
      [dir="rtl"] header .header-style-3 .main-menu .menu-right {
        padding-right: unset;
        padding-left: 8px; } }
  @media (max-width: 1366px) {
    header .header-style-3 .main-menu .menu-right .search-box {
      padding: 6px 8px; } }
  @media (max-width: 575px) {
    header .header-style-3 .main-menu .menu-right .search-box {
      padding: 4px 6px; } }
  @media (max-width: 575px) {
    header .header-style-3 .main-menu .menu-right .search-box svg {
      width: 16px; } }
  header .header-style-3 .main-menu .menu-right > ul {
    display: flex;
    align-items: center; }
    header .header-style-3 .main-menu .menu-right > ul > li svg {
      width: 20px; }
      @media (max-width: 1366px) {
        header .header-style-3 .main-menu .menu-right > ul > li svg {
          width: 18px; } }
    header .header-style-3 .main-menu .menu-right > ul > li + li {
      margin-left: 20px; }
      [dir="rtl"] header .header-style-3 .main-menu .menu-right > ul > li + li {
        margin-left: unset !important;
        margin-right: 20px; }
      @media (max-width: 575px) {
        header .header-style-3 .main-menu .menu-right > ul > li + li {
          margin-left: 10px; }
          [dir="rtl"] header .header-style-3 .main-menu .menu-right > ul > li + li {
            margin-right: 10px; } }
  header .header-style-3 .main-menu .menu-right .cart-media {
    display: flex;
    align-items: center; }
    header .header-style-3 .main-menu .menu-right .cart-media .cart-icon {
      position: relative; }
      header .header-style-3 .main-menu .menu-right .cart-media .cart-icon .label {
        font-size: 10px;
        position: absolute;
        top: -6px;
        right: -8px;
        padding: 0;
        line-height: 1;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center; }
    header .header-style-3 .main-menu .menu-right .cart-media .cart-content {
      margin-left: 20px; }
      @media (max-width: 767px) {
        header .header-style-3 .main-menu .menu-right .cart-media .cart-content {
          display: none; } }
      header .header-style-3 .main-menu .menu-right .cart-media .cart-content h6 {
        line-height: 1; }
      header .header-style-3 .main-menu .menu-right .cart-media .cart-content span {
        font-size: 13px; }
      [dir="rtl"] header .header-style-3 .main-menu .menu-right .cart-media .cart-content {
        margin-left: unset;
        margin-right: 20px; }
  @media (max-width: 1199px) {
    header .header-style-3 .main-menu nav {
      margin-left: auto;
      margin-right: 30px; }
      [dir="rtl"] header .header-style-3 .main-menu nav {
        margin: 0 !important; } }
  @media (max-width: 575px) {
    header .header-style-3 .main-menu nav {
      margin-right: 20px; }
      [dir="rtl"] header .header-style-3 .main-menu nav {
        margin-left: 20px; } }

[dir="rtl"] header .header-style-3 .menu-right {
  margin-left: unset;
  margin-right: auto; }

header .header-style-3 .menu-right > ul > li.price-button {
  margin-left: 30px; }

header .header-style-3 .menu-right > ul > li a {
  color: #232323; }
  header .header-style-3 .menu-right > ul > li a:hover {
    color: var(--theme-color); }

header .header-style-3 .menu-right > ul > li svg {
  cursor: pointer;
  width: 20px; }

header .header-style-3 .menu-right > ul > li .btn {
  font-size: 14px; }
  header .header-style-3 .menu-right > ul > li .btn svg {
    width: 24px; }
    @media (max-width: 360px) {
      header .header-style-3 .menu-right > ul > li .btn svg {
        width: 18px; } }

header .header-style-3 .menu-right > ul > li + li {
  margin-left: 30px; }
  [dir="rtl"] header .header-style-3 .menu-right > ul > li + li {
    margin-left: unset !important;
    margin-right: 30px; }
  @media (max-width: 991px) {
    header .header-style-3 .menu-right > ul > li + li {
      margin-left: 20px; }
      [dir="rtl"] header .header-style-3 .menu-right > ul > li + li {
        margin-right: 20px; } }
  @media (max-width: 575px) {
    header .header-style-3 .menu-right > ul > li + li {
      margin-left: 10px; }
      [dir="rtl"] header .header-style-3 .menu-right > ul > li + li {
        margin-right: 10px; } }

header .header-style-3 .wislist-dropdown {
  margin-right: 8px; }
  [dir="rtl"] header .header-style-3 .wislist-dropdown {
    margin-right: unset;
    margin-left: 8px; }

@media (max-width: 360px) {
  header .header-style-3 .cart-dropdown button span {
    display: none; } }

@media (max-width: 360px) {
  header .header-style-3 .cart-dropdown button .pe-2 {
    padding: 0 !important; } }

header .header-style-3 .cart-media .label {
  padding: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: absolute;
  right: -9px;
  top: -10px; }

/**=====================
     nav css start
==========================**/
header .main-navbar {
  text-align: center; }
  header .main-navbar .nav-menu {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    direction: ltr;
    text-align: left;
    vertical-align: middle; }
    [dir="rtl"] header .main-navbar .nav-menu {
      text-align: right; }
    header .main-navbar .nav-menu > li {
      position: relative;
      float: left;
      display: block; }
      [dir="rtl"] header .main-navbar .nav-menu > li {
        float: right; }
      header .main-navbar .nav-menu > li + li + li {
        margin-left: 30px; }
        [dir="rtl"] header .main-navbar .nav-menu > li + li + li {
          margin-left: unset;
          margin-right: 30px; }
        @media (max-width: 1199px) {
          header .main-navbar .nav-menu > li + li + li {
            margin-left: 0; }
            [dir="rtl"] header .main-navbar .nav-menu > li + li + li {
              margin-left: unset;
              margin-right: 0; } }
      header .main-navbar .nav-menu > li > a {
        display: block;
        color: #232323;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0;
        position: relative;
        border-radius: 15px;
        letter-spacing: 0.8px; }
      header .main-navbar .nav-menu > li.active > a {
        background-color: rgba(35, 35, 35, 0.1); }
      header .main-navbar .nav-menu > li .nav-submenu {
        position: absolute;
        left: 0;
        z-index: 1;
        box-shadow: 0 1px 5px 0 rgba(90, 90, 90, 0.2);
        padding: 15px 0;
        background: #fff;
        width: 300px !important; }
        [dir="rtl"] header .main-navbar .nav-menu > li .nav-submenu {
          left: unset;
          right: 0; }
        header .main-navbar .nav-menu > li .nav-submenu li {
          border: 0;
          float: none;
          position: relative;
          display: block;
          margin: 5px 0; }
          header .main-navbar .nav-menu > li .nav-submenu li a {
            border: 0 !important;
            background: transparent;
            color: #212529;
            border-radius: 0 !important;
            font-size: 14px;
            text-transform: capitalize;
            padding: 3px 25px 3px 40px;
            display: block;
            letter-spacing: 0.07em;
            line-height: 1.9;
            transition: all 0.5s ease;
            position: relative; }
            [dir="rtl"] header .main-navbar .nav-menu > li .nav-submenu li a {
              padding: 3px 40px 3px 25px; }
            header .main-navbar .nav-menu > li .nav-submenu li a span {
              padding: 0px 10px;
              border-radius: 5px;
              font-size: 12px;
              color: var(--theme-color);
              margin-left: 10px;
              box-shadow: 0 8px 15px -8px var(--badge-color);
              margin-left: 8px;
              position: relative; }
              header .main-navbar .nav-menu > li .nav-submenu li a span::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: var(--theme-color);
                opacity: 0.07; }
              header .main-navbar .nav-menu > li .nav-submenu li a span.tren-budge {
                color: #212529;
                background: #eff2f7; }
              header .main-navbar .nav-menu > li .nav-submenu li a span.top-budge {
                color: #eff2f7;
                background-color: #7e7e7e; }
            header .main-navbar .nav-menu > li .nav-submenu li a .label {
              font-size: 12px;
              color: #ff0000;
              position: absolute;
              padding: 0 0 0 3px; }
            header .main-navbar .nav-menu > li .nav-submenu li a.menu-title-level1:after, header .main-navbar .nav-menu > li .nav-submenu li a.submenu-title:after {
              opacity: 1; }
            header .main-navbar .nav-menu > li .nav-submenu li a.arrow-cls:after {
              opacity: 0.8; }
            header .main-navbar .nav-menu > li .nav-submenu li a:hover {
              margin-left: 3px;
              color: var(--theme-color) !important; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
            position: absolute;
            width: 200px;
            right: -200px;
            top: 0;
            background: #fff;
            border: none;
            padding: 15px 0 15px 0;
            box-shadow: 0 0 1px 0 #ebebeb; }
      header .main-navbar .nav-menu > li .mega-menu-container {
        position: absolute;
        left: 0;
        z-index: 4;
        box-shadow: 0 1px 5px 0 rgba(90, 90, 90, 0.2);
        padding: 30px;
        background: #fff;
        width: 100%;
        border-radius: 4px; }
        header .main-navbar .nav-menu > li .mega-menu-container.poster-bg-image {
          background-image: url(../images/voxo.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden; }
          @media (max-width: 1199px) {
            header .main-navbar .nav-menu > li .mega-menu-container.poster-bg-image {
              background-image: unset; } }
          header .main-navbar .nav-menu > li .mega-menu-container.poster-bg-image .floating-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            height: 60vh;
            animation: floating 110s linear infinite reverse;
            background-repeat: repeat-x !important; }
        header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
          width: 16%; }
          @media (max-width: 1199px) {
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
              width: 100%; } }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box a .label {
            font-size: 12px;
            color: #ff0000;
            position: absolute;
            padding: 0 0 0 3px; }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
            font-weight: 500;
            text-transform: capitalize;
            font-size: 14px;
            margin-bottom: 14px; }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content {
            box-shadow: none;
            background-color: transparent;
            position: relative;
            display: block;
            padding: 0; }
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li {
              border: none;
              float: none;
              position: relative;
              display: block;
              margin: 5px 0; }
              header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
                border: 0 !important;
                background: transparent;
                color: #212529;
                border-radius: 0 !important;
                font-size: 14px;
                text-transform: capitalize;
                padding: 3px 0;
                display: block;
                letter-spacing: 0.07em;
                line-height: 1.9;
                position: relative; }
                @media (max-width: 1199px) {
                  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
                    padding: 0;
                    letter-spacing: 0.03em; } }
                header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a span {
                  padding: 0px 10px;
                  border-radius: 5px;
                  font-size: 12px;
                  color: var(--theme-color);
                  background: rgba(226, 36, 84, 0.07);
                  box-shadow: 0 8px 15px -8px var(--badge-color);
                  margin-left: 8px; }
                  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a span.tren-budge {
                    color: #212529;
                    background: #eff2f7; }
                  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a span.top-budge {
                    color: #eff2f7;
                    background-color: #7e7e7e; }
                header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a img {
                  width: 100%; }
                header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover {
                  margin-left: 4px;
                  color: var(--theme-color) !important; }
                header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a.megamenu-image {
                  width: 100%;
                  height: 250px;
                  overflow: hidden; }
                  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a.megamenu-image img {
                    transition: all 0.3s ease; }
                    @media (max-width: 1199px) {
                      header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a.megamenu-image img {
                        filter: blur(0);
                        height: 180px; } }
                  header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a.megamenu-image:hover {
                    margin-left: 0; }
                    header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a.megamenu-image:hover img {
                      filter: blur(0);
                      margin-left: 0; }
              header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li.highlight-link {
                position: relative; }
                header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li.highlight-link i {
                  color: var(--theme-color); }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section + .link-section {
            margin-top: 25px; }
      header .main-navbar .nav-menu > li.mega-menu {
        position: unset; }
        header .main-navbar .nav-menu > li.mega-menu.home-menu .mega-menu-container .mega-box .link-section ul {
          float: none;
          position: relative;
          display: block;
          margin: 5px 0;
          padding: 0;
          box-shadow: none; }
        header .main-navbar .nav-menu > li.mega-menu.home-menu ul li {
          width: 100%; }
          header .main-navbar .nav-menu > li.mega-menu.home-menu ul li .megamenu-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: block;
            padding: 0; }
            @media (max-width: 1199px) {
              header .main-navbar .nav-menu > li.mega-menu.home-menu ul li .megamenu-image {
                height: 180px; } }
            header .main-navbar .nav-menu > li.mega-menu.home-menu ul li .megamenu-image img {
              width: 100%;
              transition: all 0.3s ease; }
              @media (max-width: 1199px) {
                header .main-navbar .nav-menu > li.mega-menu.home-menu ul li .megamenu-image img {
                  filter: blur(0); } }
            header .main-navbar .nav-menu > li.mega-menu.home-menu ul li .megamenu-image:hover {
              margin-left: 0; }
              header .main-navbar .nav-menu > li.mega-menu.home-menu ul li .megamenu-image:hover img {
                filter: blur(0);
                margin-left: 0; }
        header .main-navbar .nav-menu > li.mega-menu.home-menu .megamenu-image-title {
          cursor: pointer;
          padding: 10px;
          text-align: center; }
          @media (max-width: 1199px) {
            header .main-navbar .nav-menu > li.mega-menu.home-menu .megamenu-image-title {
              text-align: center;
              margin-bottom: 20px; } }

@media (min-width: 1200px) {
  .responsive-btn {
    display: none; }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 250px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    transition: all 0.5s ease; }
    header .main-navbar .nav-menu > li .nav-submenu a .sub-arrow {
      position: absolute;
      right: 20px;
      top: 7px;
      color: rgba(0, 0, 0, 0.75);
      font-size: 10px;
      transition: all 0.5s ease; }
    header .main-navbar .nav-menu > li .nav-submenu li .level1 {
      display: none;
      border-radius: 4px; }
      header .main-navbar .nav-menu > li .nav-submenu li .level1 li .level2 {
        display: none;
        border-radius: 4px; }
        header .main-navbar .nav-menu > li .nav-submenu li .level1 li .level2 li .level3 {
          display: none; }
        header .main-navbar .nav-menu > li .nav-submenu li .level1 li .level2 li:hover .level3 {
          display: block; }
      header .main-navbar .nav-menu > li .nav-submenu li .level1 li:hover .level2 {
        display: block; }
    header .main-navbar .nav-menu > li .nav-submenu li:hover .level1 {
      display: block; }
    header .main-navbar .nav-menu > li .nav-submenu li a:hover .sub-arrow {
      right: 16px;
      color: #212529;
      transition: all 0.5s ease; }
  header .main-navbar .nav-menu > li.dropdown .menu-title {
    position: relative;
    padding-right: 18px; }
    header .main-navbar .nav-menu > li.dropdown .menu-title::after {
      position: absolute;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;
      font-size: 12px; }
  header .main-navbar .nav-menu > li .mega-menu-container {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease; }
  header .main-navbar .nav-menu > li:hover .nav-submenu {
    opacity: 1;
    z-index: 4;
    visibility: visible;
    margin-top: 28px;
    transition: all 0.5s ease; }
  header .main-navbar .nav-menu > li:hover .mega-menu-container {
    opacity: 1;
    visibility: visible;
    margin-top: 28px;
    transition: all 0.5s ease; }
  .toggle-nav {
    display: none; }
  .mobile-back {
    display: none !important; } }

@media (max-width: 1199px) {
  header .main-navbar .nav-menu {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    padding-bottom: 155px;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow-y: scroll;
    overflow-x: hidden; }
    header .main-navbar .nav-menu.opennav {
      right: 0; }
    header .main-navbar .nav-menu .back-btn {
      margin-bottom: 10px; }
      header .main-navbar .nav-menu .back-btn .mobile-back {
        cursor: pointer; }
        header .main-navbar .nav-menu .back-btn .mobile-back i {
          font-size: 14px;
          transform: rotate(180deg); }
    header .main-navbar .nav-menu > li {
      display: block;
      float: none;
      width: 100%;
      padding: 0; }
      header .main-navbar .nav-menu > li:nth-child(n + 2) {
        border-bottom: 1px solid rgba(221, 221, 221, 0.5);
        margin-bottom: 10px;
        padding-bottom: 10px; }
      header .main-navbar .nav-menu > li a {
        padding: 10px 15px;
        position: relative; }
        header .main-navbar .nav-menu > li a .sub-arrow {
          right: 20px;
          position: absolute; }
          header .main-navbar .nav-menu > li a .sub-arrow:before {
            content: "+";
            font-family: inherit;
            position: relative; }
      header .main-navbar .nav-menu > li > ul li a .according-menu {
        top: 2px; }
      header .main-navbar .nav-menu > li > ul li ul li a .according-menu {
        top: 1px; }
      header .main-navbar .nav-menu > li .nav-submenu {
        width: 100%;
        padding: 0;
        position: relative;
        display: none;
        box-shadow: none; }
        header .main-navbar .nav-menu > li .nav-submenu li a {
          position: relative; }
          header .main-navbar .nav-menu > li .nav-submenu li a:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #212529; }
            [dir="rtl"] header .main-navbar .nav-menu > li .nav-submenu li a:after {
              left: unset;
              right: 20px; }
          header .main-navbar .nav-menu > li .nav-submenu li a:hover::after {
            background-color: var(--theme-color); }
        header .main-navbar .nav-menu > li .nav-submenu.opensubmenu {
          display: block; }
        header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
          display: none;
          position: relative;
          right: 0;
          width: 100%;
          padding: 0;
          box-shadow: none; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.submenu-content.level2 li a {
            padding: 0 60px; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.submenu-content.level2 .level3 li a {
            padding: 0 70px; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.opensubchild {
            display: block; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
            padding: 0 45px; }
      header .main-navbar .nav-menu > li .mega-menu-container {
        display: none;
        padding: 0;
        position: relative;
        box-shadow: none; }
        header .main-navbar .nav-menu > li .mega-menu-container.opensubmenu {
          display: block; }
        header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
          width: 100%;
          padding: 0; }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title {
            position: relative; }
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
              margin-bottom: 0;
              font-weight: 400;
              line-height: 1.9;
              padding: 2px 25px; }
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-title .according-menu {
              top: 7px; }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content {
            display: none; }
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu {
              display: block;
              padding: 0 45px; }
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
              line-height: 1.9; }
              header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover {
                margin-left: 0; }
                header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover:after {
                  display: none; }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section + .link-section {
            margin-top: 0; }
      header .main-navbar .nav-menu > li .nav-link.active {
        background-color: transparent;
        box-shadow: none;
        color: #212529;
        transition: all 0.5s ease; }
        header .main-navbar .nav-menu > li .nav-link.active:hover {
          color: var(--theme-color);
          transition: all 0.5s ease; }
    header .main-navbar .nav-menu .mobile-poster {
      border-bottom: none !important;
      margin: 0 !important;
      position: fixed;
      bottom: 0;
      right: -410px;
      width: 300px;
      padding: 20px !important;
      background-color: #eff2f7;
      align-items: center;
      transition: all 0.3s ease; }
      header .main-navbar .nav-menu .mobile-poster img {
        width: 50px; }
      header .main-navbar .nav-menu .mobile-poster .mobile-contain {
        margin-left: 15px; }
        header .main-navbar .nav-menu .mobile-poster .mobile-contain h5 {
          font-weight: 600;
          font-size: 15px; }
        header .main-navbar .nav-menu .mobile-poster .mobile-contain p {
          font-size: 13px;
          margin: 7px 0; }
  header .menu nav {
    margin-left: auto;
    margin-right: 30px; }
  .mega-box .link-section .demo ul li a span img {
    top: 30px;
    right: -60px; }
  .mega-box.col {
    flex-basis: unset; }
  .according-menu {
    position: absolute;
    right: 20px;
    top: 10px; }
    [dir="rtl"] .according-menu {
      right: unset;
      left: 20px; } }

/**=====================
     home section css start
==========================**/
.home-section {
  overflow: hidden; }
  .home-section .home-slider {
    width: 100%;
    height: 736px;
    position: relative; }
    [dir="rtl"] .home-section .home-slider {
      direction: rtl; }
    @media (max-width: 1199px) {
      .home-section .home-slider {
        height: 600px; } }
    @media (max-width: 991px) {
      .home-section .home-slider {
        background-position: left !important; }
        [dir="rtl"] .home-section .home-slider {
          background-position: right !important; } }
    @media (max-width: 575px) {
      .home-section .home-slider {
        height: 370px; } }
    @media (max-width: 360px) {
      .home-section .home-slider {
        height: 300px; } }
  .home-section .home-wrap {
    height: 100%; }
    .home-section .home-wrap h4 {
      font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      margin-top: -4px; }
      @media (max-width: 1199px) {
        .home-section .home-wrap h4 {
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1199 - 320))); } }
    .home-section .home-wrap h6 {
      color: #212529; }
    .home-section .home-wrap p {
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.5;
      color: #212529; }
      @media (max-width: 1660px) {
        .home-section .home-wrap p {
          max-width: 90%; } }
      @media (max-width: 1366px) {
        .home-section .home-wrap p {
          max-width: 80%; } }
      @media (max-width: 991px) {
        .home-section .home-wrap p {
          max-width: 89%; } }
    .home-section .home-wrap .discover-block {
      margin-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))); }
      [dir="rtl"] .home-section .home-wrap .discover-block {
        text-align: right; }
      @media (max-width: 480px) {
        .home-section .home-wrap .discover-block {
          margin-top: 10px; } }
      .home-section .home-wrap .discover-block .d-flex {
        align-items: center; }
      .home-section .home-wrap .discover-block .play-icon {
        width: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
        height: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        position: relative;
        z-index: 1; }
        .home-section .home-wrap .discover-block .play-icon::before {
          content: "";
          position: absolute;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          background-color: var(--theme-color);
          border-radius: 100%;
          opacity: 0.1;
          left: -5px;
          top: -5px;
          z-index: -1; }
        .home-section .home-wrap .discover-block .play-icon i {
          margin-left: 3px; }
          [dir="rtl"] .home-section .home-wrap .discover-block .play-icon i {
            margin-right: 0;
            margin-left: unset; }
      .home-section .home-wrap .discover-block .discover-content {
        margin-left: 20px; }
        @media (max-width: 360px) {
          .home-section .home-wrap .discover-block .discover-content {
            margin-left: 18px; } }
        [dir="rtl"] .home-section .home-wrap .discover-block .discover-content {
          margin-right: 20px;
          margin-left: unset;
          text-align: right; }
          @media (max-width: 360px) {
            [dir="rtl"] .home-section .home-wrap .discover-block .discover-content {
              margin-right: 18px; } }
  .home-section .home-social {
    position: absolute; }
    .home-section .home-social ul {
      display: flex;
      align-items: center; }
      .home-section .home-social ul li a {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))); }
      .home-section .home-social ul li img {
        margin-right: 5px; }
        .home-section .home-social ul li img [dir="rtl"] {
          margin-right: unset;
          margin-left: 5px; }
      .home-section .home-social ul li span {
        vertical-align: -2px;
        font-weight: 600;
        color: #212529; }

.poster-section-5 {
  height: 750px;
  background-image: url(../images/flower/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1660px) {
    .poster-section-5 {
      height: 700px; } }
  @media (max-width: 1366px) {
    .poster-section-5 {
      height: 620px; } }
  @media (max-width: 1199px) {
    .poster-section-5 {
      height: 550px; } }
  @media (max-width: 991px) {
    .poster-section-5 {
      height: 400px; } }
  @media (max-width: 575px) {
    .poster-section-5 {
      height: 350px; } }
  @media (max-width: 360px) {
    .poster-section-5 {
      height: 350px; } }
  .poster-section-5 .prev {
    right: 11% !important;
    bottom: 50%; }
    [dir="rtl"] .poster-section-5 .prev i {
      margin-right: 16px !important;
      margin-left: 0 !important; }
    @media (max-width: 1660px) {
      .poster-section-5 .prev {
        bottom: 40%; } }
    @media (max-width: 1460px) {
      .poster-section-5 .prev {
        right: 12% !important; } }
    @media (max-width: 1366px) {
      .poster-section-5 .prev {
        right: 12% !important; } }
    @media (max-width: 1199px) {
      .poster-section-5 .prev {
        right: 13% !important;
        bottom: 30%; } }
    @media (max-width: 991px) {
      .poster-section-5 .prev {
        right: unset !important;
        left: 9% !important;
        bottom: 30%; }
        [dir="rtl"] .poster-section-5 .prev {
          right: unset !important;
          left: 71% !important; } }
    @media (max-width: 767px) {
      .poster-section-5 .prev {
        left: 52px !important;
        bottom: 24%; } }
    @media (max-width: 575px) {
      .poster-section-5 .prev {
        right: unset !important;
        left: 11% !important;
        bottom: 38%; }
        [dir="rtl"] .poster-section-5 .prev {
          right: unset !important;
          left: 26% !important;
          bottom: 38%; } }
    @media (max-width: 480px) {
      .poster-section-5 .prev {
        right: unset !important;
        left: 42% !important; }
        [dir="rtl"] .poster-section-5 .prev {
          right: 51% !important;
          left: unset !important; } }
    @media (max-width: 360px) {
      .poster-section-5 .prev {
        bottom: 36%;
        left: 40% !important; } }
  .poster-section-5 .next {
    right: 5% !important;
    bottom: 50%; }
    [dir="rtl"] .poster-section-5 .next i {
      margin-left: 16px !important;
      margin-right: 0 !important; }
    @media (max-width: 1660px) {
      .poster-section-5 .next {
        bottom: 40%; } }
    .poster-section-5 .next:before {
      left: -23px !important; }
    @media (max-width: 1660px) {
      .poster-section-5 .next {
        right: 6% !important; } }
    @media (max-width: 1199px) {
      .poster-section-5 .next {
        right: 5% !important;
        bottom: 30%; } }
    @media (max-width: 991px) {
      .poster-section-5 .next {
        right: unset !important;
        left: 18% !important;
        bottom: 30%; }
        [dir="rtl"] .poster-section-5 .next {
          right: unset !important;
          left: 81% !important; } }
    @media (max-width: 767px) {
      .poster-section-5 .next {
        left: 140px !important;
        bottom: 24%; } }
    @media (max-width: 575px) {
      .poster-section-5 .next {
        bottom: 38%;
        left: 18% !important; }
        [dir="rtl"] .poster-section-5 .next {
          right: unset !important;
          left: 32% !important;
          bottom: 38%; } }
    @media (max-width: 480px) {
      .poster-section-5 .next {
        right: 47% !important;
        left: unset !important; }
        [dir="rtl"] .poster-section-5 .next {
          right: 41% !important;
          left: unset !important; } }
    @media (max-width: 480px) {
      .poster-section-5 .next {
        right: 42% !important;
        left: unset !important; } }
    @media (max-width: 360px) {
      .poster-section-5 .next {
        bottom: 36%; } }
  .poster-section-5 .background-circle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -4%; }
    @media (max-width: 1460px) {
      .poster-section-5 .background-circle {
        margin-top: -1%; } }
    @media (max-width: 991px) {
      .poster-section-5 .background-circle {
        width: 60%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -7%; } }
    @media (max-width: 767px) {
      .poster-section-5 .background-circle {
        margin-top: -2%; } }
    @media (max-width: 575px) {
      .poster-section-5 .background-circle {
        margin-top: -5%;
        width: 80%; } }
    .poster-section-5 .background-circle img {
      z-index: 0;
      width: auto;
      height: auto;
      object-fit: contain;
      animation: rounded 20s infinite linear; }
  @media (max-width: 991px) {
    .poster-section-5 .flower-image {
      display: none; } }
  .poster-section-5 .flower-image .flower-1 {
    position: absolute;
    bottom: -70px;
    left: -70px; }
  .poster-section-5 .flower-image .flower-2 {
    position: absolute;
    top: -70px;
    right: -70px;
    transform: rotate(-180deg); }
  .poster-section-5 .poster-image {
    background-color: transparent !important;
    z-index: 1; }
    .poster-section-5 .poster-image img {
      margin: 0 auto;
      margin-top: 4%;
      height: 1100px;
      animation: rotate-flower 45s infinite linear; }
      @media (max-width: 1660px) {
        .poster-section-5 .poster-image img {
          height: 850px; } }
      @media (max-width: 1366px) {
        .poster-section-5 .poster-image img {
          height: 775px; } }
      @media (max-width: 1199px) {
        .poster-section-5 .poster-image img {
          height: 600px; } }
      @media (max-width: 991px) {
        .poster-section-5 .poster-image img {
          margin: 0 auto;
          height: 470px;
          margin-top: 7%;
          margin-left: 35%; } }
      @media (max-width: 767px) {
        .poster-section-5 .poster-image img {
          height: 440px; } }
      @media (max-width: 575px) {
        .poster-section-5 .poster-image img {
          display: block;
          position: relative;
          object-fit: contain;
          height: 400px;
          margin-left: auto;
          margin-right: 65px; }
          [dir="rtl"] .poster-section-5 .poster-image img {
            margin-right: unset;
            margin-left: 65px; } }
      @media (max-width: 480px) {
        .poster-section-5 .poster-image img {
          margin-right: auto; }
          [dir="rtl"] .poster-section-5 .poster-image img {
            margin-left: auto;
            margin-right: auto; } }
  .poster-section-5 .slider-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100px;
    text-align: right;
    display: flex;
    align-items: center;
    z-index: 1; }
    [dir="rtl"] .poster-section-5 .slider-nav {
      right: unset;
      left: 100px; }
    @media (max-width: 1660px) {
      .poster-section-5 .slider-nav {
        right: 90px; }
        [dir="rtl"] .poster-section-5 .slider-nav {
          right: unset;
          left: 90px; } }
    @media (max-width: 1460px) {
      .poster-section-5 .slider-nav {
        right: 75px; }
        [dir="rtl"] .poster-section-5 .slider-nav {
          right: unset;
          left: 75px; } }
    @media (max-width: 1199px) {
      .poster-section-5 .slider-nav {
        right: 50px; }
        [dir="rtl"] .poster-section-5 .slider-nav {
          right: unset;
          left: 50px; } }
    [dir="rtl"] .poster-section-5 .slider-nav div {
      float: none; }
    .poster-section-5 .slider-nav div + div {
      margin-top: 20px; }
      @media (max-width: 1199px) {
        .poster-section-5 .slider-nav div + div {
          margin-top: 8px; } }
    .poster-section-5 .slider-nav .poster-img {
      position: relative;
      display: inline-block;
      cursor: pointer;
      overflow: hidden;
      border-radius: 4px; }
      @media (max-width: 991px) {
        .poster-section-5 .slider-nav .poster-img {
          width: 90%; } }
      @media (max-width: 575px) {
        .poster-section-5 .slider-nav .poster-img {
          display: none; } }
      .poster-section-5 .slider-nav .poster-img img {
        cursor: pointer;
        display: flex;
        position: relative;
        overflow: hidden;
        z-index: 1; }
      .poster-section-5 .slider-nav .poster-img .overlay-color {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transition: 0.5s ease;
        border-radius: 5px;
        opacity: 0;
        background-color: var(--theme-color); }
        .poster-section-5 .slider-nav .poster-img .overlay-color p {
          background-color: #fff;
          font-size: 18px;
          border-radius: 5px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          margin-bottom: 0; }
    .poster-section-5 .slider-nav .slick-current .overlay-color {
      transform: scale(100%);
      opacity: 1; }

.banner-plus {
  position: absolute;
  top: 12%;
  left: 38%; }
  @media (max-width: 1660px) {
    .banner-plus {
      top: 11%;
      left: 37%; } }
  @media (max-width: 1460px) {
    .banner-plus {
      left: 35%; } }
  @media (max-width: 1366px) {
    .banner-plus {
      top: 10%;
      left: 36%; } }
  @media (max-width: 1199px) {
    .banner-plus {
      display: none; } }
  .banner-plus a:hover {
    color: var(--theme-color); }
  .banner-plus a .fa-plus {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 50%;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    animation: ripple-2 0.7s linear infinite; }

.left-side-contain {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
  display: flex;
  align-items: center;
  width: 20%;
  z-index: 1; }
  [dir="rtl"] .left-side-contain {
    left: unset;
    right: 100px; }
  @media (max-width: 1660px) {
    .left-side-contain {
      left: 90px; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 90px; } }
  @media (max-width: 1460px) {
    .left-side-contain {
      left: 75px; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 75px; } }
  @media (max-width: 1366px) {
    .left-side-contain {
      left: 65px; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 65px; } }
  @media (max-width: 1199px) {
    .left-side-contain {
      width: 30%;
      left: 50px; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 50px; } }
  @media (max-width: 991px) {
    .left-side-contain {
      left: 90px; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 90px; } }
  @media (max-width: 767px) {
    .left-side-contain {
      left: 50px;
      width: 30%; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 50px; } }
  @media (max-width: 575px) {
    .left-side-contain {
      width: 40%;
      left: 54px; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 54px; } }
  @media (max-width: 480px) {
    .left-side-contain {
      width: 100%;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      text-align: center;
      color: #fff;
      display: block;
      z-index: 1; }
      [dir="rtl"] .left-side-contain {
        left: unset;
        right: 0; } }
  @media (max-width: 1366px) {
    .left-side-contain .banner-left {
      margin-bottom: 170px; } }
  @media (max-width: 991px) {
    .left-side-contain .banner-left {
      margin-bottom: 0; } }
  @media (max-width: 480px) {
    .left-side-contain .banner-left {
      margin: 0 10px;
      background-color: rgba(223, 66, 70, 0.7);
      padding: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
      backdrop-filter: blur(5px); } }
  .left-side-contain .banner-left h4 {
    color: #212529;
    margin-top: -2px; }
    @media (max-width: 480px) {
      .left-side-contain .banner-left h4 {
        margin-top: -3px;
        color: #fff; } }
    @media (max-width: 480px) {
      .left-side-contain .banner-left h4 span {
        color: #ddd !important;
        font-weight: 400; } }
  .left-side-contain .banner-left h1 {
    margin: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320))) 0;
    line-height: 1.2;
    margin-left: -4px;
    color: #212529;
    font-size: calc(23px + (70 - 23) * ((100vw - 320px) / (1920 - 320))); }
    .left-side-contain .banner-left h1 span {
      font-weight: normal; }
      @media (max-width: 480px) {
        .left-side-contain .banner-left h1 span {
          color: #ddd; } }
    @media (max-width: 480px) {
      .left-side-contain .banner-left h1 {
        color: #fff; } }
  .left-side-contain .banner-left p {
    color: #212529;
    letter-spacing: 10px; }
    @media (max-width: 1199px) {
      .left-side-contain .banner-left p {
        display: none; } }
  .left-side-contain .banner-left h2 {
    color: #212529;
    font-size: calc(20px + (45 - 20) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 1199px) {
      .left-side-contain .banner-left h2 {
        margin-bottom: -1px; } }
    @media (max-width: 480px) {
      .left-side-contain .banner-left h2 {
        color: #fff; } }
    .left-side-contain .banner-left h2 span {
      font-size: calc(17px + (32 - 17) * ((100vw - 320px) / (1920 - 320))); }
      @media (max-width: 480px) {
        .left-side-contain .banner-left h2 span {
          color: #ddd !important;
          font-weight: 400; } }
  .left-side-contain .banner-left .poster-details {
    letter-spacing: 0;
    margin: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320))) 0;
    margin-bottom: -2px; }
  .left-side-contain .banner-left .color-change {
    font-weight: 600;
    font-size: 22px;
    margin-top: 10px;
    display: flex;
    align-items: center; }
    @media (max-width: 1199px) {
      .left-side-contain .banner-left .color-change {
        display: none; } }
    .left-side-contain .banner-left .color-change h3 {
      font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
      margin-right: 10px;
      color: #212529;
      font-weight: bolder; }
    .left-side-contain .banner-left .color-change li {
      background-color: #c7c7c5;
      width: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      height: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 50%;
      position: relative;
      margin-right: 10px; }
      .left-side-contain .banner-left .color-change li::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        height: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        background-color: #000; }
      .left-side-contain .banner-left .color-change li:nth-child(2) {
        background-color: var(--theme-color); }
        .left-side-contain .banner-left .color-change li:nth-child(2)::after {
          background-color: #fff; }

.right-side-contain {
  position: absolute;
  top: 100px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1660px) {
    .right-side-contain {
      right: 90px; } }
  @media (max-width: 1460px) {
    .right-side-contain {
      right: 75px; } }
  @media (max-width: 1199px) {
    .right-side-contain {
      display: none; } }
  .right-side-contain .social-image {
    display: flex;
    align-items: center;
    justify-content: center; }
    .right-side-contain .social-image + .social-image {
      margin-left: 30px; }

/* =====================
    Slick SLider CSS Start 
 ========================== */
.box-arrow .slick-prev {
  left: calc(5px + (25 - 5) * ((100vw - 320px) / (1920 - 320)));
  z-index: 1; }
  @media (max-width: 767px) {
    .box-arrow .slick-prev {
      left: 10px; } }
  .box-arrow .slick-prev::before {
    content: "\f30a";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    color: #212529;
    font-size: 40px; }

.box-arrow .slick-next {
  right: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
  z-index: 1; }
  .box-arrow .slick-next::before {
    content: "\f30b";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    right: 0;
    color: #212529;
    font-size: 40px; }

.slick-dots li {
  width: 10px;
  height: 10px; }
  .slick-dots li.slick-active {
    background-color: var(--theme-color);
    border-radius: 50px; }
    .slick-dots li.slick-active button {
      background-color: transparent; }
  .slick-dots li button {
    background-color: #dedede;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    color: transparent; }
    .slick-dots li button::before {
      content: ""; }

.round-arrow {
  z-index: 1; }
  .round-arrow .slick-prev {
    background-color: #eaeaea;
    position: absolute;
    top: -100px;
    left: unset;
    right: 85px;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%; }
    [dir="rtl"] .round-arrow .slick-prev {
      left: 25px;
      right: unset; }
    @media (max-width: 1660px) {
      .round-arrow .slick-prev {
        top: -90px;
        right: 85px; } }
    @media (max-width: 1199px) {
      .round-arrow .slick-prev {
        top: -80px;
        right: 70px; } }
    @media (max-width: 767px) {
      .round-arrow .slick-prev {
        top: -68px;
        right: 70px; } }
    .round-arrow .slick-prev::before {
      content: "\f053";
      color: #000;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      z-index: 1;
      font-size: 16px; }
  .round-arrow .slick-next {
    background-color: #eaeaea;
    position: absolute;
    top: -100px;
    right: 25px;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%; }
    [dir="rtl"] .round-arrow .slick-next {
      right: unset;
      left: 85px; }
    @media (max-width: 1660px) {
      .round-arrow .slick-next {
        top: -90px;
        right: 25px; } }
    @media (max-width: 1199px) {
      .round-arrow .slick-next {
        top: -80px;
        right: 15px; } }
    @media (max-width: 767px) {
      .round-arrow .slick-next {
        top: -68px;
        right: 15px; } }
    .round-arrow .slick-next::before {
      content: "\f054";
      color: #000;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      z-index: 1;
      font-size: 16px; }

.round-arrow1 {
  z-index: 1; }
  .round-arrow1 .slick-prev {
    background-color: #eff2f7;
    position: absolute;
    top: -77px;
    left: unset;
    right: 85px;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%; }
    [dir="rtl"] .round-arrow1 .slick-prev {
      right: unset;
      left: 25px; }
    @media (max-width: 1660px) {
      .round-arrow1 .slick-prev {
        top: -74px;
        right: 85px; } }
    @media (max-width: 1460px) {
      .round-arrow1 .slick-prev {
        top: -68px;
        right: 85px; } }
    @media (max-width: 991px) {
      .round-arrow1 .slick-prev {
        top: -58px;
        right: 70px; } }
    @media (max-width: 767px) {
      .round-arrow1 .slick-prev {
        top: -50px;
        right: 70px;
        width: 35px;
        height: 35px; } }
    @media (max-width: 360px) {
      .round-arrow1 .slick-prev {
        top: -45px;
        right: 61px; } }
    .round-arrow1 .slick-prev::before {
      content: "\f053";
      color: #000;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      z-index: 1;
      font-size: 16px; }
  .round-arrow1 .slick-next {
    background-color: #eff2f7;
    position: absolute;
    top: -77px;
    right: 25px;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%; }
    [dir="rtl"] .round-arrow1 .slick-next {
      right: unset;
      left: 85px; }
    @media (max-width: 1660px) {
      .round-arrow1 .slick-next {
        top: -74px;
        right: 25px; } }
    @media (max-width: 1460px) {
      .round-arrow1 .slick-next {
        top: -68px;
        right: 25px; } }
    @media (max-width: 1199px) {
      .round-arrow1 .slick-next {
        top: -65px;
        right: 15px; } }
    @media (max-width: 991px) {
      .round-arrow1 .slick-next {
        top: -58px;
        right: 15px; } }
    @media (max-width: 767px) {
      .round-arrow1 .slick-next {
        top: -50px;
        right: 15px;
        width: 35px;
        height: 35px; } }
    @media (max-width: 360px) {
      .round-arrow1 .slick-next {
        top: -45px;
        right: 15px; } }
    .round-arrow1 .slick-next::before {
      content: "\f054";
      color: #000;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      z-index: 1;
      font-size: 16px; }

.white-arrow .slick-prev {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -70px;
  margin-top: -25px; }
  [dir="rtl"] .white-arrow .slick-prev {
    right: -80px;
    left: unset; }
  .white-arrow .slick-prev:active {
    color: var(--theme-color); }
  @media (max-width: 991px) {
    .white-arrow .slick-prev {
      left: unset;
      right: 90px;
      top: -80px;
      width: 40px;
      height: 40px;
      margin-top: 0; }
      [dir="rtl"] .white-arrow .slick-prev {
        right: unset;
        left: 30px; } }
  @media (max-width: 360px) {
    .white-arrow .slick-prev {
      top: -58px; } }
  .white-arrow .slick-prev::before {
    content: "\f053";
    font-family: "Font Awesome 5 Free";
    color: #000;
    font-weight: 900; }

.white-arrow .slick-next {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -70px;
  margin-top: 20px; }
  [dir="rtl"] .white-arrow .slick-next {
    right: -80px;
    left: unset; }
  .white-arrow .slick-next:active {
    color: var(--theme-color); }
  @media (max-width: 991px) {
    .white-arrow .slick-next {
      left: unset;
      right: 30px;
      top: -80px;
      width: 40px;
      height: 40px;
      margin-top: 0; }
      [dir="rtl"] .white-arrow .slick-next {
        right: unset;
        left: 90px; } }
  @media (max-width: 360px) {
    .white-arrow .slick-next {
      top: -58px; } }
  .white-arrow .slick-next::before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    color: #000;
    font-weight: 900; }

/**=====================
    Poster Section SCSS Start
==========================**/
.poster-section .poster-image {
  height: 100%;
  background-color: #f0f3f8;
  position: relative; }
  .poster-section .poster-image .poster-image-details {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 42%;
    margin-right: 35px;
    color: var(--theme-color); }
    @media (max-width: 480px) {
      .poster-section .poster-image .poster-image-details {
        margin-right: 6px; } }
    @media (max-width: 360px) {
      .poster-section .poster-image .poster-image-details {
        margin-right: 10px; } }
    .poster-section .poster-image .poster-image-details h2 {
      font-size: calc(16px + (35 - 16) * ((100vw - 320px) / (1920 - 320)));
      color: #212529;
      line-height: 1.2;
      margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))); }
    .poster-section .poster-image .poster-image-details h5 {
      font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
      margin-top: -2px; }
    .poster-section .poster-image .poster-image-details p {
      color: #38383899;
      font-size: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      line-height: 1.1; }
    .poster-section .poster-image .poster-image-details h3 {
      font-size: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: bold;
      color: #212529;
      margin: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))) 0; }
      .poster-section .poster-image .poster-image-details h3 span {
        color: var(--theme-color);
        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))); }

/**=====================
     Arrivals Scss Start
==========================**/
.arrival-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .arrival-image .arrival-contain {
    background-color: #ff0000; }
    .arrival-image .arrival-contain .arrival-price {
      position: absolute;
      text-align: right;
      padding: 30px;
      top: 0;
      right: 0; }
      @media (max-width: 1366px) {
        .arrival-image .arrival-contain .arrival-price {
          padding: 20px; } }
      @media (max-width: 575px) {
        .arrival-image .arrival-contain .arrival-price {
          padding: 15px; } }
    .arrival-image .arrival-contain .arrival-full {
      position: absolute;
      padding: 30px;
      bottom: 0; }
      @media (max-width: 1366px) {
        .arrival-image .arrival-contain .arrival-full {
          padding: 20px; } }
      @media (max-width: 575px) {
        .arrival-image .arrival-contain .arrival-full {
          padding: 15px; } }
      .arrival-image .arrival-contain .arrival-full h3 {
        font-weight: bold; }
    .arrival-image .arrival-contain .rating-star {
      position: absolute;
      padding: 30px;
      bottom: 0;
      right: 0; }
      @media (max-width: 1366px) {
        .arrival-image .arrival-contain .rating-star {
          padding: 20px; } }
      @media (max-width: 575px) {
        .arrival-image .arrival-contain .rating-star {
          padding: 15px; } }
      .arrival-image .arrival-contain .rating-star li .feather {
        stroke: var(--theme-color);
        fill: var(--theme-color);
        width: 18px; }
        @media (max-width: 360px) {
          .arrival-image .arrival-contain .rating-star li .feather {
            width: 13px; } }
      .arrival-image .arrival-contain .rating-star li:nth-child(5) .feather {
        stroke: #000;
        fill: #000; }

/**=====================
    Subsribe css Start
==========================**/
.subscribe-section {
  background-color: #eff3f7; }
  .subscribe-section-light {
    background-color: #f0f8f0; }
  .subscribe-section .subscribe-details {
    height: 100%; }
    .subscribe-section .subscribe-details h2 {
      font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      margin-top: -2px; }
    .subscribe-section .subscribe-details h6 {
      font-size: 15px;
      font-weight: normal;
      display: block;
      margin-bottom: -3px;
      line-height: 1.6; }
  .subscribe-section .subsribe-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .subscribe-section .subsribe-input .form-control {
      border-radius: 0; }

/**=====================
    service section css start
==========================**/
@media (max-width: 1460px) {
  .service-section {
    margin-bottom: -3px; } }

.service-section .service-wrap {
  display: flex;
  align-items: center; }
  .service-section .service-wrap .service-icon svg {
    width: 55px;
    height: 55px;
    fill: var(--theme-color);
    stroke: var(--theme-color);
    margin-right: 20px; }
    [dir="rtl"] .service-section .service-wrap .service-icon svg {
      margin-right: 0;
      margin-left: 20px; }
    @media (max-width: 1460px) {
      .service-section .service-wrap .service-icon svg {
        width: 40px;
        height: 40px; } }
    @media (max-width: 767px) {
      .service-section .service-wrap .service-icon svg {
        width: 35px;
        height: 35px; } }
  .service-section .service-wrap .service-content h3 {
    margin-top: -3px; }
    @media (max-width: 1199px) {
      .service-section .service-wrap .service-content h3 {
        margin-bottom: 2px !important; } }
  .service-section .service-wrap .service-content span {
    display: block;
    margin-bottom: -3px;
    line-height: 1.6;
    letter-spacing: 0.05em; }

.service-section.about-page .service-wrap .service-icon svg {
  width: 45px;
  height: 45px; }

.service-style-2 .service-wrap {
  display: block;
  text-align: center;
  margin-bottom: -2px; }
  .service-style-2 .service-wrap .service-icon {
    margin-bottom: 20px; }

.service-style-2 .row > div {
  position: relative; }
  .service-style-2 .row > div + div::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 50px;
    background-color: #f0f3f8; }
  @media (max-width: 1199px) {
    .service-style-2 .row > div + div:nth-child(3):before {
      content: "";
      display: none; } }
  @media (max-width: 575px) {
    .service-style-2 .row > div + div:nth-child(even):before {
      content: "";
      display: none; } }

/**=====================
     product css start
==========================**/
.tab-section .product-style-1 .product-box .product-details h5 {
  margin-left: 0; }
  [dir="rtl"] .tab-section .product-style-1 .product-box .product-details h5 {
    margin-left: unset;
    margin-right: 0; }
  .tab-section .product-style-1 .product-box .product-details h5.main-title {
    margin: 0 auto; }
    [dir="rtl"] .tab-section .product-style-1 .product-box .product-details h5.main-title {
      margin: 0 auto; }

.rating {
  display: block;
  margin-bottom: -4px; }
  [dir="rtl"] .rating {
    padding: 0; }
  .rating li i {
    color: #c3c3c3 !important; }
    .rating li i.theme-color {
      color: #ffa202 !important; }

.heart-wishlist {
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
  background-color: #fff;
  border-radius: 100%; }
  .heart-wishlist .far {
    margin-top: 1px;
    margin-left: -1px; }
    [dir="rtl"] .heart-wishlist .far {
      margin-left: unset;
      margin-right: -1px; }
  .heart-wishlist::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color);
    opacity: 0.1;
    border-radius: 100%;
    transition: all 0.5s;
    z-index: -1; }
  .heart-wishlist.heart-color::after {
    background-color: transparent; }

.product-box {
  overflow: hidden; }
  [dir="rtl"] .product-box {
    direction: rtl; }
  .product-box:hover .img-wrapper .share {
    opacity: 1 !important;
    color: #fff; }
    .product-box:hover .img-wrapper .share-opacity {
      opacity: 1; }
  .product-box:hover .img-wrapper .share-plus {
    color: black;
    background-color: white;
    opacity: 1;
    border-radius: 50%; }
  .product-box:hover .img-wrapper.hover-image::after {
    opacity: 1; }
  .product-box:hover .img-wrapper.squre-image::after {
    opacity: 1; }
  .product-box:hover .img-wrapper .circle-shape {
    transform: scale(100%); }
  .product-box:hover .img-wrapper .background-text {
    opacity: 1; }
  .product-box .img-wrapper {
    position: relative;
    overflow: hidden; }
    .product-box .img-wrapper.hover-image::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(../images/vegetable/effect1.png);
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 0.5s ease; }
    .product-box .img-wrapper.squre-image::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(../images/vegetable/effect.png);
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 0.5s ease; }
      @media (max-width: 1199px) {
        .product-box .img-wrapper.squre-image::after {
          opacity: 1; } }
    .product-box .img-wrapper .circle-shape {
      position: absolute;
      background-color: rgba(232, 115, 22, 0.04);
      top: -80px;
      left: -80px;
      width: 100%;
      height: 87%;
      border-radius: 50%;
      z-index: 1;
      transform: scale(0);
      transition: 0.6s ease;
      transform-origin: top left; }
      .product-box .img-wrapper .circle-shape-1 {
        width: 390px;
        height: 390px;
        position: absolute;
        background-color: rgba(232, 115, 22, 0.04);
        top: -130px;
        left: -130px;
        border-radius: 50%;
        z-index: 1;
        transition: 0.6s ease; }
    .product-box .img-wrapper .background-text {
      text-transform: uppercase;
      position: absolute;
      top: 90px;
      right: -30px;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      font-weight: bolder;
      font-size: 68px;
      color: rgba(232, 115, 22, 0.1);
      user-select: none;
      opacity: 0;
      transition: 0.6s ease; }
  .product-box .cart-wrap {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 2; }
    @media (max-width: 1366px) {
      .product-box .cart-wrap {
        bottom: 20px; } }
    @media (max-width: 480px) {
      .product-box .cart-wrap {
        bottom: 15px; } }
    .product-box .cart-wrap .category-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
    .product-box .cart-wrap a,
    .product-box .cart-wrap button {
      padding: 15px;
      background-color: #fff;
      position: relative;
      z-index: 1; }
      @media (max-width: 1366px) {
        .product-box .cart-wrap a,
        .product-box .cart-wrap button {
          padding: 10px; } }
      @media (max-width: 480px) {
        .product-box .cart-wrap a,
        .product-box .cart-wrap button {
          padding: 5px; } }
      .product-box .cart-wrap a::after,
      .product-box .cart-wrap button::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--theme-color);
        transform: scale(0);
        transition: 0.5s;
        top: 0;
        left: 0;
        z-index: -1; }
        [dir="rtl"] .product-box .cart-wrap a::after, [dir="rtl"]
        .product-box .cart-wrap button::after {
          left: unset;
          right: 0; }
      .product-box .cart-wrap a svg,
      .product-box .cart-wrap button svg {
        width: 16px;
        height: 16px; }
        @media (max-width: 767px) {
          .product-box .cart-wrap a svg,
          .product-box .cart-wrap button svg {
            width: 14px;
            height: 14px; } }
        @media (max-width: 480px) {
          .product-box .cart-wrap a svg,
          .product-box .cart-wrap button svg {
            width: 13px;
            height: 13px; } }
      .product-box .cart-wrap a:hover,
      .product-box .cart-wrap button:hover {
        background-color: transparent;
        color: #fff; }
        .product-box .cart-wrap a:hover::after,
        .product-box .cart-wrap button:hover::after {
          transform: scale(1);
          transition: 0.5s; }
    .product-box .cart-wrap a {
      color: #232323; }
    .product-box .cart-wrap button {
      line-height: 0.8; }
    [dir="rtl"] .product-box .cart-wrap ul li + li {
      margin-right: 8px;
      margin-left: unset; }
  .product-box .product-details {
    margin-top: 15px; }
    .product-box .product-details h3 span {
      font-size: 15px;
      text-decoration: line-through;
      margin-left: 4px; }
      [dir="rtl"] .product-box .product-details h3 span {
        margin-left: 0;
        margin-right: 4px; }
    .product-box .product-details h5 {
      margin: 5px auto 6px auto;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      width: 90%;
      font-weight: 500; }
    .product-box .product-details .size-box {
      margin-top: 5px !important;
      margin: -1px; }
      .product-box .product-details .size-box li {
        color: #212529;
        margin: 1px;
        padding: 2px 6px;
        font-size: 13px;
        border-radius: 3px;
        background-color: #f0f7ef; }
      .product-box .product-details .size-box-2 li {
        background-color: #eff2f7; }
    .product-box .product-details .rating {
      display: block;
      margin-bottom: -4px; }
      .product-box .product-details .rating.rating-2 {
        margin-bottom: 0px; }
      .product-box .product-details .rating li i {
        font-size: 13px;
        color: #c3c3c3; }
        .product-box .product-details .rating li i.theme-color {
          color: #ffa202; }
  .product-box .label-dark {
    left: 0; }
    [dir="rtl"] .product-box .label-dark {
      float: right;
      left: unset;
      right: 0; }
      [dir="rtl"] [dir="rtl"] .product-box .label-dark {
        float: left; }
  .product-box .label-theme {
    float: right;
    right: 0; }
    [dir="rtl"] .product-box .label-theme {
      float: left;
      right: unset;
      left: 0; }
      [dir="rtl"] [dir="rtl"] .product-box .label-theme {
        float: right; }
  .product-box:hover .cart-wrap {
    opacity: 1;
    transition: all 0.5s ease; }
    .product-box:hover .cart-wrap ul li:first-child {
      animation: fadeInUp 400ms ease-in-out; }
    .product-box:hover .cart-wrap ul li:nth-child(2) {
      animation: fadeInUp 600ms ease-in-out; }
    .product-box:hover .cart-wrap ul li:nth-child(3) {
      animation: fadeInUp 800ms ease-in-out; }
    .product-box:hover .cart-wrap ul li:nth-child(4) {
      animation: fadeInUp 1000ms ease-in-out; }

.product-wrapper-1 .slick-dots {
  margin-top: -25px !important; }

.product-wrapper .slick-dots {
  position: relative;
  bottom: 0;
  margin-top: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320))); }
  .product-wrapper .slick-dots li {
    width: auto;
    height: auto; }
    .product-wrapper .slick-dots li button {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      padding: 0;
      margin: 0;
      background-color: rgba(35, 35, 35, 0.2);
      transition: all 0.5s ease; }
      .product-wrapper .slick-dots li button::before {
        display: none; }
    .product-wrapper .slick-dots li.slick-active button {
      margin: 0;
      width: 40px;
      background-color: var(--theme-color);
      transition: all 0.5s ease; }

.product-wrapper .timer {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  text-align: center; }

.product-wrapper.slick-slider.slick-dotted {
  margin-bottom: -2px; }

.product-banner-circle {
  overflow: hidden;
  height: 100%; }

@media (max-width: 991px) {
  .product-banner {
    order: -1; } }

.product-banner .product-box {
  padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) !important;
  height: 100%; }
  @media (max-width: 1199px) {
    .product-banner .product-box {
      padding: 20px !important; } }
  .product-banner .product-box .img-wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%; }

.product-banner .label-block {
  padding: 0;
  position: relative; }
  .product-banner .label-block ~ a {
    clear: both; }

.product-banner .offer-end {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  background-color: rgba(35, 35, 35, 0.8);
  backdrop-filter: blur(10px);
  color: #fff;
  width: 70%;
  text-align: center;
  z-index: 1; }
  .product-banner .offer-end-demo4 {
    background-color: rgba(232, 115, 22, 0.8); }
    .product-banner .offer-end-demo4 h3 {
      margin-top: -3px; }
    .product-banner .offer-end-demo4 h6 {
      margin-bottom: -1px; }
  @media (max-width: 1366px) {
    .product-banner .offer-end {
      padding: 20px; } }
  @media (max-width: 991px) {
    .product-banner .offer-end {
      width: unset; } }
  @media (max-width: 480px) {
    .product-banner .offer-end {
      width: 60%;
      padding: 15px; } }
  @media (max-width: 360px) {
    .product-banner .offer-end {
      width: 90%; } }
  .product-banner .offer-end h3 {
    margin-bottom: 10px; }
  .product-banner .offer-end h6 {
    font-weight: 400; }

.instagram-wrap .product-box {
  position: relative; }
  .instagram-wrap .product-box::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color);
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    transition: all 0.5s; }
  .instagram-wrap .product-box .img-wrapper img {
    max-height: unset !important;
    max-width: unset !important;
    height: 225px;
    width: 225px;
    object-fit: contain; }
    @media (max-width: 1366px) {
      .instagram-wrap .product-box .img-wrapper img {
        height: 195px;
        width: 195px; } }
    @media (max-width: 767px) {
      .instagram-wrap .product-box .img-wrapper img {
        height: 175px;
        width: 175px; } }
  .instagram-wrap .product-box .img-wrapper .share {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.6;
    writing-mode: vertical-rl;
    text-orientation: initial;
    position: absolute;
    top: 0; }
    .instagram-wrap .product-box .img-wrapper .share-opacity {
      opacity: 0;
      transition: 0.2s ease; }
    .instagram-wrap .product-box .img-wrapper .share-box {
      z-index: 2; }
    .instagram-wrap .product-box .img-wrapper .share .share-plus {
      position: relative;
      width: 25px;
      height: 25px;
      font-size: 16px;
      display: inline-block;
      text-align: center;
      color: var(--theme-color); }
      .instagram-wrap .product-box .img-wrapper .share .share-plus::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--theme-color);
        opacity: 0.1;
        border-radius: 100%;
        transition: all 0.5s;
        z-index: -1;
        z-index: 1;
        left: 0;
        top: 0; }
      .instagram-wrap .product-box .img-wrapper .share .share-plus-color {
        background-color: #fff;
        border-radius: 50%;
        animation: ripple-2 0.7s linear infinite; }
        .instagram-wrap .product-box .img-wrapper .share .share-plus-color::after {
          background-color: #fff; }
    .instagram-wrap .product-box .img-wrapper .share span:not(.share-plus) {
      letter-spacing: 10px;
      padding-top: 15px;
      color: #fff; }
      @media (max-width: 991px) {
        .instagram-wrap .product-box .img-wrapper .share span:not(.share-plus) {
          letter-spacing: 6px;
          font-size: 13px;
          padding-top: 10px; } }
  .instagram-wrap .product-box .insta-hover {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .instagram-wrap .product-box .insta-hover-gradient {
      background: #ec7a1f;
      background: linear-gradient(138deg, #ec7a1f 0%, #e3627b 50%, #a0768e 100%);
      opacity: 0; }
    .instagram-wrap .product-box .insta-hover h2 {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
      font-size: calc(65px + (80 - 65) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 900;
      opacity: 0.08; }
      @media (max-width: 991px) {
        .instagram-wrap .product-box .insta-hover h2 {
          top: 30px; } }
    .instagram-wrap .product-box .insta-hover h3 {
      font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      margin-bottom: 30px;
      line-height: 1.4; }
      .instagram-wrap .product-box .insta-hover h3.text-hide {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden; }
      .instagram-wrap .product-box .insta-hover h3.brand-name {
        font-size: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 20px;
        line-height: 1.2; }
      @media (max-width: 991px) {
        .instagram-wrap .product-box .insta-hover h3 {
          margin-bottom: 20px; } }
    .instagram-wrap .product-box .insta-hover h5 {
      font-weight: 400;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .instagram-wrap .product-box .insta-hover h5 {
          margin-bottom: 5px; } }
    .instagram-wrap .product-box .insta-hover .btn {
      font-weight: 500; }
  .instagram-wrap .product-box:hover .insta-hover {
    opacity: 1;
    backdrop-filter: blur(5px);
    visibility: visible;
    transition: all 0.5s; }
    .instagram-wrap .product-box:hover .insta-hover-gradient {
      background: #ec7a1f;
      background: linear-gradient(138deg, #ec7a1f 0%, #e3627b 50%, #a0768e 100%);
      opacity: 1; }
  .instagram-wrap .product-box:hover::after {
    opacity: 0.8;
    visibility: visible;
    transition: all 0.5s; }

.color-variant li {
  width: 30px;
  height: 30px;
  cursor: pointer; }
  .color-variant li + li {
    margin-left: 10px; }
  .color-variant li.selected {
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.16), 0 5px 15px 2px rgba(0, 0, 0, 0.05); }

.color-variant .bg-half-light {
  background-color: #f0f3f8;
  position: relative; }
  .color-variant .bg-half-light .half-black {
    background-color: #232323;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    display: inline-block;
    height: 100%; }

.color-variant .bg-blue1 {
  background-color: #88d1f4; }

.color-variant .bg-light1 {
  background-color: #f0f3f8; }

.color-variant .bg-black1 {
  background-color: #000; }

.label-price {
  position: absolute;
  text-align: right;
  padding: 30px;
  top: 0;
  right: 0; }

.product-style-2 .product-box .img-wrapper .back {
  opacity: 0;
  position: absolute;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  width: 100%; }

.product-style-2 .product-box .product-details h5 {
  margin: 0; }

.product-style-2 .product-box .product-details .rating-details,
.product-style-2 .product-box .product-details .main-price {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .product-style-2 .product-box .product-details .rating-details.main-shop,
  .product-style-2 .product-box .product-details .main-price.main-shop {
    display: block !important;
    text-align: center; }
    [dir="rtl"] .product-style-2 .product-box .product-details .rating-details.main-shop, [dir="rtl"]
    .product-style-2 .product-box .product-details .main-price.main-shop {
      text-align: right; }
  @media (max-width: 575px) {
    .product-style-2 .product-box .product-details .rating-details,
    .product-style-2 .product-box .product-details .main-price {
      display: block;
      text-align: left; }
      [dir="rtl"] .product-style-2 .product-box .product-details .rating-details, [dir="rtl"]
      .product-style-2 .product-box .product-details .main-price {
        text-align: right; } }

.product-style-2 .product-box .product-details .main-price {
  margin-top: 10px; }
  @media (max-width: 575px) {
    .product-style-2 .product-box .product-details .main-price {
      margin-top: 5px; }
      .product-style-2 .product-box .product-details .main-price h3 {
        margin-top: 5px; } }

.product-style-2 .product-box .cart-wrap ul li + li {
  margin-left: 0; }
  [dir="rtl"] .product-style-2 .product-box .cart-wrap ul li + li {
    margin-left: unset;
    margin-right: 0; }

.product-style-2 .product-box .cart-wrap a svg,
.product-style-2 .product-box .cart-wrap button svg {
  width: 14px;
  height: 14px; }
  @media (max-width: 575px) {
    .product-style-2 .product-box .cart-wrap a svg,
    .product-style-2 .product-box .cart-wrap button svg {
      width: 13px;
      height: 13px; } }

.product-style-2 .product-box:hover .img-wrapper .back {
  opacity: 1;
  backface-visibility: visible;
  transition: all 0.3s ease; }

@media (max-width: 1660px) {
  .product-style-2.slide-4 {
    padding: 0 30px; } }

.product-style-2.slide-4 .slick-prev {
  left: -70px; }
  @media (max-width: 1660px) {
    .product-style-2.slide-4 .slick-prev {
      left: -20px; } }
  @media (max-width: 575px) {
    .product-style-2.slide-4 .slick-prev {
      left: -5px; } }

.product-style-2.slide-4 .slick-next {
  right: -70px; }
  @media (max-width: 1660px) {
    .product-style-2.slide-4 .slick-next {
      right: -20px; } }
  @media (max-width: 575px) {
    .product-style-2.slide-4 .slick-next {
      right: -5px; } }

.product-style-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px; }
  @media (max-width: 575px) {
    .product-style-3.product-style-chair {
      display: block;
      text-align: center; } }
  .product-style-3.product-style-chair .product-title {
    text-align: left; }
    [dir="rtl"] .product-style-3.product-style-chair .product-title {
      text-align: right; }
    @media (max-width: 575px) {
      .product-style-3.product-style-chair .product-title {
        text-align: center; }
        [dir="rtl"] .product-style-3.product-style-chair .product-title {
          text-align: center; } }
    @media (max-width: 575px) {
      .product-style-3.product-style-chair .product-title a h5 {
        margin-bottom: 5px; } }
  .product-style-3.product-style-chair .main-price {
    text-align: right; }
    [dir="rtl"] .product-style-3.product-style-chair .main-price {
      text-align: left; }
    @media (max-width: 575px) {
      .product-style-3.product-style-chair .main-price {
        text-align: center; }
        [dir="rtl"] .product-style-3.product-style-chair .main-price {
          text-align: center; } }
  .product-style-3 .product-title {
    overflow: hidden;
    text-transform: capitalize; }
    .product-style-3 .product-title h5 {
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #212529;
      font-weight: 500; }
    .product-style-3 .product-title p {
      font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400; }
  .product-style-3 .main-price h3 {
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320))); }

.title-3 {
  margin-top: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320))); }
  [dir="rtl"] .title-3 {
    text-align: right; }
  .title-3 h2 {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320))); }

.product-image {
  border-radius: 6px;
  display: flex;
  align-items: center; }
  [dir="rtl"] .product-image {
    direction: rtl; }
  .product-image + .product-image {
    margin-top: 30px; }
  .product-image img {
    width: 90px;
    height: 90px;
    object-fit: cover; }
  .product-image .product-details {
    margin-left: 15px;
    overflow: hidden; }
    [dir="rtl"] .product-image .product-details {
      margin-left: 0;
      margin-right: 15px; }
    .product-image .product-details a {
      color: #232323; }
    .product-image .product-details h3 {
      font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 10px 0; }
    .product-image .product-details h4 span {
      margin-left: 10px; }

.product-style-4:hover .blog-header .blog-image .blog-date {
  opacity: 1; }

.product-style-4 .blog-header {
  background: #ec7a1f;
  background: linear-gradient(138deg, #ec7a1f 0%, #e3627b 50%, #a0768e 100%); }
  .product-style-4 .blog-header .blog-image {
    position: relative; }
    .product-style-4 .blog-header .blog-image .blog-date {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 0.5s ease; }
      .product-style-4 .blog-header .blog-image .blog-date.gradient-color::after {
        background: #a1f5a1;
        background: linear-gradient(129deg, #a1f5a1 0%, #266a0b 100%); }
      .product-style-4 .blog-header .blog-image .blog-date::after {
        content: "";
        position: absolute;
        background: #ec7a1f;
        background: linear-gradient(138deg, #ec7a1f 0%, #e3627b 50%, #a0768e 100%);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.8; }
      .product-style-4 .blog-header .blog-image .blog-date .date-hover {
        width: 100px;
        height: 100px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        backdrop-filter: blur(5px);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        text-align: center; }
        .product-style-4 .blog-header .blog-image .blog-date .date-hover h2 {
          font-size: calc(18px + (45 - 18) * ((100vw - 320px) / (1920 - 320)));
          font-weight: bolder; }
        .product-style-4 .blog-header .blog-image .blog-date .date-hover h3 {
          font-weight: 800;
          font-size: calc(13px + (23 - 13) * ((100vw - 320px) / (1920 - 320))); }

.product-style-4 .blog-footer {
  text-align: center;
  padding: 10px;
  position: relative; }
  .product-style-4 .blog-footer h2 {
    font-size: 70px;
    font-weight: bolder;
    opacity: 0.07;
    margin-top: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320))); }
  .product-style-4 .blog-footer h5 {
    position: absolute;
    top: calc(54px + (58 - 54) * ((100vw - 320px) / (1920 - 320)));
    left: 50%;
    transform: translateX(-50%);
    width: 100%; }
  .product-style-4 .blog-footer h4 {
    margin-bottom: 10px;
    font-size: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320))); }
  [dir="rtl"] .product-style-4 .blog-footer button {
    direction: rtl; }

/**=====================
     category css start
==========================**/
/**=====================
    category mixins css start
==========================**/
.category-section.ratio_40 .bg-size::before {
  padding-top: 20%; }

.category-wrap {
  padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
  position: relative;
  height: 130px;
  display: flex;
  align-items: center; }
  @media (max-width: 360px) {
    .category-wrap {
      height: unset; } }
  .category-wrap.category-color {
    background-color: #eff2f7; }
  @media (max-width: 1460px) {
    .category-wrap.category-padding {
      padding: 23px; } }
  @media (max-width: 1199px) {
    .category-wrap.category-padding {
      padding: 19px; } }
  .category-wrap.theme-bg-color {
    color: #fff;
    background-color: var(--theme-color); }
    .category-wrap.theme-bg-color .light-text {
      font-size: 72px;
      line-height: 96px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 0;
      position: absolute;
      opacity: 0.08;
      top: -10px;
      left: 0; }
      [dir="rtl"] .category-wrap.theme-bg-color .light-text {
        left: unset;
        right: 0; }
    .category-wrap.theme-bg-color h2 {
      margin-bottom: 10px; }
      .category-wrap.theme-bg-color h2.top-spacing {
        margin-top: -3px; }
    .category-wrap.theme-bg-color span {
      letter-spacing: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
      text-transform: uppercase;
      display: block;
      margin-bottom: -5px; }
  .category-wrap .category-content {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%); }
    .category-wrap .category-content span {
      color: #212529;
      display: block;
      text-align: right; }
    [dir="rtl"] .category-wrap .category-content {
      text-align: right; }
    .category-wrap .category-content.category-text {
      text-align: right; }
      .category-wrap .category-content.category-text h3 {
        font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
        margin-top: -1px; }
      .category-wrap .category-content.category-text span {
        font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
        display: block;
        margin-bottom: -3px; }
      .category-wrap .category-content.category-text-1 h3 {
        margin-top: -4px; }
      .category-wrap .category-content.category-text-1 span {
        display: block;
        margin-bottom: -6px; }
      .category-wrap .category-content.category-text.category-spacing h3 {
        margin-top: -3px; }
      .category-wrap .category-content.category-text.category-spacing span {
        margin-bottom: -3px; }
      .category-wrap .category-content.category-text.t-text h3 {
        margin-top: -5px;
        color: #212529; }
      .category-wrap .category-content.category-text.t-text span {
        display: block;
        margin-bottom: -5px;
        color: #212529; }
    .category-wrap .category-content h3 {
      margin-bottom: 2px;
      font-weight: 600;
      font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320))); }

/**=====================
    offer section css start
==========================**/
.discount-image-details {
  background-color: #eff3f7;
  padding: calc(22px + (50 - 22) * ((100vw - 320px) / (1920 - 320))) calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: flex;
  align-items: center;
  position: relative; }
  .discount-image-details.discount-spacing {
    padding: calc(50px + (180 - 50) * ((100vw - 320px) / (1920 - 320))); }
  @media (max-width: 991px) {
    .discount-image-details {
      display: block; } }
  .discount-image-details .discount-images {
    margin-right: 80px; }
    [dir="rtl"] .discount-image-details .discount-images {
      margin-right: 0;
      margin-left: 80px; }
    @media (max-width: 991px) {
      .discount-image-details .discount-images {
        margin-right: 0;
        position: relative; }
        [dir="rtl"] .discount-image-details .discount-images {
          margin-right: 0;
          margin-left: 0px; } }
    .discount-image-details .discount-images .theme-circle {
      width: 450px;
      height: 450px;
      background: #752add;
      background: radial-gradient(circle, #752add 0%, #572e93 100%);
      border-radius: 50%; }
      @media (max-width: 1366px) {
        .discount-image-details .discount-images .theme-circle {
          width: 420px;
          height: 420px; } }
      @media (max-width: 1199px) {
        .discount-image-details .discount-images .theme-circle {
          width: 340px;
          height: 340px; } }
      @media (max-width: 991px) {
        .discount-image-details .discount-images .theme-circle {
          display: none; } }
    .discount-image-details .discount-images .shoes-images-1 {
      position: absolute;
      top: 18%;
      left: 59px;
      transform: rotate(34deg);
      width: 40%; }
      [dir="rtl"] .discount-image-details .discount-images .shoes-images-1 {
        left: unset;
        right: 130px; }
      @media (max-width: 1366px) {
        .discount-image-details .discount-images .shoes-images-1 {
          top: 24%;
          left: 50px;
          width: 43%; } }
      @media (max-width: 1199px) {
        .discount-image-details .discount-images .shoes-images-1 {
          top: 33%;
          left: 55px;
          width: 40%; } }
      @media (max-width: 991px) {
        .discount-image-details .discount-images .shoes-images-1 {
          position: relative;
          transform: rotate(0);
          display: block;
          width: 100%;
          left: 0;
          animation: run-shoes 15s infinite linear; }
          [dir="rtl"] .discount-image-details .discount-images .shoes-images-1 {
            left: unset;
            right: 0 !important; } }
      @media (max-width: 360px) {
        .discount-image-details .discount-images .shoes-images-1 {
          width: 100%; } }
    .discount-image-details .discount-images .shoes-images-2 {
      position: absolute;
      top: 50px;
      left: 430px;
      width: 20%;
      transform: rotateY(180deg) rotate(45deg); }
      [dir="rtl"] .discount-image-details .discount-images .shoes-images-2 {
        left: unset;
        right: 30px; }
      @media (max-width: 1366px) {
        .discount-image-details .discount-images .shoes-images-2 {
          top: 90px;
          left: 375px;
          width: 18%; } }
      @media (max-width: 1199px) {
        .discount-image-details .discount-images .shoes-images-2 {
          top: 95px;
          left: 325px;
          width: 16%; } }
      @media (max-width: 991px) {
        .discount-image-details .discount-images .shoes-images-2 {
          display: none; } }
  .discount-image-details .discunt-details {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (max-width: 991px) {
      .discount-image-details .discunt-details {
        position: relative;
        width: 100%;
        display: block; } }
    .discount-image-details .discunt-details .heart-button {
      background-color: #e3ddf4;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center; }
      .discount-image-details .discunt-details .heart-button-2 {
        background-color: #efdee7 !important; }
      .discount-image-details .discunt-details .heart-button-light {
        background-color: #eee1e6; }
      .discount-image-details .discunt-details .heart-button .fa-heart {
        margin-top: 2px; }
    .discount-image-details .discunt-details h5 {
      color: #212529; }
    .discount-image-details .discunt-details .deal-text {
      font-size: calc(22px + (45 - 22) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.1;
      font-weight: 900;
      color: #212529; }

.discount-image-details-2 {
  background-color: #eff3f7;
  position: relative;
  height: 600px;
  overflow: hidden; }
  @media (max-width: 1366px) {
    .discount-image-details-2 {
      height: 500px; } }
  @media (max-width: 1199px) {
    .discount-image-details-2 {
      height: 410px; } }
  @media (max-width: 991px) {
    .discount-image-details-2 {
      height: auto;
      padding: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320))); } }
  .discount-image-details-2 .discount-images {
    margin-right: 80px; }
    @media (max-width: 991px) {
      .discount-image-details-2 .discount-images {
        margin-right: 0;
        position: relative; } }
    .discount-image-details-2 .discount-images .flower-images-1 {
      position: absolute;
      top: -75px;
      left: -215px;
      transform: rotate(-70deg);
      width: 53%; }
      @media (max-width: 1366px) {
        .discount-image-details-2 .discount-images .flower-images-1 {
          top: -2px;
          left: -150px;
          width: 49%; } }
      @media (max-width: 1199px) {
        .discount-image-details-2 .discount-images .flower-images-1 {
          top: -2px;
          left: -150px;
          width: 49%; } }
      @media (max-width: 991px) {
        .discount-image-details-2 .discount-images .flower-images-1 {
          display: none; } }
    .discount-image-details-2 .discount-images .flower-images-2 {
      position: absolute;
      top: -250px;
      right: -330px;
      width: 67%;
      transform: rotate(375deg); }
      @media (max-width: 1366px) {
        .discount-image-details-2 .discount-images .flower-images-2 {
          top: -160px;
          right: -235px;
          width: 62%; } }
      @media (max-width: 1199px) {
        .discount-image-details-2 .discount-images .flower-images-2 {
          top: -160px;
          right: -235px;
          width: 62%; } }
      @media (max-width: 991px) {
        .discount-image-details-2 .discount-images .flower-images-2 {
          position: relative;
          transform: rotate(0);
          display: block;
          width: 100%;
          top: 0;
          left: 0;
          animation: run-shoes 15s infinite linear; }
          [dir="rtl"] .discount-image-details-2 .discount-images .flower-images-2 {
            left: unset;
            right: 0; } }
  .discount-image-details-2 .discount-shop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: var(--theme-color);
    user-select: none;
    color: #fff;
    padding: calc(13px + (25 - 13) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 991px) {
      .discount-image-details-2 .discount-shop {
        display: none; } }
    .discount-image-details-2 .discount-shop h2 {
      font-size: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .discount-image-details-2 .discount-shop h6 {
      letter-spacing: calc(0px + (5 - 0) * ((100vw - 320px) / (1920 - 320)));
      margin-top: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))); }
  .discount-image-details-2 .discunt-details {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (max-width: 991px) {
      .discount-image-details-2 .discunt-details {
        position: relative;
        width: 100%;
        display: block; } }
    .discount-image-details-2 .discunt-details .heart-button {
      padding: 15px;
      border-radius: 50%;
      display: inline-block; }
      .discount-image-details-2 .discunt-details .heart-button-light {
        background-color: #eee1e6; }
      .discount-image-details-2 .discunt-details .heart-button .feather {
        fill: var(--theme-color);
        stroke: var(--theme-color);
        width: 20px;
        height: 20px; }
    .discount-image-details-2 .discunt-details .deal-text {
      font-size: calc(22px + (45 - 22) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.1;
      font-weight: 900; }

/**=====================
     banner css start
==========================**/
.timer-banner {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative; }

.collection-banner {
  position: relative;
  overflow: hidden;
  height: 100%; }
  @media (max-width: 991px) {
    .collection-banner .collection-contain {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 15px 30px;
      border-radius: 4px;
      backdrop-filter: blur(3px); }
      .collection-banner .collection-contain h6,
      .collection-banner .collection-contain h2,
      .collection-banner .collection-contain p {
        color: #fff !important; } }
  .collection-banner .theme-color {
    margin-top: -2px; }
  .collection-banner .contain-banner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
    .collection-banner .contain-banner.contain-center {
      justify-content: center; }
    .collection-banner .contain-banner .banner-content {
      padding: 45px 80px; }
      @media (max-width: 1460px) {
        .collection-banner .contain-banner .banner-content {
          padding: 30px 60px; } }
      @media (max-width: 1366px) {
        .collection-banner .contain-banner .banner-content {
          padding: 30px 40px; } }
      @media (max-width: 1199px) {
        .collection-banner .contain-banner .banner-content {
          padding: 20px 15px; } }
      @media (max-width: 991px) {
        .collection-banner .contain-banner .banner-content {
          padding: 40px; } }
      @media (max-width: 575px) {
        .collection-banner .contain-banner .banner-content {
          padding: 30px; } }
      @media (max-width: 360px) {
        .collection-banner .contain-banner .banner-content {
          padding: 20px; } }
      .collection-banner .contain-banner .banner-content.spacing-banner {
        padding: 40px; }
        @media (max-width: 1460px) {
          .collection-banner .contain-banner .banner-content.spacing-banner {
            padding: 30px; } }
        @media (max-width: 1366px) {
          .collection-banner .contain-banner .banner-content.spacing-banner {
            padding: 30px; } }
        @media (max-width: 1199px) {
          .collection-banner .contain-banner .banner-content.spacing-banner {
            padding: 20px; } }
        @media (max-width: 991px) {
          .collection-banner .contain-banner .banner-content.spacing-banner {
            padding: 40px; } }
        @media (max-width: 575px) {
          .collection-banner .contain-banner .banner-content.spacing-banner {
            padding: 30px; } }
        @media (max-width: 360px) {
          .collection-banner .contain-banner .banner-content.spacing-banner {
            padding: 20px; } }
      .collection-banner .contain-banner .banner-content.with-bg {
        background-color: rgba(35, 35, 35, 0.93);
        color: #fff; }
        .collection-banner .contain-banner .banner-content.with-bg h2 {
          margin-top: -2px; }
        .collection-banner .contain-banner .banner-content.with-bg h3 {
          margin-top: -3px; }
        .collection-banner .contain-banner .banner-content.with-bg span {
          display: block;
          margin-bottom: -5px; }
      .collection-banner .contain-banner .banner-content.with-big {
        background-color: rgba(35, 35, 35, 0.85);
        color: #fff; }
        .collection-banner .contain-banner .banner-content.with-big h2 {
          margin-top: -3px; }
      .collection-banner .contain-banner .banner-content span {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 5px; }
        @media (max-width: 1199px) {
          .collection-banner .contain-banner .banner-content span {
            letter-spacing: 3px; } }
      .collection-banner .contain-banner .banner-content.t-top h3 {
        margin-top: -4px; }
  .collection-banner .banner-img {
    height: 100%; }
  .collection-banner.p-center .contain-banner,
  .collection-banner.p-center .banner-text {
    justify-content: center; }
  .collection-banner.p-right .contain-banner,
  .collection-banner.p-right .banner-text {
    justify-content: flex-end; }
    [dir="rtl"] .collection-banner.p-right .contain-banner, [dir="rtl"]
    .collection-banner.p-right .banner-text {
      justify-content: flex-start; }
    .collection-banner.p-right .contain-banner h3,
    .collection-banner.p-right .banner-text h3 {
      margin-top: -9px; }
    .collection-banner.p-right .contain-banner .span-top,
    .collection-banner.p-right .banner-text .span-top {
      margin-top: -4px;
      display: block; }
  .collection-banner.p-right .banner-content {
    text-align: right; }
  .collection-banner.p-left .contain-banner,
  .collection-banner.p-left .banner-text {
    justify-content: flex-start; }
    [dir="rtl"] .collection-banner.p-left .contain-banner, [dir="rtl"]
    .collection-banner.p-left .banner-text {
      justify-content: flex-end; }
  .collection-banner.p-bottom .contain-banner {
    align-items: flex-end; }
  .collection-banner .banner-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
    .collection-banner .banner-text .banner-content {
      padding: 50px;
      width: 60%; }
      [dir="rtl"] .collection-banner .banner-text .banner-content {
        text-align: right; }
      .collection-banner .banner-text .banner-content.t-top h3 {
        color: #212529;
        margin-top: -4px; }
        @media (max-width: 480px) {
          .collection-banner .banner-text .banner-content.t-top h3 {
            margin-bottom: 5px; } }
      @media (max-width: 1460px) {
        .collection-banner .banner-text .banner-content {
          padding: 30px 60px; } }
      @media (max-width: 1366px) {
        .collection-banner .banner-text .banner-content {
          padding: 30px 40px; } }
      @media (max-width: 1199px) {
        .collection-banner .banner-text .banner-content {
          padding: 20px 15px; } }
      @media (max-width: 991px) {
        .collection-banner .banner-text .banner-content {
          padding: 0 40px; } }
      @media (max-width: 575px) {
        .collection-banner .banner-text .banner-content {
          padding: 0 30px; } }
      @media (max-width: 360px) {
        .collection-banner .banner-text .banner-content {
          padding: 0 10px;
          width: 50%; } }
    .collection-banner .banner-text h6 {
      font-weight: 600;
      margin-top: -3px; }
    .collection-banner .banner-text h2 {
      margin: calc(2px + (20 - 2) * ((100vw - 320px) / (1920 - 320))) 0;
      font-size: calc(18px + (46 - 18) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.32;
      color: #212529; }
    .collection-banner .banner-text h3 {
      font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.45;
      margin-bottom: 20px; }
      .collection-banner .banner-text h3.h-bottom {
        margin-bottom: calc(2px + (20 - 2) * ((100vw - 320px) / (1920 - 320)));
        margin-top: -6px;
        color: #212529; }
    .collection-banner .banner-text .btn {
      font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320))); }

.banner-title .banner-text .banner-content {
  width: 56%; }
  [dir="rtl"] .banner-title .banner-text .banner-content {
    text-align: left; }
  .banner-title .banner-text .banner-content .spacing-text {
    margin-top: -6px;
    color: #212529; }

.collection-center {
  position: relative;
  padding: 50px;
  background-color: #f0f3f8;
  color: #212529; }
  @media (max-width: 1460px) {
    .collection-center {
      padding: 40px; } }
  @media (max-width: 575px) {
    .collection-center {
      padding: 30px; } }
  @media (max-width: 360px) {
    .collection-center {
      padding: 20px; } }
  .collection-center h2 {
    line-height: 1.3; }

.banner-image {
  position: relative; }
  .banner-image .banner-content {
    position: absolute;
    left: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    transform: translateY(-50%);
    width: 40%; }
    .banner-image .banner-content h3 {
      font-size: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.45;
      margin-bottom: calc(2px + (15 - 2) * ((100vw - 320px) / (1920 - 320)));
      margin-top: calc(0px + (-6 - 0) * ((100vw - 320px) / (1920 - 320)));
      color: var(--theme-color); }
      @media (max-width: 767px) {
        .banner-image .banner-content h3 {
          margin-bottom: 0; } }
    .banner-image .banner-content p {
      color: #7e7e7e;
      margin-bottom: 0;
      line-height: 1.6; }
      @media (max-width: 767px) {
        .banner-image .banner-content p {
          display: none; } }

.banner-style-2 .collection-banner {
  position: relative;
  cursor: pointer; }
  .banner-style-2 .collection-banner .banner-img {
    min-height: 390px;
    object-fit: cover; }
    @media (max-width: 1460px) {
      .banner-style-2 .collection-banner .banner-img {
        min-height: 330px; } }
    @media (max-width: 1199px) {
      .banner-style-2 .collection-banner .banner-img {
        min-height: 300px; } }
    @media (max-width: 360px) {
      .banner-style-2 .collection-banner .banner-img {
        min-height: 240px; } }
  .banner-style-2 .collection-banner .banner-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 30px; }
    @media (max-width: 575px) {
      .banner-style-2 .collection-banner .banner-detail {
        padding: 20px; } }
    .banner-style-2 .collection-banner .banner-detail .font-dark-30 {
      font-weight: 800;
      transition: all 0.5s ease;
      color: #212529 !important; }
    .banner-style-2 .collection-banner .banner-detail .heart-wishlist .far {
      color: rgba(35, 35, 35, 0.6); }
    .banner-style-2 .collection-banner .banner-detail .heart-wishlist::after {
      opacity: 0;
      transition: 0.5; }
  .banner-style-2 .collection-banner .contain-banner {
    top: unset;
    bottom: 60px; }
    .banner-style-2 .collection-banner .contain-banner.contain-center {
      top: 0;
      bottom: 0; }
    @media (max-width: 1199px) {
      .banner-style-2 .collection-banner .contain-banner {
        bottom: 30px; } }
    .banner-style-2 .collection-banner .contain-banner .banner-content {
      padding: 40px;
      width: calc(100% - 120px);
      transition: all 0.5s;
      backdrop-filter: blur(4px); }
      .banner-style-2 .collection-banner .contain-banner .banner-content.banner-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        backdrop-filter: blur(5px); }
      @media (max-width: 1460px) {
        .banner-style-2 .collection-banner .contain-banner .banner-content {
          padding: 30px;
          width: unset; } }
      @media (max-width: 575px) {
        .banner-style-2 .collection-banner .contain-banner .banner-content {
          padding: 20px; } }
      @media (max-width: 1460px) {
        .banner-style-2 .collection-banner .contain-banner .banner-content span {
          letter-spacing: 3px; } }
  .banner-style-2 .collection-banner.ratio2_1 .bg-size:before {
    display: none; }
  .banner-style-2 .collection-banner:hover .contain-banner .banner-content.banner-center {
    backdrop-filter: blur(5px); }
  .banner-style-2 .collection-banner:hover .contain-banner .banner-content.with-bg {
    background-color: var(--theme-color);
    opacity: 0.9;
    transition: all 0.5s;
    color: #fff; }
  .banner-style-2 .collection-banner:hover .contain-banner .banner-content.with-big {
    background-color: var(--theme-color);
    backdrop-filter: blur(5px);
    opacity: 0.9; }
  .banner-style-2 .collection-banner:hover .banner-detail .heart-wishlist .far {
    color: var(--theme-color); }
  .banner-style-2 .collection-banner:hover .banner-detail .heart-wishlist::after {
    opacity: 0.1;
    transition: 0.5; }
  .banner-style-2 .collection-banner:hover .banner-detail .font-dark-30 {
    color: #232323 !important;
    transition: all 0.5s ease; }
    .banner-style-2 .collection-banner:hover .banner-detail .font-dark-30 span {
      color: var(--theme-color) !important; }

.banner-style-2.offer-banner .collection-banner .banner-img {
  min-height: unset; }

.banner-style-2.offer-banner .collection-banner .contain-banner {
  top: 50%;
  transform: translateY(-50%);
  bottom: unset; }
  .banner-style-2.offer-banner .collection-banner .contain-banner .banner-content {
    padding: 40px; }
    .banner-style-2.offer-banner .collection-banner .contain-banner .banner-content span {
      letter-spacing: unset; }

/**=====================
    Cookie section css start
==========================**/
.cookie-bar-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e9e9e9;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  width: max-content;
  border-radius: 50px;
  z-index: 99;
  box-shadow: 0 4px 7px rgba(33, 37, 41, 0.15);
  opacity: 1;
  transition: .5s ease; }
  @media (max-width: 767px) {
    .cookie-bar-section {
      bottom: 8px;
      left: unset;
      right: 20px;
      display: block;
      width: 50%;
      margin: 0 auto;
      transform: none;
      border-radius: 5px;
      z-index: 9; } }
  @media (max-width: 480px) {
    .cookie-bar-section {
      width: 310px; } }
  @media (max-width: 360px) {
    .cookie-bar-section {
      width: fit-content;
      left: 0;
      transform: translate(0, 0);
      margin: 0 12px; } }
  .cookie-bar-section.flower-cookirbar {
    background-color: #eff2f7; }
  .cookie-bar-section.veg-cookiebar {
    background-color: #f0f7f0; }
  .cookie-bar-section.hide {
    opacity: 0;
    bottom: -350px; }
  .cookie-bar-section img {
    width: 30px; }
    @media (max-width: 767px) {
      .cookie-bar-section img {
        margin-bottom: 16px; } }
    @media (max-width: 360px) {
      .cookie-bar-section img {
        width: 40px; } }
  .cookie-bar-section .content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .cookie-bar-section .content {
        display: block;
        text-align: center; } }
    .cookie-bar-section .content p {
      text-align: center;
      margin: 0 18px;
      line-height: 1.6;
      letter-spacing: 0.03em; }
      @media (max-width: 767px) {
        .cookie-bar-section .content p {
          margin: 0 0 16px; } }
    .cookie-bar-section .content .cookie-buttons button {
      padding: 10px 25px;
      border-radius: 50px;
      text-transform: capitalize; }
      .cookie-bar-section .content .cookie-buttons button:hover {
        color: #fff; }
      @media (max-width: 767px) {
        .cookie-bar-section .content .cookie-buttons button {
          border-radius: 5px; } }
      @media (max-width: 360px) {
        .cookie-bar-section .content .cookie-buttons button {
          width: 100%; } }

/**=====================
     footer css start
==========================**/
@media (max-width: 575px) {
  footer h3 {
    font-size: 16px; } }

footer .main-footer {
  border-top: 2px solid #f0f3f8;
  border-bottom: 2px solid #f0f3f8;
  padding: 80px 0; }
  @media (max-width: 1460px) {
    footer .main-footer {
      padding: 70px 0; } }
  @media (max-width: 1199px) {
    footer .main-footer {
      padding: 60px 0; } }
  @media (max-width: 991px) {
    footer .main-footer {
      padding: 50px 0; } }
  @media (max-width: 767px) {
    footer .main-footer {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    footer .main-footer {
      padding: 30px 0; } }
  @media (max-width: 991px) {
    footer .main-footer .row > div:first-child, footer .main-footer .row > div:last-child {
      order: -1; } }
  @media (max-width: 767px) {
    footer .main-footer .row > div:first-child, footer .main-footer .row > div:last-child {
      order: unset; } }

footer .footer-contact {
  padding-right: 40px; }
  [dir="rtl"] footer .footer-contact {
    padding-right: unset !important;
    padding-left: 40px; }
  footer .footer-contact .brand-logo .svg-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    animation: none;
    transform-style: preserve-3d; }
    [dir="rtl"] footer .footer-contact .brand-logo .svg-icon {
      margin-right: 0;
      margin-left: 5px; }
    footer .footer-contact .brand-logo .svg-icon .fill-color {
      fill: var(--theme-color); }
  @media (max-width: 1366px) {
    footer .footer-contact {
      padding-right: 20px; }
      [dir="rtl"] footer .footer-contact {
        padding-left: 20px; } }
  @media (max-width: 991px) {
    footer .footer-contact {
      padding-right: 0; }
      [dir="rtl"] footer .footer-contact {
        padding-left: 0; } }
  footer .footer-contact .footer-logo {
    display: block;
    margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
  [dir="rtl"] footer .footer-contact .contact-lists {
    padding: 0; }
  footer .footer-contact .contact-lists li {
    display: flex;
    text-transform: capitalize; }
    footer .footer-contact .contact-lists li:nth-child(1) span {
      margin-top: -3px; }
    footer .footer-contact .contact-lists li:nth-child(3) span {
      margin-bottom: -3px; }
    footer .footer-contact .contact-lists li span {
      display: inline; }
      footer .footer-contact .contact-lists li span b {
        font-weight: 500;
        padding-right: 5px; }
        [dir="rtl"] footer .footer-contact .contact-lists li span b {
          padding-right: unset;
          padding-left: 5px;
          float: right; }
    footer .footer-contact .contact-lists li + li {
      margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
      @media (max-width: 991px) {
        footer .footer-contact .contact-lists li + li {
          margin-top: 15px; } }
      @media (max-width: 575px) {
        footer .footer-contact .contact-lists li + li {
          margin-top: 10px; } }

footer .footer-links .footer-title h3 {
  position: relative; }
  @media (max-width: 575px) {
    footer .footer-links .footer-title h3::after {
      position: absolute;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      font-size: 9px;
      font-weight: 600;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #7e7e7e;
      transition: 0.5s; }
      [dir="rtl"] footer .footer-links .footer-title h3::after {
        right: unset;
        left: 0; } }

@media (max-width: 575px) {
  footer .footer-links .footer-title.active h3::after {
    content: "\f077";
    transition: 0.5s; } }

footer .footer-links .footer-content {
  padding-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }

[dir="rtl"] footer .footer-links ul {
  padding: 0; }

footer .footer-links ul li {
  display: block; }
  footer .footer-links ul li .font-dark:hover {
    color: var(--theme-color) !important; }
  footer .footer-links ul li + li {
    margin-top: 15px; }
    @media (max-width: 991px) {
      footer .footer-links ul li + li {
        margin-top: 10px; } }
    @media (max-width: 575px) {
      footer .footer-links ul li + li {
        margin-top: 6px; } }

footer .footer-newsletter h3 {
  margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }

footer .footer-newsletter p {
  line-height: 1.6;
  letter-spacing: .03em; }

footer .footer-newsletter .input-group input {
  border-radius: 0;
  position: relative;
  border: none;
  background-color: #f0f3f8;
  color: rgba(35, 35, 35, 0.65); }
  footer .footer-newsletter .input-group input.color-4 {
    background-color: #e9e9e9; }

footer .footer-newsletter .input-group .input-group-text {
  padding: 6px 16px;
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  color: #fff;
  border-radius: 0;
  cursor: pointer; }

footer .footer-number {
  display: flex;
  align-items: center; }
  footer .footer-number .footer-number-image {
    background-color: #eff3f7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px; }
    [dir="rtl"] footer .footer-number .footer-number-image {
      margin-right: 0;
      margin-left: 20px; }
    footer .footer-number .footer-number-image img {
      width: 20px; }
  footer .footer-number .footer-number-container h3 {
    margin-bottom: 0; }

footer .footer-details {
  margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))); }
  footer .footer-details p {
    line-height: 1.5;
    margin-bottom: 0; }

footer .sub-footer {
  padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) 0; }
  @media (max-width: 767px) {
    footer .sub-footer {
      text-align: center; } }
  footer .sub-footer .row {
    align-items: center; }
  [dir="rtl"] footer .sub-footer ul {
    padding: 0; }
  footer .sub-footer ul li + li {
    margin-left: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    [dir="rtl"] footer .sub-footer ul li + li {
      margin-left: unset !important;
      margin-right: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    @media (max-width: 991px) {
      footer .sub-footer ul li + li {
        margin-left: 10px; }
        [dir="rtl"] footer .sub-footer ul li + li {
          margin-right: 10px; } }
  footer .sub-footer p {
    text-align: right; }
    [dir="rtl"] footer .sub-footer p {
      text-align: left; }
    @media (max-width: 767px) {
      footer .sub-footer p {
        text-align: center; }
        [dir="rtl"] footer .sub-footer p {
          text-align: center; } }

@media (max-width: 575px) {
  .footer-sm-space {
    margin-bottom: 80px; } }

/**=====================
     brand section css start
==========================**/
.brand-slider {
  margin-bottom: -7px; }

.zi-1 {
  z-index: 1; }

.blog-profile {
  display: flex;
  align-items: center; }
  .blog-profile .image-profile {
    position: relative;
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%; }
    .blog-profile .image-profile img {
      transition: 0.5s ease;
      width: 100%;
      height: auto;
      object-fit: cover; }
  .blog-profile .image-name {
    margin-left: 15px; }
    [dir="rtl"] .blog-profile .image-name {
      margin-left: unset;
      margin-right: 15px; }
    .blog-profile .image-name .text-weight h6 {
      font-weight: 300; }
    .blog-profile .image-name h3 {
      position: relative;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      margin-bottom: 4px; }
    .blog-profile .image-name h6 {
      color: #6c757d;
      font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320))); }

.search-section .search-bar .input-group {
  width: 50%;
  margin: 26px auto 110px; }

.search-section .search-bar button {
  padding: 15px 35px;
  background-color: var(--theme-color);
  color: #fff;
  border: 1px solid var(--theme-colors); }
  .search-section .search-bar button i {
    color: #fff; }
  .search-section .search-bar button.search-button {
    padding: 15px; }

.search-section .search-bar input:focus {
  border-color: #ced4da; }

/**=====================
       Social media css start
  ==========================**/
.social-media {
  display: flex; }
  .social-media.media-center {
    justify-content: center; }
  .social-media a + a {
    margin-left: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))); }
    [dir="rtl"] .social-media a + a {
      margin-left: unset;
      margin-right: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))); }
  .social-media .social-icon-box {
    width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: #212529;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; }
    .social-media .social-icon-box + .social-meida {
      margin-left: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320))); }
    .social-media .social-icon-box.social-color {
      background-color: #eff2f7; }
      @media (max-width: 360px) {
        .social-media .social-icon-box.social-color {
          background-color: var(--theme-color); } }
      .social-media .social-icon-box.social-color i {
        color: #212529; }
        @media (max-width: 575px) {
          .social-media .social-icon-box.social-color i {
            font-size: 12px; } }
        @media (max-width: 360px) {
          .social-media .social-icon-box.social-color i {
            color: #fff; } }
    .social-media .social-icon-box i {
      color: #fff; }

/**=====================
     Load more css start
  ==========================**/
.load-more {
  text-align: center;
  margin: 40px auto 0;
  width: 280px; }
  .load-more a {
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 100%;
    color: var(--theme-color); }

.product-load-more .col-grid-box {
  display: none; }

/**=====================
       Log In page Css Start
  ==========================**/
.login-section {
  min-height: 100vh;
  position: relative;
  background: #f0f3f8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + (50 - 10) * ((100vw - 320px) / (1920 - 320))); }
  .login-section .materialContainer {
    width: 100%;
    max-width: 460px;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0); }
    .login-section .materialContainer .box {
      position: relative;
      top: 0;
      opacity: 1;
      float: left;
      padding: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320))) calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
      width: 100%;
      background: #fff;
      border-radius: 10px;
      transform: scale(1);
      z-index: 5; }
      .login-section .materialContainer .box.back {
        transform: scale(0.95);
        top: -20px;
        opacity: 0.8;
        z-index: -1; }
      .login-section .materialContainer .box:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 30px;
        border-radius: 10px;
        top: -10px;
        background-color: rgba(255, 255, 255, 0.6);
        left: 0;
        transform: scale(0.95); }
    .login-section .materialContainer .login-title {
      width: 100%;
      line-height: 46px;
      font-size: 34px;
      font-weight: 700;
      letter-spacing: calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)));
      color: var(--theme-color);
      position: relative; }
      .login-section .materialContainer .login-title:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0;
        left: calc(-10px + (-10 - 10) * ((100vw - 320px) / (1920 - 320)));
        background: var(--theme-color); }
        [dir="rtl"] .login-section .materialContainer .login-title:before {
          left: unset;
          right: calc(-10px + (-10 - 10) * ((100vw - 320px) / (1920 - 320))); }
      .login-section .materialContainer .login-title h2 {
        font-size: calc(27px + (28 - 27) * ((100vw - 320px) / (1920 - 320)));
        margin-left: calc(10px + (0 - 10) * ((100vw - 320px) / (1920 - 320)));
        margin-top: -2px; }
    .login-section .materialContainer .social-media {
      padding: 15px calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(199, 199, 197, 0.6);
      display: flex;
      align-items: center;
      justify-content: center; }
      .login-section .materialContainer .social-media h6 {
        margin-left: 20px;
        margin-bottom: -2px;
        color: #fff; }
        [dir="rtl"] .login-section .materialContainer .social-media h6 {
          margin-left: 0;
          margin-right: 20px; }
      .login-section .materialContainer .social-media img {
        width: calc(8% + (18 - 8) * ((100vw - 320px) / (1920 - 320))); }
      .login-section .materialContainer .social-media.fb-media {
        background-color: #43609c; }
        .login-section .materialContainer .social-media.fb-media h6 {
          color: #fff;
          font-size: 18px; }
      .login-section .materialContainer .social-media.google-media {
        color: #000; }
        .login-section .materialContainer .social-media.google-media h6 {
          font-size: 18px;
          color: #000; }
    .login-section .materialContainer p {
      width: 100%;
      float: left;
      text-align: center;
      color: rgba(33, 37, 41, 0.4);
      font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
      margin-top: calc(17px + (23 - 17) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 0; }
      .login-section .materialContainer p.pass-or {
        margin-top: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320))); }
    .login-section .materialContainer .input {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
      .login-section .materialContainer .input label,
      .login-section .materialContainer .input input,
      .login-section .materialContainer .input .spin {
        transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
    .login-section .materialContainer .button {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
      .login-section .materialContainer .button button .button.login button i.fa {
        transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
    .login-section .materialContainer .material-button .shape:before, .login-section .materialContainer .material-button .shape:after {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
    .login-section .materialContainer .button.login button {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
    .login-section .materialContainer .material-button,
    .login-section .materialContainer .alt-2,
    .login-section .materialContainer .material-button .shape,
    .login-section .materialContainer .alt-2 .shape,
    .login-section .materialContainer .box {
      transition: 400ms cubic-bezier(0.4, 0, 0.2, 1); }
    .login-section .materialContainer .input {
      width: 100%;
      float: left; }
      .login-section .materialContainer .input label,
      .login-section .materialContainer .input input,
      .login-section .materialContainer .input .spin {
        width: 100%;
        float: left; }
    .login-section .materialContainer .button {
      width: 100%;
      float: left; }
      .login-section .materialContainer .button button {
        width: 100%;
        float: left; }
    .login-section .materialContainer .input {
      margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      height: 70px; }
    .login-section .materialContainer .input {
      position: relative; }
      .login-section .materialContainer .input input {
        position: relative; }
    .login-section .materialContainer .button {
      position: relative; }
      .login-section .materialContainer .button button {
        position: relative; }
    .login-section .materialContainer .input input {
      height: 60px;
      top: 10px;
      border: none;
      background: transparent;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      color: #212529;
      font-weight: 300; }
    .login-section .materialContainer .input label {
      font-family: "Roboto", sans-serif;
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(33, 37, 41, 0.8);
      font-weight: 300; }
      .login-section .materialContainer .input label.email-label {
        font-size: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .login-section .materialContainer .button button {
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      color: rgba(33, 37, 41, 0.8);
      font-weight: 300; }
    .login-section .materialContainer .input:before,
    .login-section .materialContainer .input .spin {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0; }
      [dir="rtl"] .login-section .materialContainer .input:before, [dir="rtl"]
      .login-section .materialContainer .input .spin {
        left: unset;
        right: 0; }
    .login-section .materialContainer .input:before {
      content: "";
      background: rgba(33, 37, 41, 0.15);
      z-index: 3; }
    .login-section .materialContainer .input .spin {
      background: var(--theme-color);
      z-index: 4;
      width: 0; }
    .login-section .materialContainer .overbox .input .spin {
      background: #fff; }
    .login-section .materialContainer .overbox .input:before {
      background: rgba(255, 255, 255, 0.5); }
    .login-section .materialContainer .input label {
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 2;
      cursor: pointer;
      line-height: 60px;
      color: #777777; }
    .login-section .materialContainer .button {
      margin-top: 20px;
      margin: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) 0;
      overflow: hidden;
      z-index: 2; }
      .login-section .materialContainer .button.button-1 {
        margin-bottom: 0; }
      .login-section .materialContainer .button.login button {
        width: 100%;
        left: 0%;
        background-color: var(--theme-color);
        border: 1px solid var(--theme-color);
        font-weight: 900;
        font-size: 18px;
        color: #fff;
        padding: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 4px; }
        .login-section .materialContainer .button.login button span {
          display: block;
          margin-top: -3px; }
      .login-section .materialContainer .button button {
        width: 100%;
        left: 0%;
        background-color: transparent;
        border: 1px solid rgba(33, 37, 41, 0.1);
        font-weight: 900;
        font-size: 18px;
        color: rgba(33, 37, 41, 0.2);
        padding: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320))); }
      .login-section .materialContainer .button button {
        background-color: #fff;
        color: var(--theme-color);
        border: none; }
      .login-section .materialContainer .button.login button.active {
        border: 1px solid transparent;
        color: #fff !important;
        background-color: var(--theme-color); }
        .login-section .materialContainer .button.login button.active span {
          opacity: 0;
          transform: scale(0);
          display: none; }
        .login-section .materialContainer .button.login button.active i.fa {
          opacity: 1;
          transform: scale(1) rotate(0deg);
          display: block; }
      .login-section .materialContainer .button.login button i.fa {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        font-size: 18px;
        transform: scale(0) rotate(-45deg);
        display: none; }
      .login-section .materialContainer .button button {
        cursor: pointer;
        position: relative;
        z-index: 2; }
    .login-section .materialContainer .pass-forgot {
      width: 100%;
      float: right;
      text-align: right;
      color: rgba(33, 37, 41, 0.4);
      font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
      margin: 10px auto 0; }
      .login-section .materialContainer .pass-forgot:hover {
        color: var(--theme-color); }
    .login-section .materialContainer .sign-category {
      width: 100%;
      text-align: center;
      color: rgba(33, 37, 41, 0.4);
      font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
      margin: 10px auto;
      position: relative; }
      .login-section .materialContainer .sign-category span {
        background-color: #fff;
        z-index: 1;
        padding: 0 12px; }
      .login-section .materialContainer .sign-category::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 1px;
        color: rgba(33, 37, 41, 0.2);
        z-index: -1; }
    .login-section .materialContainer .overbox {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: inherit;
      border-radius: 10px;
      padding: 60px 50px 40px 50px; }
      .login-section .materialContainer .overbox .login-title,
      .login-section .materialContainer .overbox .button,
      .login-section .materialContainer .overbox .input {
        z-index: 111;
        position: relative;
        color: #fff !important;
        display: none; }
      .login-section .materialContainer .overbox .login-title {
        width: 80%; }
      .login-section .materialContainer .overbox .input {
        margin-top: 20px; }
        .login-section .materialContainer .overbox .input input,
        .login-section .materialContainer .overbox .input label {
          color: #fff; }
      .login-section .materialContainer .overbox .material-button,
      .login-section .materialContainer .overbox .alt-2 {
        display: block; }
        .login-section .materialContainer .overbox .material-button .shape,
        .login-section .materialContainer .overbox .alt-2 .shape {
          display: block; }
    .login-section .materialContainer .material-button,
    .login-section .materialContainer .alt-2 {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: var(--theme-color);
      position: absolute;
      top: 40px;
      right: -70px;
      cursor: pointer;
      z-index: 100;
      transform: translate(0%, 0%); }
    .login-section .materialContainer .material-button .shape,
    .login-section .materialContainer .alt-2 .shape {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%; }
    .login-section .materialContainer .material-button .shape:before,
    .login-section .materialContainer .alt-2 .shape:before,
    .login-section .materialContainer .material-button .shape:after,
    .login-section .materialContainer .alt-2 .shape:after {
      content: "";
      position: absolute;
      background: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .login-section .materialContainer .material-button .shape:before,
    .login-section .materialContainer .alt-2 .shape:before {
      width: 25px;
      height: 4px; }
    .login-section .materialContainer .material-button .shape:after,
    .login-section .materialContainer .alt-2 .shape:after {
      height: 25px;
      width: 4px; }
    .login-section .materialContainer .material-button.active,
    .login-section .materialContainer .alt-2.active {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(0deg); }

/**=====================
       404 Page Css Start
  ==========================**/
.page-not-found .page-image {
  text-align: center; }
  .page-not-found .page-image img {
    width: 60%; }

.page-not-found .page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 calc(0px + (50 - 0) * ((100vw - 320px) / (1920 - 320)));
  margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))); }
  .page-not-found .page-container h2 {
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: #212529;
    font-size: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-top: -3px; }
  .page-not-found .page-container p {
    font-size: 16px;
    line-height: 1.6;
    color: #7e7e7e; }

/**=====================
       Contact Page Css Start
  ==========================**/
.contact-section .materialContainer {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  transform: translate(0); }
  .contact-section .materialContainer .material-details h2 {
    margin-top: -5px; }
  .contact-section .materialContainer .material-details h4 {
    margin-top: 10px;
    font-weight: 400;
    color: #78909c;
    line-height: 1.5;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))); }
  .contact-section .materialContainer .box {
    position: relative;
    top: 0;
    opacity: 1;
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    transform: scale(1);
    z-index: 5; }
  .contact-section .materialContainer .input {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
    .contact-section .materialContainer .input label,
    .contact-section .materialContainer .input input,
    .contact-section .materialContainer .input textarea,
    .contact-section .materialContainer .input .spin {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1); }
  .contact-section .materialContainer .input {
    margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: 70px; }
  .contact-section .materialContainer .input {
    position: relative; }
    .contact-section .materialContainer .input input {
      position: relative; }
    .contact-section .materialContainer .input textarea {
      position: relative;
      width: 100%; }
  .contact-section .materialContainer .input input,
  .contact-section .materialContainer .input textarea {
    height: 60px;
    top: 10px;
    border: none;
    background: transparent;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: rgba(33, 37, 41, 0.8);
    font-weight: 300; }
  .contact-section .materialContainer .input label {
    font-family: "Roboto", sans-serif;
    font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(33, 37, 41, 0.8);
    font-weight: 300; }
    .contact-section .materialContainer .input label.email-label {
      font-size: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .contact-section .materialContainer .input:before,
  .contact-section .materialContainer .input .spin {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0; }
  .contact-section .materialContainer .input:before {
    content: "";
    background: rgba(33, 37, 41, 0.1);
    z-index: 3; }
  .contact-section .materialContainer .input .spin {
    background: var(--theme-color);
    z-index: 4;
    width: 0; }
  .contact-section .materialContainer .input label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    cursor: pointer;
    line-height: 60px;
    color: #78909c; }

.contact-section .location-map iframe {
  width: 100%;
  height: calc(200px + (340 - 200) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: -6px; }

.contact-section .contact-details {
  background-color: #eff2f7;
  height: 100%;
  padding: calc(21px + (30 - 21) * ((100vw - 320px) / (1920 - 320)));
  display: flex;
  align-items: center; }
  .contact-section .contact-details h2 {
    font-weight: 500;
    margin-top: -3px;
    margin-bottom: 10px;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320))); }
  .contact-section .contact-details h5 {
    font-weight: 400;
    margin-bottom: 30px; }
  .contact-section .contact-details .contact-box {
    margin-top: 25px;
    display: flex;
    align-items: flex-start; }
    .contact-section .contact-details .contact-box .contact-title {
      width: calc(80% - 80px);
      margin-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))); }
      [dir="rtl"] .contact-section .contact-details .contact-box .contact-title {
        margin-left: unset;
        margin-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))); }
      @media (max-width: 991px) {
        .contact-section .contact-details .contact-box .contact-title {
          width: calc(100% - 80px); } }
      .contact-section .contact-details .contact-box .contact-title h4 {
        margin-bottom: 10px;
        font-weight: 500; }
      .contact-section .contact-details .contact-box .contact-title p {
        margin-bottom: 0;
        letter-spacing: 0.6px;
        line-height: 26px; }
        .contact-section .contact-details .contact-box .contact-title p + p {
          margin-top: 0;
          margin-bottom: -2px; }
    .contact-section .contact-details .contact-box .contact-icon {
      width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
      height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden; }
      .contact-section .contact-details .contact-box .contact-icon .feather {
        width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        stroke: var(--theme-color); }
      .contact-section .contact-details .contact-box .contact-icon:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--theme-color);
        opacity: 0.12;
        border-radius: 100%; }

/**=====================
       FAQ Page Css Start
  ==========================**/
.search-title {
  margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
  text-align: center; }
  .search-title h1 {
    font-size: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    display: block;
    margin-top: -10px;
    text-transform: none; }

.faq-section {
  margin-top: -160px; }
  .faq-section .faq-contain {
    text-align: center;
    padding: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    height: 100%;
    transition: 0.5s ease;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 8px 40px 0 rgba(33, 37, 41, 0.1); }
    .faq-section .faq-contain .faq-image {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto; }
      .faq-section .faq-contain .faq-image img {
        object-fit: contain; }
    .faq-section .faq-contain h2 {
      margin-top: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      color: #212529; }
    .faq-section .faq-contain h5 {
      color: #7e7e7e;
      font-weight: 300;
      margin-top: 10px;
      line-height: 1.6;
      margin-bottom: -2px;
      margin: 0 auto -2px;
      letter-spacing: 0.03em; }

.faq-details .faq-link-box {
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 30px; }
  .faq-details .faq-link-box ul li {
    display: block; }
  .faq-details .faq-link-box a {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-bottom: 12px;
    padding-top: 12px;
    color: #212529; }
    .faq-details .faq-link-box a:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: rgba(120, 144, 174, 0.23); }
      @media (max-width: 575px) {
        .faq-details .faq-link-box a:after {
          opacity: 0; } }
    .faq-details .faq-link-box a h4 {
      font-weight: 200;
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .faq-details .faq-link-box a h5 {
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 200;
      margin-left: 10px; }
      [dir="rtl"] .faq-details .faq-link-box a h5 {
        margin-left: 0;
        margin-right: 10px; }
    .faq-details .faq-link-box a + a {
      margin-top: 15px; }

.faq-heading {
  display: flex;
  align-items: flex-start; }
  .faq-heading + .faq-heading {
    margin-top: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320))); }
  .faq-heading .feather {
    width: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-top: -3px; }
  .faq-heading .faq-option {
    margin-left: 10px;
    width: calc(100% - 0px); }
    [dir="rtl"] .faq-heading .faq-option {
      margin-left: 0;
      margin-right: 10px; }
    .faq-heading .faq-option h3 {
      font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
      margin-top: -5px;
      margin-bottom: 0;
      line-height: 1.5; }
    .faq-heading .faq-option h6 {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.6;
      letter-spacing: 0.05em;
      margin-bottom: -4px;
      margin-top: 10px; }

/**=====================
       Review Page Css Start
  ==========================**/
.review-section .review-box {
  position: relative;
  padding: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
  background-color: #fff;
  box-shadow: 0 0 8px rgba(33, 37, 41, 0.08);
  border-radius: 7px;
  transition: 0.5s ease;
  overflow: hidden; }
  .review-section .review-box:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px); }
    .review-section .review-box:hover .review-image .fa-quote-left {
      opacity: 0.04; }
    .review-section .review-box:hover .review-image .fa-quote-right {
      opacity: 0.04; }
  .review-section .review-box .review-image {
    display: flex;
    align-items: center; }
    .review-section .review-box .review-image .fa-quote-left {
      font-size: 170px;
      position: absolute;
      top: -27px;
      left: -12px;
      transform: rotate(-9deg);
      color: var(--theme-color);
      opacity: 0.03;
      transition: 0.5s ease; }
    .review-section .review-box .review-image .fa-quote-right {
      font-size: 170px;
      position: absolute;
      bottom: -27px;
      right: -12px;
      transform: rotate(-9deg);
      color: var(--theme-color);
      opacity: 0.03;
      transition: 0.5s ease; }
    .review-section .review-box .review-image .review-profile {
      position: relative;
      width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
      height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
      overflow: hidden;
      border-radius: 50%; }
      .review-section .review-box .review-image .review-profile img {
        transition: 0.5s ease;
        width: 100%;
        object-fit: cover; }
    .review-section .review-box .review-image .rating {
      margin-top: 0; }
      .review-section .review-box .review-image .rating .fa-star {
        font-size: 14px; }
    .review-section .review-box .review-image .image-name {
      margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))); }
      [dir="rtl"] .review-section .review-box .review-image .image-name {
        margin-left: 0;
        margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))); }
      .review-section .review-box .review-image .image-name h3 {
        position: relative;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        margin: 0 0 4px; }
      .review-section .review-box .review-image .image-name p {
        color: #6c757d;
        margin-bottom: -3px;
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))); }
  .review-section .review-box .review-name p {
    margin-top: -4px;
    margin-bottom: 0;
    color: #6c757d;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 20px;
    hyphens: auto;
    text-align: justify;
    word-break: normal;
    line-height: 1.6; }
    .review-section .review-box .review-name p + p {
      margin: 20px 0; }

/**=====================
       Portfolio Page Css Start
  ==========================**/
.portfolio .peregraph {
  text-align: center;
  color: var(--darkgrey);
  margin: 0 auto;
  width: 90%;
  padding: 15px 0; }
  .portfolio .peregraph p {
    margin: 0;
    padding: 0; }

.portfolio .button ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .portfolio .button ul li {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-color: transparent;
    font-weight: 300;
    color: #7e7e7e;
    border-radius: 5px;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .portfolio .button ul li h4 {
      margin-top: -3px;
      margin-bottom: -4px; }
    .portfolio .button ul li.btn-submit:hover {
      color: var(--theme-color); }
    .portfolio .button ul li.active {
      color: #fff;
      background-color: var(--theme-color); }
      .portfolio .button ul li.active:hover {
        color: #fff; }

.portfolio .parent-container {
  position: relative;
  overflow: hidden; }
  .portfolio .parent-container-size .images {
    width: 100%;
    height: 500px;
    object-fit: cover; }
  .portfolio .parent-container .images {
    transition: 0.5s ease;
    width: 100%; }
  .portfolio .parent-container:hover .overlay-color {
    top: 0;
    opacity: 1; }
    .portfolio .parent-container:hover .overlay-color .overlay-icon {
      opacity: 1; }
  .portfolio .parent-container .overlay-color {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    padding: 20px; }
    .portfolio .parent-container .overlay-color .overlay-icon {
      background-color: #fff;
      color: #212529;
      display: flex;
      align-items: center;
      transition: 0.8s ease;
      width: 100%;
      height: 100px;
      padding: 20px;
      border-radius: 4px;
      position: relative;
      opacity: 0; }
      .portfolio .parent-container .overlay-color .overlay-icon h3 {
        font-size: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        transition: 0.5s ease;
        position: relative; }
      .portfolio .parent-container .overlay-color .overlay-icon p {
        margin-top: 5px;
        margin-bottom: 0; }

.portfolio .card {
  border: none;
  background-color: #fff; }

/**=====================
       Blog Page Css Start
  ==========================**/
.blog-section .blog-image {
  display: flex;
  align-items: center;
  background-color: rgba(240, 243, 248, 0.5);
  padding-right: 35px;
  border-radius: 5px;
  overflow: hidden; }
  @media (max-width: 991px) {
    .blog-section .blog-image {
      display: block; } }
  .blog-section .blog-image img {
    width: 400px;
    height: 400px;
    object-fit: cover; }
    @media (max-width: 991px) {
      .blog-section .blog-image img {
        width: 100%;
        height: auto; } }
  .blog-section .blog-image .blog-details {
    margin-left: 50px; }
    @media (max-width: 991px) {
      .blog-section .blog-image .blog-details {
        margin-left: 0;
        padding: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))); } }
    .blog-section .blog-image .blog-details h5 {
      margin-bottom: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
      margin-top: -3px;
      font-size: calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600; }
      .blog-section .blog-image .blog-details h5 span {
        font-weight: 300;
        font-size: 13px; }
    .blog-section .blog-image .blog-details h2 {
      color: #212529;
      font-weight: 500;
      font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.2;
      letter-spacing: 1px; }
      @media (max-width: 360px) {
        .blog-section .blog-image .blog-details h2 {
          line-height: 1; } }
    .blog-section .blog-image .blog-details p {
      line-height: 1.5;
      font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
      letter-spacing: calc(0px + (1 - 0) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))); }
    .blog-section .blog-image .blog-details .blog-profile {
      display: flex;
      align-items: center;
      margin-left: 15px; }
      @media (max-width: 991px) {
        .blog-section .blog-image .blog-details .blog-profile {
          margin-left: 0; } }
      .blog-section .blog-image .blog-details .blog-profile .fa-quote-left {
        font-size: 170px;
        position: absolute;
        top: -27px;
        left: -12px;
        transform: rotate(-9deg);
        color: var(--theme-color);
        opacity: 0.03;
        transition: 0.5s ease; }
      .blog-section .blog-image .blog-details .blog-profile .fa-quote-right {
        font-size: 170px;
        position: absolute;
        bottom: -27px;
        right: -12px;
        transform: rotate(-9deg);
        color: var(--theme-color);
        opacity: 0.03;
        transition: 0.5s ease; }
      .blog-section .blog-image .blog-details .blog-profile .image-profile {
        position: relative;
        width: 55px;
        height: 55px;
        overflow: hidden;
        border-radius: 50%; }
        .blog-section .blog-image .blog-details .blog-profile .image-profile img {
          transition: 0.5s ease;
          width: 100%;
          height: auto;
          object-fit: cover; }
      .blog-section .blog-image .blog-details .blog-profile .image-name {
        margin-left: 15px; }
        .blog-section .blog-image .blog-details .blog-profile .image-name h3 {
          position: relative;
          font-size: 20px;
          margin-top: -6px;
          font-weight: 500;
          margin-bottom: 4px; }
        .blog-section .blog-image .blog-details .blog-profile .image-name span {
          color: #6c757d;
          margin-bottom: -3px;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320))); }
    .blog-section .blog-image .blog-details .review-name p {
      margin-top: -4px;
      margin-bottom: 0;
      color: #6c757d;
      font-size: 16px;
      letter-spacing: 0.7px;
      line-height: 1.5;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 20px; }
      .blog-section .blog-image .blog-details .review-name p + p {
        margin: 20px 0; }

.blog-categority {
  padding: 0;
  border: none;
  background-color: #fff; }
  .blog-categority.box-bottom {
    margin-bottom: 14px; }
  .blog-categority .blog-img {
    border-radius: 5px;
    position: relative;
    overflow: hidden; }
    .blog-categority .blog-img:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: var(--theme-color);
      opacity: 0;
      transition: all 0.5s ease; }
  .blog-categority .card-body {
    padding: 0;
    margin-top: 20px;
    background-color: #fff; }
    [dir="rtl"] .blog-categority .card-body {
      direction: rtl; }
    .blog-categority .card-body h5 {
      color: var(--theme-color);
      margin-bottom: 6px; }
    .blog-categority .card-body h2 {
      font-weight: 500;
      line-height: 1.3;
      font-size: 16px;
      margin-bottom: 16px;
      color: #212529; }
    .blog-categority .card-body .blog-profile {
      display: flex;
      align-items: center; }
      .blog-categority .card-body .blog-profile .image-profile {
        position: relative;
        width: 45px;
        height: 45px;
        overflow: hidden;
        border-radius: 50%; }
        .blog-categority .card-body .blog-profile .image-profile img {
          transition: 0.5s ease;
          width: 100%;
          height: auto;
          object-fit: cover; }
      .blog-categority .card-body .blog-profile .image-name {
        margin-left: 0;
        display: flex;
        align-items: center; }
        [dir="rtl"] .blog-categority .card-body .blog-profile .image-name {
          margin-right: 0; }
        .blog-categority .card-body .blog-profile .image-name h3 {
          position: relative;
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 0;
          color: #7e7e7e;
          font-weight: 400;
          padding-right: 10px;
          border-right: 1px solid #ddd;
          margin-right: 10px; }
          [dir="rtl"] .blog-categority .card-body .blog-profile .image-name h3 {
            padding-right: 0;
            padding-left: 10px;
            border-left: 1px solid #ddd;
            border-right: none;
            margin-right: 0;
            margin-left: 10px; }
        .blog-categority .card-body .blog-profile .image-name h6 {
          color: #7e7e7e;
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 400; }
    .blog-categority .card-body .review-name p {
      margin-top: -4px;
      margin-bottom: 0;
      color: #6c757d;
      font-size: 16px;
      letter-spacing: 0.7px;
      line-height: 1.5;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 20px; }
      .blog-categority .card-body .review-name p + p {
        margin: 20px 0; }
  .blog-categority:hover .blog-img:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--theme-color);
    opacity: 0.5;
    transition: all 0.5s ease; }

.masonary-blog-section {
  position: relative;
  overflow: hidden; }
  .masonary-blog-section .peregraph {
    text-align: center;
    color: var(--darkgrey);
    margin: 0 auto;
    width: 90%;
    padding: 15px 0; }
    .masonary-blog-section .peregraph p {
      margin: 0;
      padding: 0; }
  .masonary-blog-section .button ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .masonary-blog-section .button ul li {
      display: inline-block;
      font-weight: bold;
      font-size: 15px;
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      background-color: transparent;
      font-weight: 300;
      color: #7e7e7e; }
      .masonary-blog-section .button ul li:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--theme-color);
        transition: 0.4s ease; }
      .masonary-blog-section .button ul li h4 {
        margin-top: -3px;
        margin-bottom: -4px; }
      .masonary-blog-section .button ul li.btn-submit:hover {
        color: var(--theme-color); }
      .masonary-blog-section .button ul li.active {
        color: var(--theme-color); }
        .masonary-blog-section .button ul li.active:after {
          width: 100%; }
  .masonary-blog-section .masonary-blog {
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(33, 37, 41, 0.08);
    border: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: #fff; }
    @media (max-width: 991px) {
      .masonary-blog-section .masonary-blog {
        display: block; } }
    .masonary-blog-section .masonary-blog.box-shadow {
      box-shadow: 0 5px 10px rgba(35, 35, 35, 0.08); }
    .masonary-blog-section .masonary-blog .bg-size {
      width: 30%; }
      @media (max-width: 991px) {
        .masonary-blog-section .masonary-blog .bg-size {
          width: 100%; } }
    .masonary-blog-section .masonary-blog img {
      width: 100%; }
      .masonary-blog-section .masonary-blog img.image-fit {
        height: 350px;
        object-fit: cover;
        box-shadow: 0 5px 10px rgba(35, 35, 35, 0.05); }
    .masonary-blog-section .masonary-blog .card-body {
      padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320))); }
      .masonary-blog-section .masonary-blog .card-body-width {
        width: 70%; }
      @media (max-width: 991px) {
        .masonary-blog-section .masonary-blog .card-body {
          width: 100%; } }
      .masonary-blog-section .masonary-blog .card-body.quote-body {
        text-align: center; }
      .masonary-blog-section .masonary-blog .card-body .quote-image {
        width: 60px;
        height: auto;
        display: block;
        opacity: 0.2;
        margin: 0 auto;
        margin-bottom: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320))); }
      .masonary-blog-section .masonary-blog .card-body span {
        margin-bottom: 16px;
        margin-top: -3px;
        display: block; }
      .masonary-blog-section .masonary-blog .card-body .masonary-name {
        letter-spacing: 1.2px;
        margin-bottom: 6px;
        color: var(--theme-color); }
      .masonary-blog-section .masonary-blog .card-body h2 {
        font-weight: 400;
        line-height: 1.4;
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 6px;
        color: #212529; }
        .masonary-blog-section .masonary-blog .card-body h2.quote-head {
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          letter-spacing: 1.1px; }
      .masonary-blog-section .masonary-blog .card-body p {
        letter-spacing: .06em;
        line-height: 1.6;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 15px; }
      .masonary-blog-section .masonary-blog .card-body .blog-profile {
        display: flex;
        align-items: center;
        border-top: 1px solid #eaeaea;
        padding-top: 16px;
        margin-top: 20px; }
        .masonary-blog-section .masonary-blog .card-body .blog-profile.box-center {
          justify-content: center; }
        .masonary-blog-section .masonary-blog .card-body .blog-profile .image-profile {
          position: relative;
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%; }
          .masonary-blog-section .masonary-blog .card-body .blog-profile .image-profile img {
            transition: 0.5s ease;
            width: 100%;
            height: auto;
            object-fit: cover; }
        .masonary-blog-section .masonary-blog .card-body .blog-profile .image-name {
          margin-left: 15px; }
          .masonary-blog-section .masonary-blog .card-body .blog-profile .image-name h3 {
            position: relative;
            font-size: 17px;
            margin: 0 0 5px 0;
            font-weight: 500;
            color: #212529; }
            .masonary-blog-section .masonary-blog .card-body .blog-profile .image-name h3 span {
              font-weight: 400;
              display: revert;
              color: var(--theme-color); }
          .masonary-blog-section .masonary-blog .card-body .blog-profile .image-name h6 {
            color: #6c757d;
            font-size: 14px; }
      .masonary-blog-section .masonary-blog .card-body .review-name p {
        margin-top: -4px;
        margin-bottom: 0;
        color: #6c757d;
        font-size: 16px;
        letter-spacing: 0.7px;
        line-height: 1.5;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 20px; }
        .masonary-blog-section .masonary-blog .card-body .review-name p + p {
          margin: 20px 0; }

.popular-post .popular-title {
  margin-bottom: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .popular-post .popular-title h2 {
    font-size: calc(22px + (35 - 22) * ((100vw - 320px) / (1920 - 320))); }

.popular-post .popular-image {
  display: flex;
  align-items: center;
  padding-right: 10px; }
  [dir="rtl"] .popular-post .popular-image {
    padding-right: unset; }
  .popular-post .popular-image img {
    width: 100px;
    height: 100px;
    object-fit: cover; }
  .popular-post .popular-image .popular-contain {
    margin-left: 15px; }
    .popular-post .popular-image .popular-contain h5 {
      font-size: calc(16px + (19 - 16) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 10px;
      font-weight: 400;
      margin-top: -3px; }
    .popular-post .popular-image .popular-contain p {
      margin-bottom: -1px;
      font-weight: 400; }
      .popular-post .popular-image .popular-contain p span {
        font-weight: 600; }

.popular-post .popular-details p {
  line-height: 1.8; }

.popular-title {
  margin-bottom: 30px;
  background-color: #eff2f7;
  padding: 16px 20px; }
  .popular-title.minus-spacing {
    margin-bottom: 0; }
  .popular-title h3 {
    font-weight: 500;
    font-size: 16px; }

.left-side {
  position: sticky; }
  .left-side .popular-post {
    padding: 20px;
    border: 1px solid #ddd;
    color: #212529;
    border-radius: 5px; }
    .left-side .popular-post .review-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 2px -2px -2px -2px; }
      .left-side .popular-post .review-box span {
        font-size: 13px;
        display: flex;
        align-items: center;
        font-weight: 300;
        margin: 2px; }
        .left-side .popular-post .review-box span .feather {
          width: 15px;
          margin-right: 5px; }
          [dir="rtl"] .left-side .popular-post .review-box span .feather {
            margin-right: unset;
            margin-left: 5px; }
    .left-side .popular-post .popular-image {
      display: flex;
      align-items: flex-start; }
      .left-side .popular-post .popular-image + .popular-image {
        margin-top: 30px; }
      .left-side .popular-post .popular-image .popular-number {
        border-radius: 7px;
        width: 55px; }
        [dir="rtl"] .left-side .popular-post .popular-image .popular-number {
          width: unset;
          margin-left: 15px; }
        .left-side .popular-post .popular-image .popular-number h4 {
          font-size: 30px;
          font-weight: 400;
          line-height: 1;
          margin-top: -4px; }
          @media (max-width: 1199px) {
            .left-side .popular-post .popular-image .popular-number h4 {
              font-size: 20px; } }
      .left-side .popular-post .popular-image img {
        width: 100px;
        height: 100px;
        object-fit: cover; }
      .left-side .popular-post .popular-image .popular-contain {
        margin-left: 15px; }
        .left-side .popular-post .popular-image .popular-contain h3 {
          font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: 5px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: -0.3px;
          margin-top: -3px; }
        .left-side .popular-post .popular-image .popular-contain p {
          margin-bottom: -1px;
          font-weight: 400; }
          .left-side .popular-post .popular-image .popular-contain p span {
            font-weight: 600; }
    .left-side .popular-post .popular-details p {
      line-height: 1.8; }
    .left-side .popular-post .popular-details .social-media {
      display: flex; }
      @media (max-width: 767px) {
        .left-side .popular-post .popular-details .social-media {
          align-items: center;
          justify-content: center; } }
      .left-side .popular-post .popular-details .social-media .social-icon-box {
        width: 30px;
        height: 30px;
        background-color: #212529;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px; }
        .left-side .popular-post .popular-details .social-media .social-icon-box + .social-icon-box {
          margin-left: 15px; }
        .left-side .popular-post .popular-details .social-media .social-icon-box i {
          color: #fff; }
  .left-side .category-section .category-box {
    transition: all 0.5s ease-in-out;
    display: block; }
    .left-side .category-section .category-box + .category-box {
      margin-top: 5px; }
    .left-side .category-section .category-box:hover {
      transition: all 0.5s ease-in-out; }
      .left-side .category-section .category-box:hover .category-product {
        background-color: #f0f3f8; }
        .left-side .category-section .category-box:hover .category-product .cate-shape {
          background-color: var(--theme-color); }
          .left-side .category-section .category-box:hover .category-product .cate-shape i {
            color: #fff !important; }
        .left-side .category-section .category-box:hover .category-product .cate-contain h5 {
          color: #212529;
          font-weight: 500; }
    .left-side .category-section .category-box .category-product {
      display: flex;
      align-items: center;
      border-radius: 5px;
      transition: 0.5s ease; }
      .left-side .category-section .category-box .category-product .cate-shape {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-in-out; }
        .left-side .category-section .category-box .category-product .cate-shape i {
          font-size: 16px;
          transition: all 0.5s ease-in-out; }
      .left-side .category-section .category-box .category-product .cate-contain {
        margin-left: 15px;
        transition: all 0.5s ease-in-out; }
        [dir="rtl"] .left-side .category-section .category-box .category-product .cate-contain {
          margin-left: unset;
          margin-right: 15px; }
        .left-side .category-section .category-box .category-product .cate-contain h5 {
          transition: all 0.5s ease-in-out;
          color: #212529;
          font-weight: 300; }
    .left-side .category-section .category-box:hover .category-product .cate-shape i {
      color: var(--theme-color); }
    .left-side .category-section .category-box:hover .category-product .cate-contain h5 {
      color: var(--theme-color); }
  .left-side .tag-section .button-tags {
    display: inline-block;
    margin: 2px 0; }

.blog-details {
  border-radius: 8px;
  border: none;
  overflow: hidden;
  display: block;
  align-items: center;
  position: relative; }
  @media (max-width: 767px) {
    .blog-details {
      margin-top: 30px; } }
  .blog-details .blog-image-box {
    position: relative; }
    .blog-details .blog-image-box img {
      width: 100%; }
    .blog-details .blog-image-box .blog-title {
      position: absolute;
      bottom: 20px;
      right: 20px;
      padding: 15px;
      background: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
      @media (max-width: 575px) {
        .blog-details .blog-image-box .blog-title {
          padding: 6px;
          bottom: 10px;
          right: 10px;
          background-color: transparent; } }
      .blog-details .blog-image-box .blog-title .blog-contain {
        margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))); }
        .blog-details .blog-image-box .blog-title .blog-contain h4 {
          font-weight: 300;
          margin-bottom: 10px; }
          @media (max-width: 360px) {
            .blog-details .blog-image-box .blog-title .blog-contain h4 {
              display: none; } }
        .blog-details .blog-image-box .blog-title .blog-contain h2 {
          font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320))); }
  .blog-details .blog-detail-contain {
    padding: 0 0 calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320))) 0;
    margin-top: 40px; }
    .blog-details .blog-detail-contain span {
      margin-bottom: 16px;
      margin-top: -5px;
      display: block; }
    .blog-details .blog-detail-contain h2 {
      font-weight: 400;
      line-height: 1.2;
      font-size: calc(18px + (27 - 18) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 16px;
      margin-top: -8px; }
    .blog-details .blog-detail-contain p {
      letter-spacing: 0.05em;
      line-height: 1.6;
      font-weight: 400;
      margin-bottom: 0;
      text-align: justify; }
      .blog-details .blog-detail-contain p + p {
        margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))); }
      .blog-details .blog-detail-contain p.firt-latter::first-letter {
        float: left;
        font-size: 75px;
        line-height: 60px;
        padding-top: 4px;
        padding-right: 15px;
        padding-left: 3px;
        color: #212529; }
    .blog-details .blog-detail-contain .blog-profile {
      display: flex;
      align-items: center; }
      .blog-details .blog-detail-contain .blog-profile .image-profile {
        position: relative;
        width: 55px;
        height: 55px;
        overflow: hidden;
        border-radius: 50%; }
        .blog-details .blog-detail-contain .blog-profile .image-profile img {
          transition: 0.5s ease;
          width: 100%;
          height: auto;
          object-fit: cover; }
      .blog-details .blog-detail-contain .blog-profile .image-name {
        margin-left: 15px; }
        .blog-details .blog-detail-contain .blog-profile .image-name h3 {
          position: relative;
          font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
          margin-bottom: 4px; }
        .blog-details .blog-detail-contain .blog-profile .image-name h6 {
          color: #6c757d;
          font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320))); }
    .blog-details .blog-detail-contain .review-name p {
      margin-top: -4px;
      margin-bottom: 0;
      color: #6c757d;
      font-size: 16px;
      letter-spacing: 0.7px;
      line-height: 1.5;
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 20px; }
      .blog-details .blog-detail-contain .review-name p + p {
        margin: 20px 0; }

.leave-comments .form-control {
  background-color: #f0f3f8;
  border: none; }
  .leave-comments .form-control:focus {
    border-color: transparent; }

.block-shadow-space {
  padding-bottom: calc(80px - 14px); }

/**=====================
       Pagination Css Start
  ==========================**/
.page-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px; }
  .page-section .pagination {
    margin-bottom: 0; }
    [dir="rtl"] .page-section .pagination .page-item .page-link span i {
      transform: rotate(-180deg); }
    .page-section .pagination .page-item + .page-item {
      margin-left: 10px; }
      [dir="rtl"] .page-section .pagination .page-item + .page-item {
        margin-left: unset;
        margin-right: 10px; }
    .page-section .pagination .page-item.active .page-link {
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-weight: 600;
      z-index: 0; }
    .page-section .pagination .page-item .page-link {
      border: 1px solid transparent;
      color: var(--theme-color);
      border-radius: 5px;
      background-color: #fff; }
      .page-section .pagination .page-item .page-link:hover {
        background-color: #eff2f7;
        color: var(--theme-color); }
      .page-section .pagination .page-item .page-link:focus {
        background-color: transparent;
        box-shadow: none; }

/**=====================
       Cart Page Scss Start
  ==========================**/
.wish-list-section.table-dashboard thead tr th {
  padding: 16px 12px; }

.wish-list-section.table-dashboard tbody tr td {
  padding: 16px; }

.wish-list-section .count-down {
  margin-bottom: 20px;
  background: #f0f3f8;
  padding: 10px;
  letter-spacing: 1.1px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212529; }
  @media (max-width: 767px) {
    .wish-list-section .count-down {
      display: block;
      text-align: center; } }
  .wish-list-section .count-down h5 {
    margin-right: 10px; }
    @media (max-width: 767px) {
      .wish-list-section .count-down h5 {
        margin-right: 0; } }

.wish-list-section.shopping-button {
  display: block; }
  .wish-list-section.shopping-button .fas {
    margin-right: 10px; }

.table tbody + tbody {
  border-top: none; }

.cart-buttons {
  padding-top: 35px; }
  .cart-buttons > div:last-child {
    text-align: right;
    padding-right: 59px;
    padding-right: 38px; }

@media (max-width: 767px) {
  .wishlist-table thead tr th:nth-child(n + 3) {
    display: none; } }

@media (max-width: 767px) {
  .wishlist-table tbody tr td:nth-child(n + 3) {
    display: none; }
  .wishlist-table tbody tr td .mobile-cart-content .col {
    justify-content: center; } }

.cart-table {
  overflow: hidden;
  margin-bottom: 0; }
  .cart-table thead th {
    border-bottom-width: 1px;
    font-weight: 600;
    color: #212529;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 0;
    text-align: center;
    padding: 12px;
    border-bottom: 1px solid #eff2f7 !important;
    background-color: #eff2f7; }
  .cart-table tbody h2 {
    margin-bottom: 0; }
  .cart-table tbody tr td {
    vertical-align: middle;
    color: #777777;
    border-top: 0;
    border-bottom: 1px solid #eff2f7 !important;
    text-align: center;
    padding: 14px 8px; }
    .cart-table tbody tr td a {
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
      margin-bottom: 0;
      display: inline-block; }
      .cart-table tbody tr td a.icon {
        background-color: #eff2f7;
        border: 1px solid #eff2f7;
        margin: 0 5px; }
        @media (max-width: 767px) {
          .cart-table tbody tr td a.icon {
            margin: 0; } }
        .cart-table tbody tr td a.icon:hover .fas {
          color: var(--theme-color); }
        .cart-table tbody tr td a.icon .fas {
          width: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
          height: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px; }
      .cart-table tbody tr td a img {
        height: 70px; }
    .cart-table tbody tr td p {
      color: #212529;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
      margin-bottom: 0; }
      .cart-table tbody tr td p img {
        height: 90px; }
    .cart-table tbody tr td .td-color {
      color: var(--theme-deafult); }
    .cart-table tbody tr td h2 {
      font-size: 20px;
      color: #212529;
      font-weight: 400; }
    .cart-table tbody tr td .mobile-cart-content {
      display: none;
      justify-content: center;
      margin-top: 10px; }
      @media (max-width: 767px) {
        .cart-table tbody tr td .mobile-cart-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap; } }
      .cart-table tbody tr td .mobile-cart-content .qty-box {
        border-radius: 5px; }
        .cart-table tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
          width: 48px;
          padding: 6px; }
      .cart-table tbody tr td .mobile-cart-content .col {
        align-self: center;
        margin: 10px 0; }
        @media (max-width: 767px) {
          .cart-table tbody tr td .mobile-cart-content .col {
            margin: 0;
            display: flex;
            white-space: nowrap; } }
      .cart-table tbody tr td .mobile-cart-content h2 {
        font-size: 20px;
        margin-left: 10px; }
    .cart-table tbody tr td .qty-box .input-group {
      display: block; }
      .cart-table tbody tr td .qty-box .input-group .form-control {
        width: 75px;
        margin: 0 auto;
        text-align: center; }

tfoot tr th {
  padding-top: 35px;
  text-align: right; }

tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px;
  padding-right: 41px; }
  tfoot tr td h2 {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center; }
  tfoot tr td:first-child {
    width: 85%;
    font-size: 15px;
    padding-right: 10px;
    text-transform: capitalize;
    font-weight: 700; }

.left-side-button {
  display: flex;
  align-items: center; }
  .left-side-button a {
    border-radius: 5px; }
    .left-side-button a i {
      margin-right: 6px; }
  @media (max-width: 767px) {
    .left-side-button {
      text-align: center; } }
  @media (max-width: 767px) {
    .left-side-button .shopping-button {
      width: 100%;
      text-align: center; } }

.cart-section .count-down {
  margin-bottom: 5px;
  padding: 0;
  letter-spacing: 1.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212529; }
  @media (max-width: 767px) {
    .cart-section .count-down {
      display: block;
      text-align: center; } }
  .cart-section .count-down h5 {
    margin-right: 10px; }
    [dir="rtl"] .cart-section .count-down h5 {
      margin-right: unset;
      margin-left: 10px; }
    @media (max-width: 767px) {
      .cart-section .count-down h5 {
        margin-right: 0;
        margin-bottom: 13px; } }

.cart-section .table tbody + tbody {
  border-top: none; }

.cart-section .cart-buttons {
  padding-top: 35px; }
  .cart-section .cart-buttons > div:last-child {
    text-align: right;
    padding-right: 59px;
    padding-right: 38px; }

.cart-section .cart-table {
  overflow: hidden;
  margin-bottom: 0; }
  .cart-section .cart-table thead th {
    border-bottom-width: 1px;
    font-weight: 600;
    color: #212529;
    text-transform: uppercase;
    font-size: 15px;
    border-top: 0;
    text-align: center;
    border-bottom: 1px solid #eff2f7 !important;
    padding: 12px;
    background-color: #eff2f7; }
    @media (max-width: 767px) {
      .cart-section .cart-table thead th:nth-last-child(-n + 4) {
        display: none; } }

.cart-section tbody h2 {
  margin-bottom: 0; }

.cart-section tbody tr.table-order td {
  min-width: 150px; }

.cart-section tbody tr td {
  vertical-align: middle;
  color: #212529;
  border-top: 0;
  border-bottom: 1px solid #c7c7c5 !important;
  text-align: center;
  min-width: 175px; }
  @media (max-width: 767px) {
    .cart-section tbody tr td:nth-last-child(-n + 4) {
      display: none; } }
  .cart-section tbody tr td a {
    color: #7e7e7e;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0; }
    .cart-section tbody tr td a.icon {
      border: 1px solid rgba(169, 169, 169, 0.5);
      padding: 10px 15px;
      border-radius: 3px; }
    .cart-section tbody tr td a img {
      height: 80px; }
  .cart-section tbody tr td p {
    color: #777777;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0; }
    .cart-section tbody tr td p img {
      height: 90px; }
  .cart-section tbody tr td .td-color {
    color: var(--theme-deafult); }
  .cart-section tbody tr td h2 {
    font-size: 20px;
    color: var(--theme-color);
    font-weight: 400; }
  .cart-section tbody tr td .mobile-cart-content {
    display: none;
    justify-content: center;
    margin-top: 10px; }
    @media (max-width: 767px) {
      .cart-section tbody tr td .mobile-cart-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap; } }
    .cart-section tbody tr td .mobile-cart-content .qty-box {
      border-radius: 5px; }
      .cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
        width: 48px;
        padding: 6px; }
    .cart-section tbody tr td .mobile-cart-content .col {
      align-self: center;
      margin: 10px 0; }
      @media (max-width: 767px) {
        .cart-section tbody tr td .mobile-cart-content .col {
          margin: 0;
          display: flex; } }
    .cart-section tbody tr td .mobile-cart-content h2 {
      font-size: 20px; }
  .cart-section tbody tr td .qty-box .input-group {
    display: block; }
    .cart-section tbody tr td .qty-box .input-group .form-control {
      width: 75px;
      margin: 0 auto;
      text-align: center;
      padding: 5px;
      height: 50px; }

.cart-section tfoot tr th {
  padding-top: 35px;
  text-align: right; }

.cart-section tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px;
  padding-right: 41px; }
  .cart-section tfoot tr td h2 {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center; }
  .cart-section tfoot tr td:first-child {
    width: 85%;
    font-size: 15px;
    padding-right: 10px;
    text-transform: capitalize;
    font-weight: 700; }

.cart-section .left-side-button {
  display: flex;
  align-items: center; }
  [dir="rtl"] .cart-section .left-side-button a i {
    transform: rotate(180deg); }
  @media (max-width: 767px) {
    .cart-section .left-side-button {
      text-align: center; } }
  @media (max-width: 767px) {
    .cart-section .left-side-button .shopping-button {
      width: 100%;
      text-align: center; } }

.cart-section .cart-checkout-section {
  margin-top: 30px; }
  .cart-section .cart-checkout-section .promo-section .input-group {
    display: flex;
    align-items: center; }
    .cart-section .cart-checkout-section .promo-section .input-group .form-control {
      padding: 12px 20px; }
  .cart-section .cart-checkout-section .checkout-button {
    text-align: right; }
    [dir="rtl"] .cart-section .cart-checkout-section .checkout-button {
      text-align: left; }
    .cart-section .cart-checkout-section .checkout-button a {
      border-radius: 5px; }
  .cart-section .cart-checkout-section .cart-box {
    background-color: #eff2f7;
    border-radius: 10px;
    overflow: hidden;
    padding: 0; }
    .cart-section .cart-checkout-section .cart-box label {
      font-weight: 500;
      margin-bottom: 8px;
      text-transform: capitalize; }
    .cart-section .cart-checkout-section .cart-box .cart-box-details .total-details span {
      float: right; }
      [dir="rtl"] .cart-section .cart-checkout-section .cart-box .cart-box-details .total-details span {
        float: left; }
    .cart-section .cart-checkout-section .cart-box .cart-box-details .total-details .top-details {
      border-bottom: 1px solid #c7c7c5;
      padding: 22px;
      margin-bottom: 0; }
      .cart-section .cart-checkout-section .cart-box .cart-box-details .total-details .top-details h3 {
        margin-bottom: 10px;
        color: #212529; }
      .cart-section .cart-checkout-section .cart-box .cart-box-details .total-details .top-details h6 {
        line-height: 1.9;
        color: #212529; }
    .cart-section .cart-checkout-section .cart-box .cart-box-details .total-details .bottom-details a {
      background-color: var(--theme-color);
      width: 100%;
      display: block;
      padding: 12px 0;
      text-align: center;
      color: #fff;
      font-weight: 500;
      letter-spacing: 1.2px; }

/**=====================
       Checkout Page Scss Start
  ==========================**/
.your-cart-box {
  position: sticky;
  top: 10px; }
  .your-cart-box .list-group .lh-condensed {
    padding: 15px 25px;
    background-color: #fff; }
    .your-cart-box .list-group .lh-condensed.active {
      background: #eff2f7;
      border-color: rgba(33, 37, 41, 0.125); }
    .your-cart-box .list-group .lh-condensed h6 {
      color: #212529; }
    .your-cart-box .list-group .lh-condensed small,
    .your-cart-box .list-group .lh-condensed span,
    .your-cart-box .list-group .lh-condensed strong {
      color: #7e7e7e; }
  .your-cart-box .list-group .card {
    border: none; }
  .your-cart-box .new-badge {
    border-radius: 50% !important;
    padding: 8px;
    line-height: 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px; }
  .your-cart-box .custome-imput-group .form-control {
    padding: 6px 12px; }

.custome-form-select {
  padding: 12px 20px; }

.checkout-label {
  padding-top: 2px; }

.custome-radio-box {
  display: inline-block;
  margin-right: 10px; }
  [dir="rtl"] .custome-radio-box {
    margin-right: 0;
    margin-left: 10px; }
  .custome-radio-box .form-check-input {
    transition: all 0.3s ease-in-out;
    margin-top: 2px;
    background-color: #fff; }
    .custome-radio-box .form-check-input:checked {
      background-color: var(--theme-color);
      border-color: var(--theme-color); }
    .custome-radio-box .form-check-input:active {
      filter: none; }
    .custome-radio-box .form-check-input:focus {
      box-shadow: none; }

hr {
  background-color: #6c757d; }

/**=====================
       Order Success css Start
  ==========================**/
.success-icon {
  padding: 70px 20px;
  background-color: #f0f3f8; }
  @media (max-width: 575px) {
    .success-icon {
      padding: 30px 20px; } }
  .success-icon .main-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center; }
    .success-icon .main-container .check-container {
      width: 100px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: column;
      animation: mover 1s infinite alternate; }
      .success-icon .main-container .check-container .check-background {
        width: 100%;
        height: calc(100% - 20px);
        background: linear-gradient(to bottom right, #008000, #008000);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
        transform: scale(0.84);
        border-radius: 50%;
        animation: animateContainer 0.75s ease-out forwards 0.75s;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0; }
        .success-icon .main-container .check-container .check-background svg {
          width: 65%;
          transform: translateY(4px);
          stroke-dasharray: 80;
          stroke-dashoffset: 80;
          animation: animateCheck 0.35s forwards 1.25s ease-out; }
      .success-icon .main-container .check-container .check-shadow {
        bottom: calc(-15% - 5px);
        left: 0;
        border-radius: 50%;
        background: radial-gradient(closest-side, #49da83, transparent);
        animation: animateShadow 0.75s ease-out forwards 0.75s; }
  .success-icon .success-contain {
    text-align: center; }
    .success-icon .success-contain h4 {
      color: green;
      font-size: 30px;
      font-weight: 500; }
    .success-icon .success-contain h5 {
      margin: 5px 0 10px;
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320))); }
    .success-icon .success-contain h6 {
      letter-spacing: 1.1px; }

.order-left-box {
  position: relative;
  text-align: center; }
  .order-left-box:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #ddd; }
  .order-left-box:nth-child(4)::after {
    width: 0; }
  @media (max-width: 991px) {
    .order-left-box:nth-child(2)::after {
      width: 0; } }

.order-details-table .table tbody {
  border-bottom: 1px solid #ddd; }
  .order-details-table .table tbody tr td {
    padding: 10px;
    text-align: left;
    max-width: 150px;
    border-bottom: none !important;
    color: #7e7e7e; }
    [dir="rtl"] .order-details-table .table tbody tr td {
      text-align: right; }
    @media (max-width: 767px) {
      .order-details-table .table tbody tr td:nth-last-child(-n + 4) {
        display: revert !important; } }
    .order-details-table .table tbody tr td:nth-child(1) {
      background-color: transparent; }
    .order-details-table .table tbody tr td h5 {
      margin-top: 10px;
      color: #212529; }

.order-details-table .table tfoot tr td {
  text-align: right;
  padding: 10px 15px; }
  [dir="rtl"] .order-details-table .table tfoot tr td {
    text-align: left; }
  .order-details-table .table tfoot tr td:nth-child(1) {
    text-align: left;
    width: 100%; }
    [dir="rtl"] .order-details-table .table tfoot tr td:nth-child(1) {
      text-align: right; }
  .order-details-table .table tfoot tr td h4 {
    font-weight: 400;
    color: #212529; }

.order-details-table .table tfoot tr.table-order:nth-child(3) {
  border-bottom: 1px solid #ddd; }

.order-details-table .order-success {
  padding: 30px;
  border-radius: 5px;
  background-color: #eff2f7; }
  @media (max-width: 360px) {
    .order-details-table .order-success {
      padding: 20px; } }
  .order-details-table .order-success h4 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 16px;
    margin-bottom: 8px; }
  .order-details-table .order-success .order-details li {
    display: flex;
    text-transform: capitalize;
    line-height: 1.6;
    font-size: 15px;
    margin-bottom: 3px;
    color: #7e7e7e; }
  .order-details-table .order-success .payment-mode p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 0;
    color: #7e7e7e;
    letter-spacing: 1px; }
  .order-details-table .order-success .delivery-sec {
    margin-bottom: -5px;
    padding: 0;
    margin-top: 20px;
    background-color: transparent; }
    .order-details-table .order-success .delivery-sec h3 {
      color: #212529;
      text-transform: capitalize;
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 400; }
      .order-details-table .order-success .delivery-sec h3 span {
        font-weight: 700;
        margin-left: 5px; }
        [dir="rtl"] .order-details-table .order-success .delivery-sec h3 span {
          margin-left: 0;
          margin-right: 5px; }
    .order-details-table .order-success .delivery-sec a {
      font-size: 16px;
      text-transform: capitalize;
      margin-top: 5px;
      display: block;
      font-weight: 600; }

/**=======================
   Dashboard Scss Start
  ============================**/
.dash-filter {
  margin-bottom: 40px; }

.custome-nav-tabs {
  padding: 20px;
  color: black;
  border: none;
  border-radius: 5px;
  position: sticky;
  top: 10px;
  background: #eff2f7; }
  [dir="rtl"] .custome-nav-tabs {
    padding: 20px; }
  @media (max-width: 991px) {
    .custome-nav-tabs {
      position: fixed;
      z-index: 9;
      top: 0;
      left: -300px;
      width: 300px;
      height: 100%;
      z-index: 1;
      border-radius: 0;
      transition: all 0.3s ease-in-out; }
      .custome-nav-tabs.show {
        left: 0; } }
  .custome-nav-tabs .nav-item .nav-link {
    width: 100%;
    text-align: left;
    border-radius: 5px;
    background: transparent;
    border: 1px solid transparent;
    position: relative;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    color: #212529 !important; }
    [dir="rtl"] .custome-nav-tabs .nav-item .nav-link {
      text-align: right; }
    .custome-nav-tabs .nav-item .nav-link i {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.5s ease-in-out; }
      [dir="rtl"] .custome-nav-tabs .nav-item .nav-link i {
        top: 3px;
        left: unset;
        right: 0;
        transform: rotate(180deg) translateY(-50%); }
    .custome-nav-tabs .nav-item .nav-link:before {
      content: "\f101";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
      opacity: 0; }
      [dir="rtl"] .custome-nav-tabs .nav-item .nav-link:before {
        left: unset;
        right: 0;
        top: 0;
        transform: rotate(180deg) translateY(-50%); }
    .custome-nav-tabs .nav-item .nav-link:hover {
      padding-left: 20px;
      color: var(--theme-color) !important; }
      [dir="rtl"] .custome-nav-tabs .nav-item .nav-link:hover {
        padding-left: 0;
        padding-right: 20px; }
      .custome-nav-tabs .nav-item .nav-link:hover:before {
        opacity: 1;
        left: 0px; }
        [dir="rtl"] .custome-nav-tabs .nav-item .nav-link:hover:before {
          left: unset;
          right: 0; }
    .custome-nav-tabs .nav-item .nav-link.active {
      padding-left: 20px;
      color: var(--theme-color) !important;
      font-weight: 600; }
      [dir="rtl"] .custome-nav-tabs .nav-item .nav-link.active {
        padding-left: 0;
        padding-right: 20px; }
      .custome-nav-tabs .nav-item .nav-link.active:before {
        opacity: 1;
        left: 0px; }
        [dir="rtl"] .custome-nav-tabs .nav-item .nav-link.active:before {
          left: unset;
          right: 0;
          top: 0;
          transform: rotate(180deg) translateY(-50%); }

.page-title {
  margin-bottom: 32px;
  display: flex;
  align-items: center; }
  .page-title h2 {
    font-size: 28px;
    margin-top: -3px;
    color: #212529; }
  .page-title a {
    margin-left: 30px; }

.box-head {
  margin-bottom: 18px;
  display: flex;
  align-items: center; }
  .box-head h2 {
    font-size: 25px;
    letter-spacing: 1.1px; }
  .box-head a {
    margin-left: 20px; }
    [dir="rtl"] .box-head a {
      margin-left: unset;
      margin-right: 20px; }

.dashboard {
  background-color: transparent;
  padding: 0 15px;
  border: none;
  border-radius: 6px; }
  .dashboard .page-title {
    margin-bottom: calc(22px + (32 - 22) * ((100vw - 320px) / (1920 - 320))); }
    .dashboard .page-title h2 {
      font-size: calc(22px + (38 - 22) * ((100vw - 320px) / (1920 - 320))); }
  .dashboard address a {
    display: block;
    margin-bottom: -5px;
    margin-top: 10px; }
  .dashboard .welcome-msg p {
    letter-spacing: 0.3px;
    margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5; }
  .dashboard .welcome-msg h6 {
    letter-spacing: 1.5px; }
    .dashboard .welcome-msg h6 span {
      font-weight: 600;
      color: #212529; }
  .dashboard .box-account {
    margin-top: calc(25px + (45 - 25) * ((100vw - 320px) / (1920 - 320))); }
    .dashboard .box-account .box-content {
      margin: 10px 0; }
      .dashboard .box-account .box-content h4 {
        margin: 15px 0; }
  .dashboard .address-box {
    margin-top: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320))); }
  .dashboard .box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320))) 0; }
    .dashboard .box-title h3 {
      font-weight: 400; }
  .dashboard h6,
  .dashboard p {
    margin: 6px 0;
    line-height: 1.4; }

.order-box-contain .order-box {
  position: relative;
  overflow: hidden;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: #eff2f7;
  box-shadow: none; }
  .order-box-contain .order-box .order-box-image {
    position: absolute;
    top: 50%;
    right: -20px;
    width: 25%;
    opacity: 0.04;
    transform: rotate(0) translateY(-50%);
    transition: all 0.5s ease-in-out; }
    [dir="rtl"] .order-box-contain .order-box .order-box-image {
      left: -20px;
      right: unset; }
  .order-box-contain .order-box:hover .order-box-image {
    transform: rotate(-7deg) translateY(-50%);
    opacity: 0.25; }
    [dir="rtl"] .order-box-contain .order-box:hover .order-box-image {
      transform: rotate(7deg) translateY(-50%); }
  .order-box-contain .order-box .order-box-contain {
    display: flex;
    align-items: center; }
    .order-box-contain .order-box .order-box-contain img {
      width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320))); }
    .order-box-contain .order-box .order-box-contain > div {
      width: calc(100% - 20px);
      margin-left: 15px;
      text-transform: capitalize; }
      [dir="rtl"] .order-box-contain .order-box .order-box-contain > div {
        margin-left: unset;
        margin-right: 15px; }
      .order-box-contain .order-box .order-box-contain > div h3 {
        margin-top: 6px; }

.dashboard-profile .dash-profile li {
  display: flex;
  align-items: center;
  margin-bottom: 2px; }
  @media (max-width: 575px) {
    .dashboard-profile .dash-profile li {
      flex-wrap: wrap; } }
  .dashboard-profile .dash-profile li .left {
    width: 220px; }
    @media (max-width: 575px) {
      .dashboard-profile .dash-profile li .left {
        width: auto;
        margin-right: 10px; }
        [dir="rtl"] .dashboard-profile .dash-profile li .left {
          margin-right: 0;
          margin-left: 10px; } }
    .dashboard-profile .dash-profile li .left h6 {
      margin: 0; }
  .dashboard-profile .dash-profile li a {
    margin-left: 20px; }
    [dir="rtl"] .dashboard-profile .dash-profile li a {
      margin-left: unset;
      margin-right: 20px; }

.dashboard-security .page-title {
  margin-bottom: 10px; }

.dashboard-security p {
  line-height: 1.6;
  letter-spacing: 0.05em; }

.dashboard-security .security-details {
  margin-bottom: 18px; }
  .dashboard-security .security-details h5 span {
    font-weight: 500;
    color: #212529; }

.dashboard-security .security-details-1 {
  border: 1px dashed rgba(0, 0, 0, 0.28);
  border-radius: 5px;
  padding: 15px; }

.success-button {
  background-color: green;
  margin: 0 !important;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 12px !important;
  color: #fff !important; }

.danger-button {
  background-color: var(--theme-color);
  margin: 0 !important;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 12px !important;
  color: #fff !important; }
  .danger-button.danger-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 !important; }
    .danger-button.danger-center .feather {
      stroke: #fff; }

.save-details-box .save-details {
  border: 1px solid #eff2f7;
  padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  background-color: #eff2f7;
  position: relative; }
  .save-details-box .save-details.show {
    box-shadow: 0 0px 10px rgba(33, 37, 41, 0.2); }
  .save-details-box .save-details .save-name {
    position: relative;
    margin-bottom: 20px; }
    .save-details-box .save-details .save-name h5 {
      color: #212529; }
  .save-details-box .save-details .save-position {
    position: absolute;
    background-color: var(--theme-color);
    top: 0;
    right: 0;
    color: #fff;
    padding: 8px 15px;
    border-radius: 4px; }
    [dir="rtl"] .save-details-box .save-details .save-position {
      right: unset;
      left: 0; }
    .save-details-box .save-details .save-position h6 {
      margin: 0;
      margin-bottom: -2px;
      font-weight: 500; }
  .save-details-box .save-details .save-address p {
    line-height: 1.4;
    letter-spacing: 1px;
    margin: 0 0 calc(2px + (5 - 2) * ((100vw - 320px) / (1920 - 320))); }
  .save-details-box .save-details .mobile p.mobile {
    margin-top: 20px;
    padding-bottom: 6px; }
  .save-details-box .save-details .button {
    display: flex;
    align-items: center;
    margin: -5px; }
    .save-details-box .save-details .button a {
      width: 100%;
      margin: 5px;
      background-color: #fff;
      color: #212529; }

.payment-card-detail {
  position: relative; }
  .payment-card-detail:hover .edit-card {
    opacity: 1;
    transition: all 0.3s ease-in-out; }
  .payment-card-detail.add-card {
    background-color: #f9f9f9;
    cursor: pointer;
    padding: 0; }
    .payment-card-detail.add-card .card-details {
      min-height: 130px;
      background: #d2d2d2;
      color: #212529;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .payment-card-detail.add-card .card-details h5 {
        margin-bottom: 0;
        text-transform: capitalize; }
  .payment-card-detail .card-details {
    color: #fff;
    padding: 30px 20px;
    border-radius: 5px;
    background-color: #4b77a0;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%); }
    .payment-card-detail .card-details.card-visa {
      background-color: #777876; }
    .payment-card-detail .card-details.dabit-card {
      background-color: #86b8cf; }
    .payment-card-detail .card-details .card-number {
      margin-bottom: 10px; }
      .payment-card-detail .card-details .card-number h4 {
        color: #fff; }
    .payment-card-detail .card-details .valid-detail {
      display: flex;
      align-items: center;
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))); }
      .payment-card-detail .card-details .valid-detail .title {
        margin-bottom: 0; }
        .payment-card-detail .card-details .valid-detail .title span {
          display: block;
          color: rgba(255, 255, 255, 0.7);
          text-transform: uppercase;
          font-size: 12px;
          line-height: 1.3; }
      .payment-card-detail .card-details .valid-detail .date h3 {
        margin-bottom: 0;
        color: #fff;
        margin-left: 15px;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: normal; }
        [dir="rtl"] .payment-card-detail .card-details .valid-detail .date h3 {
          margin-left: 0;
          margin-right: 15px; }
      .payment-card-detail .card-details .valid-detail .primary {
        margin-left: auto; }
        [dir="rtl"] .payment-card-detail .card-details .valid-detail .primary {
          margin-left: 0;
          margin-right: auto; }
        .payment-card-detail .card-details .valid-detail .primary span {
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          padding: 0;
          font-weight: 400;
          text-transform: capitalize; }
    .payment-card-detail .card-details .name-detail {
      margin-top: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center; }
      .payment-card-detail .card-details .name-detail .name {
        width: 100%; }
        .payment-card-detail .card-details .name-detail .name h5 {
          text-transform: uppercase;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: 0;
          color: #fff; }
      .payment-card-detail .card-details .name-detail .card-img {
        display: inline-block;
        margin-left: auto;
        text-align: right; }
        [dir="rtl"] .payment-card-detail .card-details .name-detail .card-img {
          text-align: left; }
  .payment-card-detail .edit-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    background-color: rgba(33, 37, 41, 0.8);
    border-radius: 5px;
    opacity: 0;
    transition: all 0.5s ease; }
    @media (max-width: 767px) {
      .payment-card-detail .edit-card {
        display: none; } }
    .payment-card-detail .edit-card a {
      padding: 0 10px;
      text-transform: capitalize;
      color: #fff; }

.edit-card-mobile {
  display: none; }
  @media (max-width: 767px) {
    .edit-card-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px; }
      .edit-card-mobile a {
        padding: 0 10px;
        text-transform: capitalize;
        color: #212529; } }

/**============================
    Order Tracking css Start
  =================================**/
.tracker-title {
  font-size: 28px;
  letter-spacing: 1.2px;
  margin: 0;
  font-weight: 500; }
  @media (max-width: 360px) {
    .tracker-title {
      font-size: 26px;
      line-height: 1.5; } }

.tracker-number p {
  margin-bottom: 10px;
  letter-spacing: 1.2px; }
  .tracker-number p span {
    font-weight: 500;
    margin-left: 10px;
    color: #212529; }

.progtrckr {
  margin: 40px 0 15px;
  padding: 0;
  display: flex;
  align-items: flex-start; }
  @media (max-width: 575px) {
    .progtrckr {
      display: block;
      margin: 30px 0; } }
  .progtrckr li {
    display: inline-block;
    text-align: center;
    margin: 10px 0; }
    @media (max-width: 575px) {
      .progtrckr li {
        margin: 0;
        width: 50% !important; } }
    .progtrckr li h5 {
      margin-top: 20px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 500; }
      @media (max-width: 575px) {
        .progtrckr li h5 {
          display: block;
          text-align: left;
          margin-top: 0; }
          [dir="rtl"] .progtrckr li h5 {
            text-align: right; } }
    .progtrckr li:before {
      position: relative;
      top: -2px;
      float: left;
      left: 50% !important;
      line-height: 1;
      transform: translate(-50%, -50%) !important; }
      @media (max-width: 575px) {
        .progtrckr li:before {
          position: relative;
          top: 10px;
          float: left;
          left: -2px !important;
          line-height: 1;
          transform: translate(-50%, -50%) !important; }
          [dir="rtl"] .progtrckr li:before {
            float: right;
            right: -32px !important;
            left: unset !important; } }
    .progtrckr li.progtrckr-todo {
      color: #c0c0c0;
      border-top: 4px solid #c0c0c0;
      width: 100% !important; }
      @media (max-width: 575px) {
        .progtrckr li.progtrckr-todo {
          position: relative;
          text-align: left;
          margin-left: 30px;
          align-items: flex-start;
          padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
          padding-left: 30px;
          border-top: unset;
          border-left: 4px solid #c0c0c0; }
          [dir="rtl"] .progtrckr li.progtrckr-todo {
            border-left: none;
            border-right: 4px solid #c0c0c0;
            margin-left: 0;
            margin-right: 30px;
            align-items: flex-end;
            padding-left: 0;
            padding-right: 30px;
            text-align: right; } }
      .progtrckr li.progtrckr-todo:before {
        content: "\f111";
        font-family: "Font Awesome 5 Free";
        color: #c0c0c0;
        background-color: #fff;
        font-size: 2.2em; }
        @media (max-width: 575px) {
          .progtrckr li.progtrckr-todo:before {
            position: absolute;
            top: 10px;
            left: -17px;
            line-height: 1;
            transform: translateY(-50%); }
            [dir="rtl"] .progtrckr li.progtrckr-todo:before {
              left: unset;
              right: -17px; } }
      .progtrckr li.progtrckr-todo h6 {
        font-size: 13px;
        margin-top: 8px; }
    .progtrckr li.progtrckr-done {
      color: #212529;
      border-top: 4px solid var(--theme-color);
      width: 100% !important; }
      @media (max-width: 575px) {
        .progtrckr li.progtrckr-done {
          position: relative;
          text-align: left;
          margin-left: 30px;
          align-items: self-start;
          padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
          border-top: unset;
          border-left: 4px solid var(--theme-color); }
          [dir="rtl"] .progtrckr li.progtrckr-done {
            margin-left: 0;
            margin-right: 30px;
            text-align: right;
            border-left: none;
            border-right: 4px solid var(--theme-color);
            align-items: flex-end; } }
      .progtrckr li.progtrckr-done:before {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #fff;
        background-color: var(--theme-color);
        height: 30px;
        width: 30px;
        line-height: 2.2em;
        border: none;
        border-radius: 2.2em;
        display: flex;
        align-items: center;
        justify-content: center; }
      .progtrckr li.progtrckr-done h6 {
        font-size: 13px;
        margin-top: 8px;
        color: #7e7e7e; }

.order-left-image {
  margin-top: 0;
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .order-left-image {
      display: block; } }
  .order-left-image .tracking-product-image {
    margin-right: 40px;
    width: 30%; }
    [dir="rtl"] .order-left-image .tracking-product-image {
      margin-right: 0;
      margin-left: 40px; }
    @media (max-width: 991px) {
      .order-left-image .tracking-product-image {
        margin-bottom: 18px;
        width: 50%;
        margin-left: auto;
        margin-right: auto; }
        [dir="rtl"] .order-left-image .tracking-product-image {
          margin-right: auto;
          margin-left: auto; } }
    @media (max-width: 767px) {
      .order-left-image .tracking-product-image {
        margin: 0 auto;
        margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))); } }
  .order-left-image .order-image-contain {
    width: calc(100% - 30px); }
    @media (max-width: 991px) {
      .order-left-image .order-image-contain {
        width: 100%; } }
    .order-left-image .order-image-contain p {
      margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
      letter-spacing: 0.05em; }
      .order-left-image .order-image-contain p span {
        font-weight: 500;
        margin-left: 10px;
        color: #212529; }
        [dir="rtl"] .order-left-image .order-image-contain p span {
          margin-left: 0;
          margin-right: 10px; }
    .order-left-image .order-image-contain h4 {
      font-size: 22px;
      letter-spacing: 0.03em;
      margin-bottom: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500; }
      @media (max-width: 767px) {
        .order-left-image .order-image-contain h4 {
          font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320))); } }

.order-address {
  margin-top: 50px; }
  @media (max-width: 360px) {
    .order-address {
      margin-top: 20px; } }
  .order-address li {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 360px) {
      .order-address li {
        display: block; } }
    .order-address li .left-box {
      text-transform: capitalize;
      min-width: 210px;
      padding: 10px 0; }
      .order-address li .left-box h6 {
        font-weight: 500; }
        @media (max-width: 360px) {
          .order-address li .left-box h6 {
            color: #7e7e7e !important;
            margin-bottom: -13px;
            padding-bottom: 8px;
            border-bottom: 1px solid #cacaca;
            font-weight: 500; } }
    .order-address li .right-box {
      padding: 10px 0;
      width: 180px; }
      .order-address li .right-box p {
        margin-bottom: 0;
        line-height: 1.6; }

.tracker-table {
  padding-top: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  overflow: hidden;
  margin-bottom: 0; }
  .tracker-table .table {
    margin-bottom: 0; }
    .tracker-table .table thead th {
      border-bottom-width: 1px;
      font-weight: 500;
      color: #212529 !important;
      text-transform: uppercase;
      font-size: 15px;
      border-top: 0;
      text-align: left;
      padding: 16px;
      border-bottom: 1px solid #eff2f7 !important;
      background-color: #eff2f7; }
      [dir="rtl"] .tracker-table .table thead th {
        text-align: right; }
    .tracker-table .table tbody h2 {
      margin-bottom: 0; }
    .tracker-table .table tbody tr td {
      vertical-align: middle;
      color: #777777;
      border-top: 0;
      border-bottom: 1px solid #eff2f7 !important;
      text-align: left;
      padding: calc(0px + (16 - 0) * ((100vw - 320px) / (1920 - 320))) calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));
      min-width: 175px; }
      [dir="rtl"] .tracker-table .table tbody tr td {
        text-align: right; }
      .tracker-table .table tbody tr td p {
        margin: 10px 0 !important; }
      .tracker-table .table tbody tr td:nth-child(odd) p {
        color: #212529; }
      .tracker-table .table tbody tr td a {
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
        display: inline-block; }

/**=====================
    Element Page css Start
  ==========================**/
.header-image-contain {
  margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))); }
  .header-image-contain .background-contain {
    background-image: none;
    height: 100%; }

.custome-card {
  border: 1px solid #ced4da; }
  .custome-card .card-header {
    background-color: #eff2f7; }
  .custome-card .card-body {
    background-color: #fff; }

/**=====================
    About Page css Start
  ==========================**/
@media (max-width: 991px) {
  .about-image {
    display: none; } }

.about-details {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .about-details h2 {
    margin-bottom: 10px;
    letter-spacing: 1px;
    color: var(--theme-color); }
  .about-details h3 {
    font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1.5px;
    margin-bottom: 16px;
    max-width: 80%;
    line-height: 1.5;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .about-details h3 {
        max-width: 100%; } }
  .about-details p {
    letter-spacing: 1px;
    line-height: 1.5;
    color: #7e7e7e; }

.custome-accordion {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 5px; }
  .custome-accordion .accordion-item {
    border-top: 1px solid rgba(221, 221, 221, 0.5);
    border: none; }
    .custome-accordion .accordion-item .accordion-header {
      border-top: 1px solid rgba(221, 221, 221, 0.5); }
      .custome-accordion .accordion-item .accordion-header .accordion-button {
        background-color: transparent;
        font-weight: 600;
        text-transform: capitalize; }
        .custome-accordion .accordion-item .accordion-header .accordion-button:focus {
          border-color: #c7c7c5;
          box-shadow: none; }
        .custome-accordion .accordion-item .accordion-header .accordion-button.collapsed {
          background-color: #fff;
          font-weight: 400; }
        .custome-accordion .accordion-item .accordion-header .accordion-button:after {
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-left: auto;
          color: #7e7e7e;
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          background-image: none;
          transition: transform 0.4s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center; }
        .custome-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
          color: #212529; }
          .custome-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
            transform: rotate(90deg); }
    .custome-accordion .accordion-item .accordion-collapse .accordion-body p {
      letter-spacing: 1.3px;
      margin-bottom: 0;
      line-height: 1.4;
      text-align: justify;
      color: #7e7e7e; }

.team-contain.team-center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 22px; }
  .team-contain.team-center img {
    width: 40%; }
  .team-contain.team-center .team-details {
    margin-top: 0;
    margin-left: 20px; }
    .team-contain.team-center .team-details p span {
      color: #7e7e7e;
      display: none; }
    .team-contain.team-center .team-details .moreless-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      text-align: right;
      color: var(--theme-color); }

.team-details {
  margin-top: 30px;
  position: relative; }
  .team-details h3 {
    font-size: 23px;
    margin-bottom: 10px;
    color: #212529; }
  .team-details p {
    margin-bottom: 0;
    letter-spacing: 0.6px;
    line-height: 1.5;
    color: #7e7e7e; }
    .team-details p span {
      color: #7e7e7e;
      display: none; }
  .team-details .moreless-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: right;
    color: var(--theme-color); }

.team-image {
  width: 100%;
  height: 220px;
  object-fit: cover; }

.team-leader-section {
  background-color: #eff2f7; }

.leader-contain:hover .leader-image .social-media {
  bottom: 0;
  opacity: 1; }
  @media (max-width: 767px) {
    .leader-contain:hover .leader-image .social-media {
      top: 0;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-radius: 0; } }

.leader-contain .leader-image {
  border-radius: 5px;
  position: relative;
  overflow: hidden; }
  .leader-contain .leader-image .social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -60px;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: block;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(226, 46, 83, 0.8);
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    backdrop-filter: blur(5px); }
    @media (max-width: 767px) {
      .leader-contain .leader-image .social-media {
        top: 0;
        bottom: unset;
        opacity: 0;
        height: 100%;
        background-color: rgba(226, 46, 83, 0.6); } }
    .leader-contain .leader-image .social-media li {
      margin: 0 5px;
      width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      transition: all 0.5s ease-in-out;
      border-radius: 3px; }
      .leader-contain .leader-image .social-media li:hover {
        background-color: rgba(33, 37, 41, 0.7); }
        .leader-contain .leader-image .social-media li:hover a {
          color: #fff; }
      .leader-contain .leader-image .social-media li a {
        color: #ddd;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-in-out;
        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))); }

.leader-contain .leader-contain {
  color: #212529;
  padding: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
  width: 100%;
  text-align: center; }
  .leader-contain .leader-contain h3 {
    letter-spacing: 1px; }
  .leader-contain .leader-contain h6 {
    margin: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) 0 0;
    letter-spacing: 1.6px;
    color: #7e7e7e; }

.testimonial-section .testimonial-contain {
  text-align: center;
  box-shadow: 0 0 8px #ddd;
  padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 10px; }
  [dir="rtl"] .testimonial-section .testimonial-contain {
    direction: rtl; }
  .testimonial-section .testimonial-contain .textimonial-image {
    text-align: center; }
    .testimonial-section .testimonial-contain .textimonial-image img {
      border-radius: 25px;
      border: 1px solid #c7c7c5;
      padding: 5px;
      height: 100px;
      width: 100px;
      margin: 0 auto;
      object-fit: cover; }

.testimonial-section .testimonial-details {
  margin: 15px auto 0; }
  @media (max-width: 767px) {
    .testimonial-section .testimonial-details {
      width: 100%; } }
  .testimonial-section .testimonial-details .details-images {
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.8;
    color: #7e7e7e;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .testimonial-section .testimonial-details .details-images i {
      color: var(--theme-color);
      font-size: 15px;
      vertical-align: super; }
      [dir="rtl"] .testimonial-section .testimonial-details .details-images i {
        transform: rotate(180deg); }
      .testimonial-section .testimonial-details .details-images i.fa-quote-left {
        margin-right: 8px; }
        [dir="rtl"] .testimonial-section .testimonial-details .details-images i.fa-quote-left {
          margin-right: 0;
          margin-left: 8px; }
      .testimonial-section .testimonial-details .details-images i.fa-quote-right {
        margin-left: 8px; }
        [dir="rtl"] .testimonial-section .testimonial-details .details-images i.fa-quote-right {
          margin-left: 0;
          margin-right: 8px; }
    .testimonial-section .testimonial-details .details-images::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      width: 90px;
      height: 3px;
      background-color: var(--theme-color); }
  .testimonial-section .testimonial-details h5 {
    text-transform: uppercase;
    letter-spacing: 1.4px; }

/**=====================
    Dark and RTL css Start
  ==========================**/
.theme-setting {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3; }
  [dir="rtl"] .theme-setting ul {
    padding-right: 0; }
  .theme-setting ul li {
    background-color: #eff2f7;
    border: 1px solid #ced4da;
    margin: 10px 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
    width: 40px;
    height: 38px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600; }
    .theme-setting ul li button {
      color: #212529; }
      .theme-setting ul li button:hover {
        color: #212529; }
    .theme-setting ul li.color-picker {
      background: none;
      overflow: hidden;
      margin-bottom: 0;
      border: none; }
      .theme-setting ul li.color-picker input {
        height: 100%;
        opacity: 1;
        padding: 0;
        border: none;
        font-size: x-large; }

/**=====================
    Dark and RTL css Start
  ==========================**/
.demo-contain {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border: 2px solid rgba(33, 37, 41, 0.3);
  border-radius: 5px;
  position: relative; }
  .demo-contain .demo-image {
    overflow: hidden;
    width: 100%; }
    .demo-contain .demo-image:hover img {
      transform: translateY(calc(-100% + 500px));
      transition: transform 5s ease-in-out; }
    .demo-contain .demo-image img {
      object-fit: cover;
      transition: transform 5s ease-in-out;
      width: 100%; }
    .demo-contain .demo-image a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ff0000;
      color: #212529; }

/**=====================
     Shop page css start
==========================**/
.custome-nav-tabs {
  position: sticky;
  top: 10px; }

.category-scroll {
  max-height: 250px;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .category-scroll::-webkit-scrollbar {
    width: 5px; }
  .category-scroll::-webkit-scrollbar-track {
    background-color: #ddd; }
  .category-scroll::-webkit-scrollbar-thumb {
    background: #b5bec7;
    border-radius: 5px; }
  .category-scroll::-webkit-scrollbar-thumb:hover {
    opacity: 0.8; }

.custome-form-check {
  min-height: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center; }
  [dir="rtl"] .custome-form-check {
    padding-right: 0; }
  .custome-form-check .form-check-input {
    margin-right: 10px;
    width: 17px;
    height: 17px;
    margin-top: 0;
    background-color: transparent;
    border: 1px solid #c7c7c5 !important;
    border-radius: 3px !important;
    transition: all 0.3s ease-in-out; }
    [dir="rtl"] .custome-form-check .form-check-input {
      margin-left: 1.5rem;
      margin-right: 0; }
    .custome-form-check .form-check-input:checked[type="checkbox"] {
      background-image: url(../images/check.svg);
      background-size: 13px; }
    .custome-form-check .form-check-input:focus {
      border-color: transparent;
      box-shadow: none; }
  .custome-form-check .form-check-label {
    color: #212529;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.sidebar-image {
  margin-top: 20px; }
  .sidebar-image img {
    height: 500px;
    border-radius: 5px; }

.category-option {
  position: sticky;
  top: 40px;
  z-index: 1; }
  @media (max-width: 991px) {
    .category-option {
      position: fixed;
      top: 0;
      left: -320px;
      background-color: #fff;
      z-index: 7;
      padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
      width: 300px;
      height: 100vh;
      transition: all 0.5s ease;
      overflow-x: scroll;
      overflow-y: auto; }
      [dir="rtl"] .category-option {
        left: unset;
        right: -320px; } }
  .category-option.show {
    left: 0; }
    [dir="rtl"] .category-option.show {
      left: unset;
      right: 0; }
  .category-option .button-close {
    display: none; }
    @media (max-width: 991px) {
      .category-option .button-close {
        display: block;
        margin-bottom: 10px; } }
    .category-option .button-close button {
      display: flex;
      font-size: 17px;
      color: #212529; }
      .category-option .button-close button .feather {
        display: block;
        width: 18px;
        margin-right: 8px; }
        [dir="rtl"] .category-option .button-close button .feather {
          margin-right: unset;
          margin-left: 8px; }
  .category-option .category-name {
    color: #fff;
    background-color: #fff; }
    .category-option .category-name .accordion-item {
      padding: 20px;
      border: 1px solid #ced4da;
      color: #212529;
      border-radius: 5px;
      background-color: #fff; }
      .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check {
        display: flex;
        align-items: center; }
        .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .form-check-label span {
          margin-left: 10px; }
          [dir="rtl"] .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .form-check-label span {
            margin-left: 0;
            margin-right: 10px; }
        .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check p {
          margin-bottom: 0;
          margin-left: 12px;
          font-size: 13px; }
          [dir="rtl"] .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check p {
            margin-left: 0;
            margin-right: 12px; }
        .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .rating {
          display: flex; }
          .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .rating .rating-list {
            margin: 0; }
            .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .rating .rating-list + .rating-list {
              margin-left: 3px; }
            .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .rating .rating-list .fa-star {
              color: #c3c3c3; }
              .category-option .category-name .accordion-item.category-rating .accordion-collapse .accordion-body .category-list li .form-check .rating .rating-list .fa-star.theme-color {
                color: #ffa202; }
      .category-option .category-name .accordion-item.category-price .accordion-collapse .accordion-body .category-list li {
        display: inline-block;
        padding: 5px 10px;
        background: #fff;
        border-radius: 5px;
        font-size: 13px;
        border: 1px solid #fff;
        margin: 3px 1px; }
        .category-option .category-name .accordion-item.category-price .accordion-collapse .accordion-body .category-list li a {
          color: #212529;
          font-weight: 400; }
      .category-option .category-name .accordion-item.filter-accordion .accordion-collapse .accordion-body .category-list li {
        margin: 0;
        padding: 2px 6px; }
        .category-option .category-name .accordion-item.filter-accordion .accordion-collapse .accordion-body .category-list li:nth-child(3) a .label-tag {
          margin-bottom: 0; }
        .category-option .category-name .accordion-item.filter-accordion .accordion-collapse .accordion-body .category-list li .label-tag {
          display: flex;
          border: none;
          padding: 0 5px;
          margin-bottom: 10px;
          border-radius: 5px; }
          .category-option .category-name .accordion-item.filter-accordion .accordion-collapse .accordion-body .category-list li .label-tag span {
            color: #7f7f7f;
            font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320))); }
          .category-option .category-name .accordion-item.filter-accordion .accordion-collapse .accordion-body .category-list li .label-tag .btn-close {
            font-size: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            margin-left: auto;
            margin-top: 3px; }
      .category-option .category-name .accordion-item.category-color .accordion-collapse {
        margin-top: 22px; }
        .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          flex-wrap: wrap;
          margin: -5px; }
          .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li {
            margin-top: 0;
            margin: 5px; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li a {
              width: 30px;
              height: 30px;
              background-color: #ff0000;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center; }
              .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li a i {
                color: #212529;
                display: none; }
                .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li a i.active {
                  display: block; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(2) a {
              background-color: #fab1a0; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(3) a {
              background-color: #10ac84; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(4) a {
              background-color: #ff9f43; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(5) a {
              background-color: #8395a7; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(6) a {
              background-color: #fab1a0; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(7) a {
              background-color: #10ac84; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(8) a {
              background-color: #ff9f43; }
            .category-option .category-name .accordion-item.category-color .accordion-collapse .accordion-body .category-list li:nth-child(9) a {
              background-color: #8395a7; }
      @media (max-width: 991px) {
        .category-option .category-name .accordion-item {
          padding: 0;
          border: none; } }
      .category-option .category-name .accordion-item + .accordion-item {
        margin-top: 20px; }
      .category-option .category-name .accordion-item .accordion-header {
        margin-bottom: 0;
        background-color: #eff2f7; }
      .category-option .category-name .accordion-item .accordion-collapse {
        border: none;
        border-width: 0 1px;
        margin-top: 10px; }
        .category-option .category-name .accordion-item .accordion-collapse .accordion-body {
          padding: 0; }
          .category-option .category-name .accordion-item .accordion-collapse .accordion-body .category-list {
            overflow: hidden; }
            .category-option .category-name .accordion-item .accordion-collapse .accordion-body .category-list li {
              display: block;
              margin: 10px 5px;
              text-overflow: ellipsis;
              white-space: nowrap; }
              .category-option .category-name .accordion-item .accordion-collapse .accordion-body .category-list li::nth-child(9) {
                margin-bottom: 0; }
    .category-option .category-name .accordion-button {
      color: #212529;
      border: none;
      margin: 0;
      font-weight: bold;
      box-shadow: none;
      background-color: #eff2f7; }
      .category-option .category-name .accordion-button:focus {
        box-shadow: none;
        outline: none; }
      .category-option .category-name .accordion-button:not(.collapsed)::after {
        background-image: url(../images/minus.svg);
        filter: invert(1);
        transform: rotate(0); }
      .category-option .category-name .accordion-button:after {
        background-image: url(../images/plus.svg);
        filter: invert(1);
        transition: all 0.2s ease-in-out; }
  .category-option-1 {
    background-color: #f0f3f8; }
    @media (max-width: 991px) {
      .category-option-1 {
        background-color: #fff; } }
    .category-option-1 .category-name .accordion-item {
      border: none;
      padding: 10px 20px 0;
      background-color: transparent; }
      @media (max-width: 991px) {
        .category-option-1 .category-name .accordion-item {
          padding: 0; } }
      .category-option-1 .category-name .accordion-item + .accordion-item {
        margin-top: 0; }
      .category-option-1 .category-name .accordion-item .accordion-header .accordion-button {
        padding: 14px 8px; }
      .category-option-1 .category-name .accordion-item .accordion-collapse {
        margin-top: 0; }
        .category-option-1 .category-name .accordion-item .accordion-collapse .accordion-body {
          padding: 10px 3px; }
          .category-option-1 .category-name .accordion-item .accordion-collapse .accordion-body .category-list {
            margin-top: -12px; }
            .category-option-1 .category-name .accordion-item .accordion-collapse .accordion-body .category-list li {
              margin-top: 12px; }
              .category-option-1 .category-name .accordion-item .accordion-collapse .accordion-body .category-list li .form-check .form-check-input {
                background-color: #fff; }

.filter-button {
  display: none; }
  @media (max-width: 991px) {
    .filter-button {
      display: block; } }
  .filter-button a {
    display: flex;
    align-items: center; }
  .filter-button .feather {
    width: 20px;
    margin-right: 10px; }
    [dir="rtl"] .filter-button .feather {
      margin-right: unset;
      margin-left: 10px; }
  .filter-button span {
    display: block;
    margin-bottom: -3px; }

.hide-button button {
  font-size: 15px !important;
  background-color: var(--theme-color);
  padding: 4px 10px;
  border-radius: 5px;
  color: #fff !important;
  display: flex;
  align-items: center; }
  .hide-button button .feather {
    width: 20px;
    margin-right: 10px; }
    [dir="rtl"] .hide-button button .feather {
      margin-right: 0;
      transform: rotateY(180deg); }

.hide-button span {
  display: block;
  margin-bottom: -3px; }

.short-name {
  margin: -5px; }
  .short-name .label-tag {
    display: inline-block;
    border: none;
    padding: 3px 12px;
    margin-bottom: 15px;
    border: none;
    margin: 5px;
    background-color: #eff2f7;
    border-radius: 8px; }
    .short-name .label-tag span {
      font-size: 14px; }
    .short-name .label-tag .btn-close {
      font-size: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      margin-left: 5px; }
      [dir="rtl"] .short-name .label-tag .btn-close {
        margin-left: 0;
        margin-right: 5px; }

.select-featured {
  border: 1px solid #eff2f7;
  color: #8f8397;
  margin-left: auto;
  border-radius: 0; }
  .select-featured:focus {
    border-color: #eff2f7;
    box-shadow: 0 0 0 0.25rem transparent; }
  .select-featured .dropdown-toggle {
    border: 1px solid #eff2f7;
    color: #8f8397;
    margin-left: auto;
    border-radius: 0;
    font-weight: 400;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    position: relative; }
    .select-featured .dropdown-toggle::after {
      display: none; }
    .select-featured .dropdown-toggle::before {
      content: "\f107";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 14px; }
  .select-featured .dropdown-menu {
    min-width: 100%; }
    .select-featured .dropdown-menu li {
      width: 100%; }

.custome-offcanvas .offcanvas-body {
  background-color: #fff; }

@media (max-width: 991px) {
  .custome-offcanvas {
    width: 300px; } }

.custome-offcanvas .offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: center; }
  .custome-offcanvas .offcanvas-header h5 {
    margin-top: -5px; }

.custome-offcanvas .offcanvas-body .category-spacing .category-name .accordion-item {
  padding: 0; }
  .custome-offcanvas .offcanvas-body .category-spacing .category-name .accordion-item + .accordion-item {
    margin-top: 10px; }

@media (max-width: 991px) {
  .custome-offcanvas .category-option {
    position: relative;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: unset;
    padding: 0;
    width: unset;
    height: auto;
    transition: all 0.5s ease;
    overflow-y: auto; }
    [dir="rtl"] .custome-offcanvas .category-option {
      left: unset;
      right: 0; } }

.image-slider {
  display: block;
  overflow: hidden; }
  .image-slider .image-product {
    position: relative;
    transition: all 0.3s ease-in-out; }
    .image-slider .image-product img {
      border-radius: 5px; }
  .image-slider .image-contain {
    display: block;
    text-align: center;
    padding: 20px 0 0;
    transition: all 0.3s ease-in-out; }
    .image-slider .image-contain h3 {
      font-weight: 400; }

.filter-name {
  height: 100%;
  display: flex;
  align-items: center; }
  .filter-name h4 {
    font-weight: 500;
    font-size: large; }

.filter-icon {
  display: none; }
  @media (max-width: 991px) {
    .filter-icon {
      display: block; } }

.category-filter {
  width: 100%; }

.category-list {
  width: 100%; }
  .category-list .dropdown .dropdown-toggle {
    width: 100%;
    background-color: rgba(233, 233, 233, 0.5);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))); }
  .category-list .dropdown .dropdown-menu {
    padding: 1rem;
    width: 100%; }
    .category-list .dropdown .dropdown-menu .dropdown-list {
      display: block; }
      .category-list .dropdown .dropdown-menu .dropdown-list + .dropdown-list {
        margin-top: 10px;
        text-overflow: ellipsis;
        white-space: nowrap; }

.filter-show-button {
  padding: 15px;
  display: none; }
  .filter-show-button a {
    display: flex;
    align-items: center; }
    .filter-show-button a .feather {
      margin-right: 10px;
      width: 20px; }
    .filter-show-button a h5 {
      font-weight: 600; }
  @media (max-width: 991px) {
    .filter-show-button {
      padding: 0 0 10px;
      display: flex; } }

.top-filter-section {
  background-color: #fff;
  padding-bottom: 50px; }
  @media (max-width: 991px) {
    .top-filter-section {
      position: fixed;
      left: -300px;
      width: 300px;
      height: 100vh;
      top: 0;
      margin-top: 0;
      padding: 20px;
      z-index: 5;
      transition: all 0.5s ease;
      box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
      overflow-y: auto; } }
  .top-filter-section.show {
    left: 0; }
  .top-filter-section ul {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991px) {
      .top-filter-section ul {
        display: block; } }
    .top-filter-section ul .back-btn {
      display: none; }
      @media (max-width: 991px) {
        .top-filter-section ul .back-btn {
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          text-transform: capitalize;
          color: black;
          font-weight: 700;
          margin-bottom: 20px;
          cursor: pointer;
          display: block; } }
    .top-filter-section ul .onclick-title {
      width: 100%;
      cursor: pointer;
      position: relative;
      background-color: #eff2f7;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      margin: 0 15px; }
      .top-filter-section ul .onclick-title:nth-child(7) {
        margin-right: 0; }
      @media (max-width: 991px) {
        .top-filter-section ul .onclick-title {
          margin: 30px 0 0;
          background-color: #fff;
          padding: 0; }
          .top-filter-section ul .onclick-title:nth-child(3) {
            margin-top: 15px; }
          .top-filter-section ul .onclick-title + .onclick-title {
            margin-left: unset; } }
      .top-filter-section ul .onclick-title h6 {
        white-space: nowrap;
        text-transform: capitalize;
        font-size: 16px;
        text-align: left;
        margin-bottom: 0;
        padding: 0 20px 0 0;
        position: relative; }
        [dir="rtl"] .top-filter-section ul .onclick-title h6 {
          text-align: right;
          padding: 0 0 0 20px; }
        @media (max-width: 991px) {
          .top-filter-section ul .onclick-title h6 {
            text-align: left;
            font-weight: 500; } }
        .top-filter-section ul .onclick-title h6:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f107";
          position: absolute;
          right: 0;
          opacity: 0.2; }
          [dir="rtl"] .top-filter-section ul .onclick-title h6:before {
            right: unset;
            left: 0; }
          @media (max-width: 991px) {
            .top-filter-section ul .onclick-title h6:before {
              content: ""; } }
      .top-filter-section ul .onclick-title:nth-child(-n + 3) {
        border-left: none; }
      .top-filter-section ul .onclick-title .onclick-content {
        display: none;
        position: absolute;
        background-color: #fff;
        padding: 1rem;
        width: 100%;
        left: 0;
        top: 40px;
        box-shadow: 0px 5px 9px 3px rgba(33, 37, 41, 0.13);
        z-index: 2; }
        @media (max-width: 991px) {
          .top-filter-section ul .onclick-title .onclick-content {
            display: block;
            position: relative;
            box-shadow: none;
            padding: 0;
            top: 10px;
            margin-top: 10px; } }
        .top-filter-section ul .onclick-title .onclick-content li {
          display: block;
          cursor: pointer; }
          .top-filter-section ul .onclick-title .onclick-content li + li {
            margin-top: 10px; }
        .top-filter-section ul .onclick-title .onclick-content .custom-control .form-check-label {
          text-transform: capitalize; }
    .top-filter-section ul .filter-title {
      width: 100%;
      cursor: pointer;
      position: relative;
      border-left: 1px solid #e0e0e0;
      min-width: 60px; }
      @media (max-width: 991px) {
        .top-filter-section ul .filter-title {
          margin-bottom: 10px; } }
      .top-filter-section ul .filter-title h6 {
        text-transform: capitalize;
        font-size: 16px;
        margin-bottom: 0;
        padding: 0 20px 0 0;
        position: relative;
        padding: 0;
        font-weight: 700; }
        .top-filter-section ul .filter-title h6:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f107";
          position: absolute;
          right: 15px;
          opacity: 0.2;
          display: none; }
      .top-filter-section ul .filter-title:nth-child(-n + 3) {
        border-left: none; }
      .top-filter-section ul .filter-title .onclick-content {
        display: none;
        position: absolute;
        background-color: white;
        z-index: 1;
        padding: 10px 20px;
        width: 200px;
        left: 30px;
        top: 34px;
        box-shadow: 0px 1px 9px 3px rgba(228, 228, 228, 0.81); }
        @media (max-width: 991px) {
          .top-filter-section ul .filter-title .onclick-content {
            display: block;
            position: relative;
            top: 10px;
            box-shadow: none; } }
        .top-filter-section ul .filter-title .onclick-content .custom-control .form-check-label {
          text-transform: capitalize; }
    .top-filter-section ul .onclick-title.show .onclick-content {
      display: block; }
    .top-filter-section ul .filter-title.show .onclick-content {
      display: block; }
  .top-filter-section .form-check {
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .top-filter-section .form-check + .form-check {
      margin-top: 10px; }
    .top-filter-section .form-check .form-check-label {
      font-weight: 400;
      display: block;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.category-image {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #eff2f7;
  display: flex;
  align-items: center; }
  .category-image .elec-image {
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px; }
    [dir="rtl"] .category-image .elec-image {
      padding-right: 0;
      padding-left: 30px; }
    .category-image .elec-image img {
      width: 90%; }
  .category-image .category-contain {
    color: #212529;
    width: calc(100% - 150px); }
    .category-image .category-contain h3 {
      text-transform: capitalize;
      color: #212529;
      letter-spacing: 1.1px; }
    .category-image .category-contain .product-list {
      margin: 5px 0;
      padding-inline-start: 15px;
      list-style-type: circle; }
      .category-image .category-contain .product-list li {
        display: list-item;
        text-transform: capitalize; }
        .category-image .category-contain .product-list li a {
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden; }

.fliter-menu button {
  font-weight: 300;
  display: inline-block;
  border: none;
  padding: 5px calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 0;
  border: 1px solid #ddd;
  margin: 5px; }
  .fliter-menu button .feather {
    width: 16px;
    stroke-width: 1px; }

.product-box .product-details .main-price p {
  display: none; }

.product-box .product-details .main-price .listing-content {
  display: none; }

.list-view.product-box {
  display: flex;
  align-items: center; }
  .list-view.product-box .img-wrapper .back {
    opacity: 0;
    position: absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    width: 100%; }
  .list-view.product-box .product-details .rating-details,
  .list-view.product-box .product-details .main-price {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 575px) {
      .list-view.product-box .product-details .rating-details,
      .list-view.product-box .product-details .main-price {
        display: block;
        text-align: left; }
        [dir="rtl"] .list-view.product-box .product-details .rating-details, [dir="rtl"]
        .list-view.product-box .product-details .main-price {
          text-align: right; } }
  .list-view.product-box .product-details .main-price {
    margin-top: 10px; }
    @media (max-width: 575px) {
      .list-view.product-box .product-details .main-price {
        margin-top: 5px; }
        .list-view.product-box .product-details .main-price h3 {
          margin-top: 5px; } }
  .list-view.product-box .cart-wrap ul li + li {
    margin-left: 0; }
    [dir="rtl"] .list-view.product-box .cart-wrap ul li + li {
      margin-left: unset;
      margin-right: 0; }
  .list-view.product-box .cart-wrap a svg,
  .list-view.product-box .cart-wrap button svg {
    width: 14px;
    height: 14px; }
    @media (max-width: 575px) {
      .list-view.product-box .cart-wrap a svg,
      .list-view.product-box .cart-wrap button svg {
        width: 13px;
        height: 13px; } }
  .list-view.product-box:hover .img-wrapper .back {
    opacity: 1;
    backface-visibility: visible;
    transition: all 0.3s ease; }

@media (max-width: 1660px) {
  .list-view.slide-4 {
    padding: 0 30px; } }

.list-view.slide-4 .slick-prev {
  left: -70px; }
  @media (max-width: 1660px) {
    .list-view.slide-4 .slick-prev {
      left: -20px; } }
  @media (max-width: 575px) {
    .list-view.slide-4 .slick-prev {
      left: -5px; } }

.list-view.slide-4 .slick-next {
  right: -70px; }
  @media (max-width: 1660px) {
    .list-view.slide-4 .slick-next {
      right: -20px; } }
  @media (max-width: 575px) {
    .list-view.slide-4 .slick-next {
      right: -5px; } }

.collection-grid-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .collection-grid-view ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .collection-grid-view ul li {
      height: 100%;
      margin-right: 8px;
      border: 2px solid #212529; }
      .collection-grid-view ul li button {
        padding: 5px; }
        .collection-grid-view ul li button img {
          height: 50%; }

.range-slider {
  margin-top: 0; }
  .range-slider .irs--flat .irs-line {
    background-color: #eff2f7; }
  .range-slider .irs--flat .irs-max {
    background-color: #c7c7c5;
    color: #212529; }
  .range-slider .irs--flat .irs-bar {
    background-color: var(--theme-color); }
  .range-slider .irs--flat .irs-handle i {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: var(--theme-color); }
  .range-slider .irs--flat .irs-to {
    background-color: var(--theme-color); }
    .range-slider .irs--flat .irs-to:before {
      border-top-color: var(--theme-color); }
  .range-slider .irs--flat .irs-from {
    background-color: var(--theme-color); }
    .range-slider .irs--flat .irs-from:before {
      border-top-color: var(--theme-color); }

.banner-deatils .banner-contain p {
  line-height: 1.8;
  font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))); }

.most-popular {
  margin: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320))) 0 0; }
  .most-popular .title {
    margin-bottom: 25px; }
    [dir="rtl"] .most-popular .title {
      text-align: left !important; }
  .most-popular .product-slider {
    padding-top: 20px;
    border-top: 1px solid #eff2f7; }
    .most-popular .product-slider .slick-prev,
    .most-popular .product-slider .slick-next {
      background-color: #eff2f7;
      position: absolute;
      top: -35px;
      z-index: 1;
      width: 35px;
      height: 35px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .most-popular .product-slider .slick-prev {
      left: unset;
      right: 50px; }
      .most-popular .product-slider .slick-prev::before {
        content: "\f053";
        color: #000;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        z-index: 1;
        font-size: 16px; }
    .most-popular .product-slider .slick-next {
      right: 0; }
      .most-popular .product-slider .slick-next::before {
        content: "\f054";
        color: #000;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        z-index: 1;
        font-size: 16px; }

.list-style > div {
  width: 100%; }

.list-style .product-box {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .list-style .product-box {
      display: block;
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 15px calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 5px; } }
  .list-style .product-box .img-wrapper {
    width: 25%; }
    @media (max-width: 767px) {
      .list-style .product-box .img-wrapper {
        width: 60%;
        margin: 0 auto; } }
    @media (max-width: 575px) {
      .list-style .product-box .img-wrapper {
        width: 100%; } }
  .list-style .product-box .cart-wrap {
    display: none; }
  .list-style .product-box .product-details {
    padding-left: 15px;
    margin-top: 0;
    width: calc(100% - 100px); }
    [dir="rtl"] .list-style .product-box .product-details {
      padding-left: 0;
      padding-right: 15px; }
    @media (max-width: 767px) {
      .list-style .product-box .product-details {
        width: 100%;
        margin-top: 20px;
        padding: 0; } }
    .list-style .product-box .product-details .rating {
      margin: 0; }
    .list-style .product-box .product-details h5 {
      font-weight: 500;
      margin: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))) 0 0 0; }
    .list-style .product-box .product-details span {
      margin-top: 5px; }
    .list-style .product-box .product-details .rating-details {
      text-align: left !important; }
      .list-style .product-box .product-details .rating-details.rating-shop {
        text-align: left; }
        [dir="rtl"] .list-style .product-box .product-details .rating-details.rating-shop {
          text-align: right; }
        .list-style .product-box .product-details .rating-details.rating-shop h5 {
          margin-top: 5px; }
        .list-style .product-box .product-details .rating-details.rating-shop .size-box {
          margin: 5px 0; }
      .list-style .product-box .product-details .rating-details .grid-content {
        display: none; }
    .list-style .product-box .product-details .main-price .listing-content {
      display: block; }
      .list-style .product-box .product-details .main-price .listing-content.listing-shop {
        text-align: left; }
        [dir="rtl"] .list-style .product-box .product-details .main-price .listing-content.listing-shop {
          text-align: right; }
  .list-style .product-box .main-price {
    display: block !important;
    margin-top: 0 !important; }
    .list-style .product-box .main-price h3 {
      padding: 0;
      margin: 0 0 8px; }
    .list-style .product-box .main-price p {
      display: block !important;
      margin: 8px 0;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.6;
      letter-spacing: 0.04em; }
    .list-style .product-box .main-price button {
      position: relative;
      padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      z-index: 1;
      transition: all 0.5s;
      border-radius: 0;
      font-weight: bold;
      background: var(--theme-color);
      font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
      color: #fff;
      border-radius: 5px;
      margin-top: 0; }
      .list-style .product-box .main-price button:hover {
        color: #fff;
        background-color: var(--theme-color); }

.filter-options {
  display: flex;
  align-items: center; }
  .filter-options .select-options {
    display: flex;
    align-items: center; }
    .filter-options .select-options > div + div {
      margin-left: 12px; }
      [dir="rtl"] .filter-options .select-options > div + div {
        margin-left: 0;
        margin-right: 12px; }
  .filter-options .grid-options {
    margin-left: auto; }
    [dir="rtl"] .filter-options .grid-options {
      margin-left: unset;
      margin-right: auto; }
    .filter-options .grid-options.avtive li {
      background-color: var(--theme-color); }
      .filter-options .grid-options.avtive li a img {
        filter: invert(1); }
    .filter-options .grid-options li {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      background-color: #eff2f7;
      opacity: 0.5; }
      .filter-options .grid-options li.active {
        opacity: 1; }
      .filter-options .grid-options li a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; }
      .filter-options .grid-options li + li {
        margin-left: 8px; }
        [dir="rtl"] .filter-options .grid-options li + li {
          margin-left: 0;
          margin-right: 8px; }

.category-side {
  transition: .3s ease; }
  .category-side.show {
    transition: .3s ease;
    width: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden; }
  .category-side .category-option {
    background-color: #ddd; }
    @media (max-width: 991px) {
      .category-side .category-option {
        background-color: #eff2f7; } }
    .category-side .category-option .custome-form-check .form-check-input {
      background-color: #fff; }
    .category-side .category-option .category-name .accordion-button {
      background-color: #fff;
      border: none; }
    .category-side .category-option .category-name .accordion-item {
      background-color: #eff2f7;
      border: none; }
      @media (max-width: 991px) {
        .category-side .category-option .category-name .accordion-item {
          padding: 20px 0; } }
      .category-side .category-option .category-name .accordion-item + .accordion-item {
        margin-top: 0px; }

/**=====================
     Product Page CSS Start
==========================**/
.pro-popular .product-slider .slick-prev {
  background-color: #ddd !important; }

.pro-popular .product-slider .slick-next {
  background-color: #ddd !important; }

.rating .fa-star {
  padding-right: 1px !important;
  font-size: 16px !important;
  color: #c3c3c3 !important; }
  .rating .fa-star.theme-color {
    color: #ffa202 !important; }

.product-title {
  color: #212529;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px; }
  .product-title-2 {
    margin-top: 18px; }
  .product-title.hurry-title {
    justify-content: unset;
    font-weight: 400; }
    .product-title.hurry-title span {
      margin: 0 5px;
      color: var(--theme-color); }

.shipping-info {
  margin-top: 10px; }
  .shipping-info li {
    display: block;
    font-size: 16px;
    color: #777777;
    line-height: 1.5;
    padding-bottom: 5px; }

.product-icon {
  display: flex; }
  @media (max-width: 767px) {
    .product-icon {
      justify-content: center; } }
  .product-icon .product-social {
    margin-top: 5px; }
    .product-icon .product-social li {
      padding-right: 30px; }
      .product-icon .product-social li:last-child {
        padding-right: 0; }
      [dir="rtl"] .product-icon .product-social li {
        padding-right: 0;
        padding-left: 30px; }
      .product-icon .product-social li a {
        color: #212529;
        transition: all 0.3s ease;
        font-size: 16px; }

.added-notification {
  width: 180px;
  position: fixed;
  top: -100px;
  right: 30px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 3px;
  padding: 6px;
  overflow: hidden;
  box-shadow: 0 0 2px 1px #ced4da;
  transition: all 0.5s ease;
  z-index: 9;
  background-color: #fff; }
  @media (max-width: 575px) {
    .added-notification {
      right: 12px; } }
  .added-notification.show {
    top: 30px;
    transition: all 0.5s ease; }
  .added-notification img {
    width: 25%; }
  .added-notification h3 {
    color: #212529;
    text-transform: capitalize;
    font-size: 16px;
    margin: 0 10px;
    font-weight: 400;
    white-space: nowrap; }

.addeffect-section {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #ddd; }
  .addeffect-section.cartMove .error-message {
    display: block; }
  .addeffect-section.cartMove .size-box {
    animation: buttons-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; }
  .addeffect-section .size-box {
    margin: 10px 0; }
    .addeffect-section .size-box ul li {
      height: 35px;
      width: 35px;
      border-radius: 5px;
      margin-right: 10px;
      cursor: pointer;
      background-color: #eff2f7;
      border: 1px solid #eff2f7;
      text-align: center;
      transition: all 0.2s ease-in-out; }
      [dir="rtl"] .addeffect-section .size-box ul li {
        margin-right: 0;
        margin-left: 10px; }
      .addeffect-section .size-box ul li.active {
        background: #212529; }
        .addeffect-section .size-box ul li.active a {
          color: #fff; }
      .addeffect-section .size-box ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #212529;
        font-weight: 400; }
  .addeffect-section .error-message {
    display: none;
    color: var(--theme-color);
    font-weight: 500;
    margin: 10px 0; }
  @media (max-width: 767px) {
    .addeffect-section .qty-box .input-group {
      justify-content: center; } }
  .addeffect-section .qty-box .input-group span button {
    border: 1px solid #ced4da;
    height: 100%; }
  .addeffect-section .qty-box .input-group .form-control {
    text-align: center;
    width: 80px;
    flex: unset; }
  .addeffect-section .qty-box .input-group input {
    padding: 12px 10px; }
  .addeffect-section .qty-box .input-group button {
    background-color: transparent;
    border: 0;
    color: #212529;
    cursor: pointer;
    font-size: 12px;
    font-weight: 900;
    line-height: 1;
    padding: 15px 20px; }
    .addeffect-section .qty-box .input-group button i {
      font-weight: 900;
      color: #212529; }
  .addeffect-section .qty-box .input-group .icon {
    padding-right: 0; }

.product-buttons {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .product-buttons {
      justify-content: center; } }
  @media (max-width: 575px) {
    .product-buttons {
      display: flex;
      align-items: center;
      justify-content: center; } }
  .product-buttons .btn-solid {
    background-color: var(--theme-color);
    color: #fff;
    padding: 12px 45px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .product-buttons .btn-solid + .btn-solid {
      margin-left: 8px; }
      [dir="rtl"] .product-buttons .btn-solid + .btn-solid {
        margin-right: 8px;
        margin-left: unset; }
    @media (max-width: 991px) {
      .product-buttons .btn-solid {
        padding: 12px 30px; } }
    @media (max-width: 575px) {
      .product-buttons .btn-solid {
        width: 100%; } }
    @media (max-width: 480px) {
      .product-buttons .btn-solid {
        padding: 10px 20px;
        font-size: 14px; } }
    .product-buttons .btn-solid i {
      font-size: 17px;
      margin-right: 5px; }
      [dir="rtl"] .product-buttons .btn-solid i {
        margin-right: unset;
        margin-left: 5px; }
    .product-buttons .btn-solid span {
      font-weight: 500; }
    .product-buttons .btn-solid.btn-animation i {
      animation: ring 4s 0.7s ease-in-out infinite; }

.shipping-order li {
  display: flex;
  align-items: center;
  text-align: left; }
  @media (max-width: 767px) {
    .shipping-order li {
      justify-content: center; } }
  .shipping-order li img {
    margin-right: 18px; }

.product-count {
  background-color: #eff2f7;
  padding: 10px 12px !important;
  margin-bottom: 20px; }
  .product-count img {
    margin-right: 6px; }
    [dir="rtl"] .product-count img {
      margin-right: 0;
      margin-left: 6px; }
  .product-count ul {
    margin-bottom: -4px; }
    .product-count ul li {
      margin-bottom: 4px; }
      .product-count ul li:first-child {
        margin-right: 14px; }
        [dir="rtl"] .product-count ul li:first-child {
          margin-right: 0;
          margin-left: 14px; }

.rating-section {
  margin-bottom: 15px;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .rating-section {
      justify-content: center; } }
  .rating-section h6 {
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px; }
    [dir="rtl"] .rating-section h6 {
      margin-left: 0;
      margin-right: 10px; }

.color-variant {
  margin-bottom: 10px; }
  .color-variant li {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
    overflow: hidden;
    transition: all .3s ease; }
    .color-variant li + li {
      margin-left: 0; }
    .color-variant li:nth-child(1) {
      background-color: #f1e7e6; }
    .color-variant li:nth-child(3) {
      background-color: #bfbfbf; }
    .color-variant li.active {
      position: relative; }
      .color-variant li.active:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        top: 10px;
        height: 100%;
        right: 4px;
        height: 15px;
        width: 15px;
        background-size: 70%;
        background-repeat: no-repeat;
        position: absolute;
        opacity: 0.8; }

.price-detail {
  font-size: 26px;
  color: #212529;
  font-weight: 400;
  margin: 15px 0; }
  .price-detail del {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 400; }
  .price-detail span {
    font-size: 16px;
    color: var(--theme-color);
    padding-left: 10px; }
    [dir="rtl"] .price-detail span {
      padding-left: 0;
      padding-right: 10px; }

.label-section {
  margin: 10px 0; }
  .label-section .badge-grey-color {
    padding: 6px 11px;
    font-size: 12px;
    background: #929292; }
  .label-section .label-text {
    text-transform: capitalize;
    padding-left: 5px; }
    [dir="rtl"] .label-section .label-text {
      padding-left: 0;
      padding-right: 5px; }

.service-accorion {
  padding: 28px; }
  .service-accorion .accordion-collapse {
    margin-top: 0 !important; }
    .service-accorion .accordion-collapse .accordion-body .category-list li {
      margin: 0 !important; }
      .service-accorion .accordion-collapse .accordion-body .category-list li + li {
        margin-top: 30px !important; }
      .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap {
        display: flex;
        align-items: center; }
        .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-icon svg {
          width: 30px;
          height: 30px;
          margin-right: 16px;
          fill: var(--theme-color);
          stroke: var(--theme-color);
          font-weight: 300; }
          [dir="rtl"] .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-icon svg {
            margin-right: 0;
            margin-left: 16px; }
          @media (max-width: 767px) {
            .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-icon svg {
              width: 35px;
              height: 35px; } }
        .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-content h3 {
          margin-top: -1px;
          font-size: 15px;
          font-weight: 400; }
          @media (max-width: 1199px) {
            .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-content h3 {
              margin-bottom: 2px !important; } }
        .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-content span {
          display: block;
          margin-bottom: -3px; }
          @media (max-width: 991px) {
            .service-accorion .accordion-collapse .accordion-body .category-list li .service-wrap .service-content span {
              font-size: 13px; } }

.details-items .color-image {
  margin: 20px 0;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .details-items .color-image {
      justify-content: center; } }
  .details-items .color-image .image-select {
    display: flex;
    align-items: center; }
    .details-items .color-image .image-select h5 {
      white-space: nowrap; }
    .details-items .color-image .image-select .image-section {
      width: calc(100% - 30px);
      margin-left: 10px;
      flex-wrap: nowrap;
      display: flex;
      align-items: center; }
      [dir="rtl"] .details-items .color-image .image-select .image-section {
        margin-left: 0;
        margin-right: 10px; }
      .details-items .color-image .image-select .image-section li {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 5px;
        transition: all 0.5s ease-in-out; }
        .details-items .color-image .image-select .image-section li.active {
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4); }

.details-items .details-image {
  position: relative; }
  .details-items .details-image .product-image-tag {
    position: relative; }
    .details-items .details-image .product-image-tag img {
      border-radius: 5px;
      height: 100%;
      position: relative; }
    .details-items .details-image .product-image-tag .label-tag {
      position: absolute;
      bottom: 10px;
      right: 0;
      border-radius: 50px 0 0 50px;
      background-color: #fff;
      display: none; }
      @media (max-width: 575px) {
        .details-items .details-image .product-image-tag .label-tag {
          display: block; } }
      .details-items .details-image .product-image-tag .label-tag h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 10px;
        font-weight: 500;
        font-size: 12px; }
        .details-items .details-image .product-image-tag .label-tag h6 i {
          font-size: 10px;
          margin: -1px 2px 0 0;
          color: var(--theme-color); }
        .details-items .details-image .product-image-tag .label-tag h6 span {
          font-weight: 400;
          border-left: 1px solid #ddd;
          padding-left: 6px;
          margin-left: 6px; }

.details-items .degree-section {
  position: relative; }
  .details-items .degree-section .image-360 {
    position: absolute;
    top: 20px;
    right: 20px;
    filter: brightness(0.5);
    z-index: 1;
    cursor: pointer; }
    .details-items .degree-section .image-360.videoplay-box {
      filter: contrast(0.5); }

.details-items .details-image-option .play-icon {
  position: relative; }
  .details-items .details-image-option .play-icon .fa-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #212529;
    border: 2px solid #212529;
    padding: 15px;
    border-radius: 50%;
    font-size: 18px; }

.details-items .cloth-details-size {
  position: sticky;
  top: 10px; }
  @media (max-width: 767px) {
    .details-items .cloth-details-size {
      text-align: center; } }
  .details-items .cloth-details-size .details-image-concept {
    margin: 15px 0; }
    .details-items .cloth-details-size .details-image-concept h2 {
      text-transform: uppercase;
      font-size: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.2em;
      color: #212529; }
  .details-items .cloth-details-size .rating-number {
    display: inline-block;
    padding: 10px 0 0 0;
    color: #212529; }
    .details-items .cloth-details-size .rating-number h5 {
      font-size: 15px;
      margin: 0;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1); }
      .details-items .cloth-details-size .rating-number h5 .feather {
        width: 15px;
        margin: 0 2px;
        margin-top: -3px;
        fill: #ffa202;
        color: #ffa202; }
      .details-items .cloth-details-size .rating-number h5 span {
        font-weight: 300;
        color: #212529; }
  .details-items .cloth-details-size .price-rating {
    padding: 0;
    margin-bottom: 0; }
    .details-items .cloth-details-size .price-rating li {
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      color: var(--theme-color);
      text-decoration: none;
      font-weight: 600;
      font-size: 20px; }
      .details-items .cloth-details-size .price-rating li:nth-child(2) {
        color: #696969;
        text-decoration: line-through;
        font-weight: 300;
        font-size: 15px; }
      .details-items .cloth-details-size .price-rating li:nth-child(3) {
        color: #009c00;
        font-weight: 600; }
  .details-items .cloth-details-size .border-product {
    padding: 20px 0;
    border-top: 1px dashed #ddd; }
    .details-items .cloth-details-size .border-product img {
      width: 100%;
      max-width: 75%; }
      @media (max-width: 767px) {
        .details-items .cloth-details-size .border-product img {
          width: 100%;
          max-width: 100%; } }
  .details-items .cloth-details-size .timer-5 {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px 0; }
    .details-items .cloth-details-size .timer-5 h5 {
      display: inline-block;
      margin: 5px; }
    .details-items .cloth-details-size .timer-5 .timer1 {
      padding: 0;
      width: auto;
      display: inline-block; }
      .details-items .cloth-details-size .timer-5 .timer1 .counter {
        display: inline-block; }
        .details-items .cloth-details-size .timer-5 .timer1 .counter h5 {
          display: inline-block;
          color: #212529; }
  .details-items .cloth-details-size .progress {
    background-color: #ddd;
    height: 5px;
    width: 70%; }
    @media (max-width: 1199px) {
      .details-items .cloth-details-size .progress {
        width: 75%; } }
    @media (max-width: 767px) {
      .details-items .cloth-details-size .progress {
        width: 100%; } }
    .details-items .cloth-details-size .progress .progress-bar {
      background-color: var(--theme-color); }
  .details-items .cloth-details-size .image-bundle .bundle-img {
    display: flex;
    align-items: center; }
    .details-items .cloth-details-size .image-bundle .bundle-img .image-contain a img {
      max-width: 115px;
      box-shadow: 0 0 8px rgba(33, 37, 41, 0.15); }
    .details-items .cloth-details-size .image-bundle .bundle-img span {
      margin: 0 15px; }
  .details-items .cloth-details-size .image-bundle .checkbox-details {
    margin-top: 18px; }
    .details-items .cloth-details-size .image-bundle .checkbox-details .checkbox-list {
      display: block;
      margin-bottom: 10px; }
  .details-items .cloth-details-size .cloth-size h5 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 4px; }
  .details-items .cloth-details-size .cloth-size .choose-size {
    padding: 0;
    display: flex;
    margin: -10px; }
    .details-items .cloth-details-size .cloth-size .choose-size li {
      list-style: none;
      border-radius: 5px;
      font-size: 15px;
      border: 1px solid rgba(33, 37, 41, 0.2);
      margin: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .details-items .cloth-details-size .cloth-size .choose-size li a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #212529;
        text-decoration: none;
        width: 40px;
        height: 40px;
        font-size: 13px; }
      .details-items .cloth-details-size .cloth-size .choose-size li:hover a {
        font-weight: bold; }
  .details-items .cloth-details-size .cloth-timer {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    background-color: #eaeaea; }
    .details-items .cloth-details-size .cloth-timer h4 {
      font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300))); }
    .details-items .cloth-details-size .cloth-timer #time-date {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      bottom: 0;
      right: 0;
      font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1600 - 300)));
      display: inline-block;
      margin: 0;
      font-weight: 500; }
  .details-items .cloth-details-size .cloth-color .dropdown-color {
    border-radius: 5px;
    padding: 9px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    font-weight: 400;
    background-color: white; }
    .details-items .cloth-details-size .cloth-color .dropdown-color:focus-visible {
      outline: none; }
    .details-items .cloth-details-size .cloth-color .dropdown-color option {
      color: #212529; }
  .details-items .cloth-details-size .cloth-color .counter-1 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .details-items .cloth-details-size .cloth-color .counter-1 input {
      width: 100px;
      height: 40px;
      border: 0;
      line-height: 30px;
      font-size: 20px;
      text-align: center;
      background: #fff;
      margin: 0px 5px;
      color: #212529;
      appearance: none;
      outline: 0; }
    .details-items .cloth-details-size .cloth-color .counter-1 span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      user-select: none;
      background-color: #696969;
      border-radius: 5px; }
      .details-items .cloth-details-size .cloth-color .counter-1 span .feather {
        color: #fff;
        width: 20px;
        stroke-width: 2.5px; }
  .details-items .cloth-details-size .cloth-color .wise-list {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    border: 1px solid var(--theme-color);
    transition: all 0.5s ease-in-out; }
    .details-items .cloth-details-size .cloth-color .wise-list:hover {
      border: 1px solid var(--theme-color);
      background-color: var(--theme-color); }
      .details-items .cloth-details-size .cloth-color .wise-list:hover .feather {
        fill: var(--theme-color);
        color: white;
        stroke-width: 3px; }
    .details-items .cloth-details-size .cloth-color .wise-list .feather {
      width: 20px;
      transition: all 0.5s ease-in-out; }
  .details-items .cloth-details-size .cart-button .buy-cart {
    background-color: var(--theme-color);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1600 - 320)));
    border: none;
    padding: 0; }
    .details-items .cloth-details-size .cart-button .buy-cart .fa-shopping-cart {
      margin-top: -1px; }
  .details-items .cloth-details-size .cart-button .add-cart {
    background-color: #fff;
    width: 100%;
    height: calc(40px + (50 - 40) * ((100vw - 320px) / (1600 - 320)));
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1600 - 320)));
    padding: 0; }
    .details-items .cloth-details-size .cart-button .add-cart h6 {
      font-weight: 400; }
  .details-items .cloth-details-size .pay-option {
    padding: 0;
    margin: 0;
    display: flex; }
    .details-items .cloth-details-size .pay-option li {
      list-style: none;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .details-items .cloth-details-size .pay-option li a {
        color: gray;
        font-size: 30px;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none; }
  .details-items .cloth-details-size .share-it {
    display: flex;
    align-content: center;
    position: absolute;
    top: 0;
    right: 0; }
    .details-items .cloth-details-size .share-it button {
      display: inline-block;
      color: #c6c9cc;
      cursor: pointer;
      background-color: #ececec;
      border: none;
      padding: 6px 12px;
      border-radius: 4px; }
      .details-items .cloth-details-size .share-it button .feather {
        width: 18px;
        color: #212529; }
    .details-items .cloth-details-size .share-it .share-option {
      padding: 0; }
      .details-items .cloth-details-size .share-it .share-option li {
        list-style: none;
        margin-right: 20px; }
        .details-items .cloth-details-size .share-it .share-option li a {
          color: #212529;
          font-size: 20px; }
          .details-items .cloth-details-size .share-it .share-option li a:hover {
            color: #696969; }

.cloth-review .nav-tabs {
  flex-wrap: initial;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid #ced4da; }
  .cloth-review .nav-tabs .nav-link {
    color: #212529;
    border-bottom: 4px solid transparent;
    padding: 15px 20px;
    font-size: 19px;
    font-weight: 500;
    margin-right: 20px;
    white-space: nowrap; }
    [dir="rtl"] .cloth-review .nav-tabs .nav-link {
      margin-right: 0;
      margin-left: 20px; }
    @media (max-width: 575px) {
      .cloth-review .nav-tabs .nav-link {
        margin-right: 0; } }
    .cloth-review .nav-tabs .nav-link:hover {
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      border-bottom: 4px solid var(--theme-color);
      transition: all 0.5s ease; }
    .cloth-review .nav-tabs .nav-link.active {
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      border-bottom: 4px solid var(--theme-color);
      transition: all 0.5s ease;
      color: #212529;
      background-color: #fff; }

.cloth-review .shipping-chart {
  padding-bottom: 5px;
  padding: 0; }
  .cloth-review .shipping-chart li {
    font-size: 15px;
    list-style: none;
    color: #696969;
    padding: 0;
    margin: 10px 0;
    display: block; }

.cloth-review .tab-pane {
  padding-top: 30px; }
  .cloth-review .tab-pane .customer-review-box h4 {
    margin-bottom: 0px; }
  .cloth-review .tab-pane .customer-review-box .customer-section {
    border-bottom: 1px solid rgba(206, 212, 218, 0.7);
    display: flex;
    align-items: flex-start;
    padding: 30px 0; }
    .cloth-review .tab-pane .customer-review-box .customer-section .customer-profile {
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 28px; }
      [dir="rtl"] .cloth-review .tab-pane .customer-review-box .customer-section .customer-profile {
        margin-right: 0;
        margin-left: 28px; }
    .cloth-review .tab-pane .customer-review-box .customer-section .customer-details {
      width: calc(100% - 30px); }
      .cloth-review .tab-pane .customer-review-box .customer-section .customer-details h5 {
        font-weight: 500; }
      .cloth-review .tab-pane .customer-review-box .customer-section .customer-details p {
        line-height: 1.4; }
      .cloth-review .tab-pane .customer-review-box .customer-section .customer-details .date-custo {
        margin-top: 15px;
        font-size: 14px; }
  .cloth-review .tab-pane .customer-rating .rating-progess {
    margin-top: 21px; }
    .cloth-review .tab-pane .customer-rating .rating-progess li {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .cloth-review .tab-pane .customer-rating .rating-progess li .progress {
        width: 100%;
        border-radius: 8px;
        height: 12px; }
        .cloth-review .tab-pane .customer-rating .rating-progess li .progress .progress-bar {
          background-color: #ffa202; }
      .cloth-review .tab-pane .customer-rating .rating-progess li h5 {
        margin: 0;
        white-space: nowrap;
        color: #212529;
        font-size: 14px; }
  .cloth-review .tab-pane .table-pane tr.bg-color {
    background-color: #eff2f7; }
  .cloth-review .tab-pane .pro .table-part {
    margin-top: 25px; }
    .cloth-review .tab-pane .pro .table-part tr {
      color: #7e7e7e; }
      .cloth-review .tab-pane .pro .table-part tr th {
        border: 1px solid #ced4da;
        background-color: #eff2f7;
        font-weight: 500;
        padding: 1.5rem !important;
        white-space: nowrap; }
      .cloth-review .tab-pane .pro .table-part tr td {
        border: 1px solid #ced4da;
        text-align: left;
        padding: 1.5rem !important;
        white-space: nowrap; }
        [dir="rtl"] .cloth-review .tab-pane .pro .table-part tr td {
          text-align: right; }
  .cloth-review .tab-pane .question-answer ul li {
    margin-bottom: 26px;
    padding-bottom: 26px;
    border-bottom: 1px solid #ced4da; }
    .cloth-review .tab-pane .question-answer ul li .que {
      font-weight: 500;
      display: flex;
      align-items: flex-start;
      margin-bottom: 0; }
      .cloth-review .tab-pane .question-answer ul li .que .fa-question {
        margin-right: 15px;
        font-size: 19px;
        color: var(--theme-color);
        width: 38px;
        height: 38px;
        border-radius: 100%;
        border: 1px solid #ced4da;
        display: flex;
        align-items: center;
        justify-content: center; }
        [dir="rtl"] .cloth-review .tab-pane .question-answer ul li .que .fa-question {
          margin-right: 0;
          margin-left: 15px; }
      .cloth-review .tab-pane .question-answer ul li .que .que-details {
        width: calc(100% - 30px); }
        .cloth-review .tab-pane .question-answer ul li .que .que-details h6 {
          font-weight: 400;
          text-transform: capitalize;
          letter-spacing: 0.9px;
          display: block;
          width: 100%;
          color: #212529;
          font-size: 16px;
          line-height: 1.5; }
        .cloth-review .tab-pane .question-answer ul li .que .que-details p {
          margin-bottom: 0;
          line-height: 1.6;
          letter-spacing: 0.05em;
          font-size: 14px;
          margin-top: 15px;
          font-weight: 300;
          color: #7e7e7e; }
  .cloth-review .tab-pane .speci-list {
    display: block;
    margin-bottom: 28px; }
    .cloth-review .tab-pane .speci-list h6 {
      font-weight: 500; }
      .cloth-review .tab-pane .speci-list h6 span {
        font-weight: 400; }
  .cloth-review .tab-pane p {
    margin-bottom: 0;
    line-height: 1.8;
    letter-spacing: 0.05em;
    font-size: 14px; }
  .cloth-review .tab-pane .submit-button {
    background-color: var(--theme-color);
    color: #fff;
    font-weight: bold; }
  .cloth-review .tab-pane table tbody tr th {
    padding: 1.2rem 0.5rem !important;
    font-weight: 400;
    white-space: nowrap; }
  .cloth-review .tab-pane table tbody tr td {
    padding: 1.2rem 0.5rem;
    white-space: nowrap;
    text-align: center; }

.cloth-review .review-box .form-control:focus {
  border-color: #eff3f7 !important;
  box-shadow: none; }

.part {
  margin-bottom: calc(0px + (20 - 0) * ((100vw - 320px) / (1920 - 320))); }
  .part .inner-title {
    font-weight: 400;
    text-transform: capitalize;
    font-size: 18px; }

.custome-modal .modal-content {
  border-radius: 10px; }
  .custome-modal .modal-content .modal-header {
    padding: 0; }
    .custome-modal .modal-content .modal-header button {
      position: absolute;
      top: 10px;
      right: 10px;
      margin: 0;
      z-index: 1;
      opacity: 1;
      color: #fff;
      display: flex; }
  .custome-modal .modal-content .modal-body {
    display: flex;
    align-content: center; }
    .custome-modal .modal-content .modal-body .share-it {
      padding: 0;
      margin: 0; }
      .custome-modal .modal-content .modal-body .share-it li {
        list-style: none;
        display: block; }
        .custome-modal .modal-content .modal-body .share-it li + li {
          margin-top: 30px; }
        .custome-modal .modal-content .modal-body .share-it li a {
          text-decoration: none;
          color: #212529;
          display: flex;
          align-content: center; }
          .custome-modal .modal-content .modal-body .share-it li a p {
            margin-bottom: 0; }
          .custome-modal .modal-content .modal-body .share-it li a i {
            margin-right: 10px;
            stroke-width: 1px;
            display: flex;
            line-height: 1.5;
            align-content: center; }

.recently-purchase {
  background: #fff;
  max-width: 400px;
  border: 0;
  border-radius: 0;
  bottom: -200px;
  left: 10px;
  padding: 12px;
  position: fixed;
  text-align: left;
  width: auto;
  z-index: 7;
  box-shadow: 0 0 4px 0 rgba(33, 37, 41, 0.2);
  padding: 5px;
  display: flex;
  align-items: center;
  transition: all 0.6s ease; }
  [dir="rtl"] .recently-purchase {
    left: unset;
    right: 10px; }
  @media (max-width: 360px) {
    .recently-purchase {
      left: 0;
      margin: 0 13px; } }
  .recently-purchase.show {
    bottom: 10px;
    transition: all 0.6s ease; }
    @media (max-width: 767px) {
      .recently-purchase.show {
        bottom: 80px; } }
    @media (max-width: 480px) {
      .recently-purchase.show {
        bottom: 64px; } }
  .recently-purchase img {
    width: 22%; }
  .recently-purchase .close-popup {
    position: absolute;
    right: 7px;
    top: 7px;
    opacity: 0.5;
    color: #212529; }
    [dir="rtl"] .recently-purchase .close-popup {
      right: unset;
      left: 7px; }
  .recently-purchase .media-body {
    padding: 0 20px; }
    [dir="rtl"] .recently-purchase .media-body {
      text-align: right; }
    .recently-purchase .media-body h4 {
      font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500; }
    .recently-purchase .media-body .title {
      font-weight: 600; }
    .recently-purchase .media-body .product-name {
      color: var(--theme-color);
      font-size: 12px;
      display: block;
      margin: 2px 0; }
    .recently-purchase .media-body .timeAgo {
      display: block; }

.stickyCart {
  padding-bottom: 94px; }
  @media (max-width: 575px) {
    .stickyCart {
      padding-bottom: 0; } }
  .stickyCart .sticky-bottom-cart {
    bottom: 0;
    transition: all 0.5s ease; }
  .stickyCart .recently-purchase.show {
    bottom: 110px; }
  .stickyCart .tap-top {
    bottom: 110px; }

.outline-button {
  padding: 12px 45px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600; }
  @media (max-width: 767px) {
    .outline-button {
      padding: 12px; } }

.sticky-bottom-cart {
  position: fixed;
  bottom: -160px;
  width: 100vw;
  z-index: 9;
  background-color: #fff;
  padding: 12px 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.16);
  transition: all 0.5s ease; }
  .sticky-bottom-cart .cart-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 575px) {
      .sticky-bottom-cart .cart-content {
        display: block; } }
  .sticky-bottom-cart .product-image {
    display: flex;
    align-items: center; }
    @media (max-width: 575px) {
      .sticky-bottom-cart .product-image {
        display: none; } }
    .sticky-bottom-cart .product-image img {
      width: 60px;
      height: 70px;
      object-fit: cover;
      object-position: top; }
    .sticky-bottom-cart .product-image .content {
      margin-left: 12px;
      color: #212529;
      margin-top: 0; }
      [dir="rtl"] .sticky-bottom-cart .product-image .content {
        margin-left: 0;
        margin-right: 12px; }
      @media (max-width: 991px) {
        .sticky-bottom-cart .product-image .content {
          display: none; } }
      @media (max-width: 480px) {
        .sticky-bottom-cart .product-image .content {
          display: block; } }
      .sticky-bottom-cart .product-image .content h5,
      .sticky-bottom-cart .product-image .content h6 {
        margin-bottom: 0; }
      .sticky-bottom-cart .product-image .content h5 {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize; }
      .sticky-bottom-cart .product-image .content h6 {
        font-size: 15px;
        margin-top: 3px; }
        .sticky-bottom-cart .product-image .content h6 del {
          margin-left: 7px; }
        .sticky-bottom-cart .product-image .content h6 span {
          color: var(--theme-deafult);
          margin-left: 5px; }
          [dir="rtl"] .sticky-bottom-cart .product-image .content h6 span {
            margin-left: 0;
            margin-right: 5px; }
  .sticky-bottom-cart .selection-section {
    display: flex;
    align-items: center;
    margin: 0 10px; }
    @media (max-width: 575px) {
      .sticky-bottom-cart .selection-section {
        display: none; } }
    .sticky-bottom-cart .selection-section .form-control {
      background-color: #eff2f7;
      border: 1px solid #eff2f7;
      text-transform: capitalize; }
      .sticky-bottom-cart .selection-section .form-control:focus {
        box-shadow: none; }
    .sticky-bottom-cart .selection-section .form-group {
      width: 300px;
      margin-left: 10px;
      margin-right: 10px; }
      @media (max-width: 1199px) {
        .sticky-bottom-cart .selection-section .form-group {
          width: 190px; } }
      @media (max-width: 767px) {
        .sticky-bottom-cart .selection-section .form-group {
          margin-right: 0;
          width: unset; } }
  @media (max-width: 575px) {
    .sticky-bottom-cart .add-btn {
      display: flex;
      align-items: center;
      justify-content: center; } }
  @media (max-width: 575px) {
    .sticky-bottom-cart .add-btn a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .sticky-bottom-cart .add-btn a.wishlist-btn {
    display: none;
    background-color: #eff2f7;
    color: #212529; }
    .sticky-bottom-cart .add-btn a.wishlist-btn:hover {
      background-color: var(--theme-color);
      border-color: var(--theme-color);
      color: #fff; }
    @media (max-width: 575px) {
      .sticky-bottom-cart .add-btn a.wishlist-btn {
        display: flex; } }
  @media (max-width: 575px) {
    .sticky-bottom-cart .add-btn a + a {
      margin-left: 8px; }
      [dir="rtl"] .sticky-bottom-cart .add-btn a + a {
        margin-right: 8px;
        margin-left: unset; } }
  .sticky-bottom-cart .add-btn a i {
    margin-right: 7px; }
    [dir="rtl"] .sticky-bottom-cart .add-btn a i {
      margin-right: unset;
      margin-left: 7px; }

.product-image {
  border-radius: 7px;
  display: flex;
  align-items: center; }
  .product-image:hover .product-details .cart-wrap {
    opacity: 1; }
    .product-image:hover .product-details .cart-wrap ul li {
      opacity: 1;
      transform: translateY(0); }
      .product-image:hover .product-details .cart-wrap ul li:nth-child(2) {
        transition-delay: 0.1s; }
      .product-image:hover .product-details .cart-wrap ul li:nth-child(3) {
        transition-delay: 0.2s; }
      .product-image:hover .product-details .cart-wrap ul li:nth-child(4) {
        transition-delay: 0.3s; }
      .product-image:hover .product-details .cart-wrap ul li:nth-child(5) {
        transition-delay: 0.4s; }
  [dir="rtl"] .product-image {
    direction: rtl; }
  .product-image img {
    width: 80px;
    height: 90px;
    object-fit: cover; }
  .product-image .product-details {
    margin-left: 15px;
    overflow: hidden;
    position: relative; }
    .product-image .product-details .cart-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      left: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding-left: 20px;
      opacity: 0;
      transition: .4s ease; }
      [dir="rtl"] .product-image .product-details .cart-wrap {
        margin-left: unset;
        padding-right: 20px; }
      .product-image .product-details .cart-wrap ul li {
        opacity: 0;
        transform: translateY(40px);
        transition: .4s ease; }
        .product-image .product-details .cart-wrap ul li + li {
          margin-left: 10px; }
          [dir="rtl"] .product-image .product-details .cart-wrap ul li + li {
            margin-left: unset;
            margin-right: 10px; }
        .product-image .product-details .cart-wrap ul li a .feather {
          width: 20px; }
    [dir="rtl"] .product-image .product-details {
      margin-left: 0;
      margin-right: 15px; }
    .product-image .product-details a {
      color: #212529; }
      .product-image .product-details a h3 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 4px 0 0; }
    .product-image .product-details h4 {
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320))); }
      .product-image .product-details h4 span {
        margin-left: 10px; }
        [dir="rtl"] .product-image .product-details h4 span {
          margin-left: unset;
          margin-right: 10px; }
  .product-image + .product-image {
    margin-top: 30px; }

.wrapper {
  text-align: center;
  color: #696969;
  overflow: hidden; }
  .wrapper #threesixty {
    margin: 0 auto;
    user-select: none; }
  .wrapper .buttons-wrapper {
    max-width: 120px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
    .wrapper .buttons-wrapper .button {
      position: relative;
      border: none;
      width: 45px;
      cursor: pointer;
      height: 45px;
      transition: all 0.3s ease-in-out; }
      .wrapper .buttons-wrapper .button:hover {
        background-color: #212529;
        color: #fff; }

.compare-table tbody tr th,
.compare-table tbody tr td {
  padding: 12px 20px;
  font-weight: 400; }

.compare-table tbody tr td p {
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.9px;
  margin-bottom: 0; }

.compare-table tbody tr td.table-image-section {
  display: flex;
  align-items: center; }
  .compare-table tbody tr td.table-image-section img {
    width: 100%;
    max-width: 250px; }
  .compare-table tbody tr td.table-image-section .image-details {
    margin-left: 20px;
    width: 100%; }
    .compare-table tbody tr td.table-image-section .image-details h5 {
      margin: 8px 0; }
    .compare-table tbody tr td.table-image-section .image-details p {
      line-height: 10px;
      margin-bottom: 0; }
    .compare-table tbody tr td.table-image-section .image-details .btn-solid {
      background-color: var(--theme-color);
      color: #fff;
      padding: 7px 25px; }

.compare-table tbody tr td.product-description {
  width: 500px; }

@keyframes rounded {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes run-shoes {
  0% {
    transform: rotate(0deg) translate(0, 0); }
  50% {
    transform: rotate(5deg);
    transform-origin: left; }
  75% {
    transform: rotate(-5deg);
    transform-origin: right; }
  100% {
    transform: rotate(0deg) translate(0, 0); } }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(239, 243, 247, 0.3), 0 0 0 1em rgba(239, 243, 247, 0.3); }
  100% {
    box-shadow: 0 0 0 1em rgba(239, 243, 247, 0.3), 0 0 0 3em rgba(239, 243, 247, 0.3); } }

@keyframes ripple-1 {
  0% {
    box-shadow: 0 0 0 0 rgba(232, 115, 22, 0.3), 0 0 0 1em rgba(232, 115, 22, 0.3); }
  100% {
    box-shadow: 0 0 0 1em rgba(232, 115, 22, 0.3), 0 0 0 1.5em rgba(232, 115, 22, 0); } }

@keyframes ripple-2 {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3), 0 0 0 1em rgba(255, 255, 255, 0.3); }
  100% {
    box-shadow: 0 0 0 1em rgba(255, 255, 255, 0.3), 0 0 0 1.5em rgba(255, 255, 255, 0); } }

@keyframes rotate-flower {
  0% {
    transform: rotate(0deg);
    transform-origin: bottom; }
  25% {
    transform: rotate(8deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-8deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes back {
  0% {
    transform: translateX(0px); }
  70% {
    transform: translateX(-13px); }
  100% {
    transform: translateX(0px); } }

@keyframes regMask {
  0% {
    transform: translate(0px, 0px) scale(0); }
  50% {
    transform: translate(100px, 100px) scale(0.5); }
  100% {
    transform: translate(0px, 0px) scale(1); } }

@keyframes regMaskR {
  0% {
    transform: translate(0px, 0px) scale(1); }
  50% {
    transform: translate(150px, -150px) scale(0.5); }
  100% {
    transform: translate(0px, 0px) scale(0); } }

@keyframes regTag {
  0% {
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    transform: translate(4px, 25px) rotate(11.25deg); }
  50% {
    transform: translate(-88px, 40px) rotate(22.5deg); }
  100% {
    transform: translate(-96px, 8px) rotate(45deg); } }

@keyframes regTag2 {
  0% {
    width: 140px;
    height: 140px;
    line-height: 140px;
    box-shadow: 0px 2px 5px -1px rgba(33, 37, 41, 0.45); }
  100% {
    width: 70px;
    height: 70px;
    box-shadow: 0px 2px 5px -1px rgba(33, 37, 41, 0);
    line-height: 68px; } }

@keyframes regTagR {
  0% {
    transform: translate(-96px, 8px) rotate(45deg); }
  25% {
    transform: translate(-88px, 40px) rotate(22.5deg); }
  50% {
    transform: translate(4px, 25px) rotate(11.25deg); }
  100% {
    transform: translate(0px, 0px) rotate(0deg); } }

@keyframes regTag2R {
  0% {
    width: 70px;
    height: 70px;
    box-shadow: 0px 2px 5px -1px rgba(33, 37, 41, 0);
    line-height: 68px; }
  100% {
    width: 140px;
    height: 140px;
    line-height: 140px;
    box-shadow: 0px 2px 5px -1px rgba(33, 37, 41, 0.45); } }

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0); } }

@keyframes fade {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  50% {
    opacity: 0.5;
    transform: rotate(180deg); }
  100% {
    opacity: 1;
    transform: rotate(360deg); } }

@keyframes buttons-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0); }
  40%,
  60% {
    transform: translate3d(2px, 0, 0); } }

@keyframes ring {
  0% {
    transform: rotateZ(0); }
  1% {
    transform: rotateZ(15deg); }
  3% {
    transform: rotateZ(-14deg); }
  5% {
    transform: rotateZ(17deg); }
  7% {
    transform: rotateZ(-16deg); }
  9% {
    transform: rotateZ(15deg); }
  11% {
    transform: rotateZ(-14deg); }
  13% {
    transform: rotateZ(13deg); }
  15% {
    transform: rotateZ(-12deg); }
  17% {
    transform: rotateZ(12deg); }
  19% {
    transform: rotateZ(-10deg); }
  21% {
    transform: rotateZ(9deg); }
  23% {
    transform: rotateZ(-8deg); }
  25% {
    transform: rotateZ(7deg); }
  27% {
    transform: rotateZ(-5deg); }
  29% {
    transform: rotateZ(5deg); }
  31% {
    transform: rotateZ(-4deg); }
  33% {
    transform: rotateZ(3deg); }
  35% {
    transform: rotateZ(-2deg); }
  37% {
    transform: rotateZ(1deg); }
  39% {
    transform: rotateZ(-1deg); }
  41% {
    transform: rotateZ(1deg); }
  43% {
    transform: rotateZ(0); }
  100% {
    transform: rotateZ(0); } }

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset; }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset; }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset; }
  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset; }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset; }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset; } }

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80; }
  to {
    stroke-dashoffset: 0; } }

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%; }
  25% {
    opacity: 0.25; }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35; }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25; } }

@keyframes mover {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-7px); } }

@keyframes floating {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-187%); } }

@keyframes fadeIn {
  to {
    opacity: 1; } }

@keyframes moveDown {
  from {
    transform: translate(0, -100%); }
  to {
    transform: translate(0, 0); } }

@keyframes pendulum {
  20% {
    transform: rotate(60deg); }
  40% {
    transform: rotate(-40deg); }
  60% {
    transform: rotate(20deg); }
  80% {
    transform: rotate(-5deg); } }

@keyframes catHi {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.8); }
  10%,
  60% {
    transform: scale(1);
    opacity: 1; } }

@keyframes catShadow {
  0%,
  100% {
    transform: translate(40px, -35px) scale(0.3); }
  10%,
  60% {
    opacity: 1;
    transform: translate(-5px, 10px) scale(0.5); }
  60% {
    opacity: 0; } }

@keyframes moveBox {
  0% {
    opacity: 0;
    transform: translate(0, -150px) rotate(20deg); }
  15% {
    opacity: 1;
    transform: translate(0, 100px) rotate(-15deg); }
  25% {
    transform: translate(0, 250px) rotate(10deg); }
  30% {
    transform: translate(0, 350px) rotate(-5deg); }
  35% {
    opacity: 1;
    transform: translate(0, 570px) rotate(0deg); }
  45%,
  100% {
    opacity: 0;
    transform: translate(0, 570px); } }

@keyframes parachute {
  0% {
    transform: translate(0, -150px) rotate(20deg) scale(0.8);
    opacity: 0; }
  15% {
    transform: translate(0, 100px) rotate(-15deg) scale(1);
    opacity: 1; }
  25% {
    transform: translate(0, 250px) rotate(10deg); }
  30% {
    transform: translate(0, 350px) rotate(-5deg); }
  33% {
    transform: translate(0, 460px) rotate(0deg) scale(0.9);
    opacity: 1; }
  45%,
  100% {
    transform: translate(0, 480px);
    opacity: 0; } }

@keyframes fadeInOut {
  5%,
  12% {
    opacity: 1; }
  20% {
    opacity: 0; } }

@keyframes clouds {
  from {
    transform: translate(-150%, 0); }
  to {
    transform: translate(150%, 0); } }

@keyframes round {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

/**=====================
     banner css start
==========================**/
.banner-style-2 .collection-banner {
  position: relative;
  cursor: pointer; }
  .banner-style-2 .collection-banner .banner-img {
    min-height: 390px;
    object-fit: cover; }
    @media (max-width: 1460px) {
      .banner-style-2 .collection-banner .banner-img {
        min-height: 330px; } }
    @media (max-width: 1199px) {
      .banner-style-2 .collection-banner .banner-img {
        min-height: 300px; } }
    @media (max-width: 360px) {
      .banner-style-2 .collection-banner .banner-img {
        min-height: 240px; } }
  .banner-style-2 .collection-banner .banner-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 30px; }
    @media (max-width: 575px) {
      .banner-style-2 .collection-banner .banner-detail {
        padding: 20px; } }
    .banner-style-2 .collection-banner .banner-detail .font-dark-30 {
      font-weight: 800;
      transition: all 0.5s ease;
      color: #212529 !important; }
    .banner-style-2 .collection-banner .banner-detail .heart-wishlist .far {
      color: rgba(35, 35, 35, 0.6); }
    .banner-style-2 .collection-banner .banner-detail .heart-wishlist::after {
      opacity: 0;
      transition: 0.5; }
  .banner-style-2 .collection-banner .contain-banner {
    top: unset;
    bottom: 60px; }
    .banner-style-2 .collection-banner .contain-banner.contain-center {
      top: 0;
      bottom: 0; }
    @media (max-width: 1199px) {
      .banner-style-2 .collection-banner .contain-banner {
        bottom: 30px; } }
    .banner-style-2 .collection-banner .contain-banner .banner-content {
      padding: 40px;
      width: calc(100% - 120px);
      transition: all 0.5s;
      backdrop-filter: blur(4px); }
      .banner-style-2 .collection-banner .contain-banner .banner-content.banner-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        backdrop-filter: blur(5px); }
      @media (max-width: 1460px) {
        .banner-style-2 .collection-banner .contain-banner .banner-content {
          padding: 30px;
          width: unset; } }
      @media (max-width: 575px) {
        .banner-style-2 .collection-banner .contain-banner .banner-content {
          padding: 20px; } }
      @media (max-width: 1460px) {
        .banner-style-2 .collection-banner .contain-banner .banner-content span {
          letter-spacing: 3px; } }
  .banner-style-2 .collection-banner.ratio2_1 .bg-size:before {
    display: none; }
  .banner-style-2 .collection-banner:hover .contain-banner .banner-content.banner-center {
    backdrop-filter: blur(5px); }
  .banner-style-2 .collection-banner:hover .contain-banner .banner-content.with-bg {
    background-color: var(--theme-color);
    opacity: 0.9;
    transition: all 0.5s;
    color: #fff; }
  .banner-style-2 .collection-banner:hover .contain-banner .banner-content.with-big {
    background-color: var(--theme-color);
    backdrop-filter: blur(5px);
    opacity: 0.9; }
  .banner-style-2 .collection-banner:hover .banner-detail .heart-wishlist .far {
    color: var(--theme-color); }
  .banner-style-2 .collection-banner:hover .banner-detail .heart-wishlist::after {
    opacity: 0.1;
    transition: 0.5; }
  .banner-style-2 .collection-banner:hover .banner-detail .font-dark-30 {
    color: #232323 !important;
    transition: all 0.5s ease; }
    .banner-style-2 .collection-banner:hover .banner-detail .font-dark-30 span {
      color: var(--theme-color) !important; }

.banner-style-2.offer-banner .collection-banner .banner-img {
  min-height: unset; }

.banner-style-2.offer-banner .collection-banner .contain-banner {
  top: 50%;
  transform: translateY(-50%);
  bottom: unset; }
  .banner-style-2.offer-banner .collection-banner .contain-banner .banner-content {
    padding: 40px; }
    .banner-style-2.offer-banner .collection-banner .contain-banner .banner-content span {
      letter-spacing: unset; }

/**=====================
    RTL css start
==========================**/
[dir="rtl"] ol,
[dir="rtl"] ul {
  padding: 0; }

[dir="rtl"] .slick-slider {
  direction: ltr; }

[dir="rtl"] .slick-slide {
  float: left; }

/**=====================
     responsive css start
==========================**/
@media (min-width: 1430px) {
  .container {
    max-width: 1400px; } }
